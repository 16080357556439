import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NoImage from "../../assets/images/No_Image_Available.jpg";
import LazyLoad from "react-lazyload";
import LogoLoader from "../../Component/LogoLoader/LogoLoader";

const Marquee = () => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [category, setCategory] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [noCategoryFound, setNoCategoryFound] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/categories/public`
        );
        setCategory(response?.data?.data);
        setFilteredCategory(response?.data?.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    fetchCategory();
  }, []);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchText(searchValue);
    // Filter categories based on the search value
    const filteredCategories = category.filter((item) =>
      item.tag.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredCategory(filteredCategories);
    setNoCategoryFound(filteredCategories.length === 0); // Check if no categories are found
  };

  const handleClick = (item) => {
    navigate(`/collection/${item.tag?.toLowerCase().replace(/ /g, "_")}`, {
      state: { selectedTag: item._id },
    });

    window.scrollTo(0, 0);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(true); // Set loaded to true even on error to prevent infinite loading loop
  };

  return (
    <>
      <div className="mt-1">
        <div className="text-center mb-6">
          <h2 className="lg:text-[45px] sm:text-[25px] text-[#00F3F4] font-[Macoxs] uppercase tracking-[5px]">
            <span className="text-[#9ADD3A]">Instant</span> <br />
            creativity hub
          </h2>
          <p className="text-white text-xl max-w-md mx-auto ">
            From HD videos to amazing AI-generated images, find the content you
            want in the format you need
          </p>
        </div>
        <form className="max-w-3xl mx-auto" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="relative w-full min-h-14">
              <input
                type="search"
                id="search-dropdown"
                className="placeholder:text-white px-4 rounded-t-lg rounded-bl-lg text-white block p-2.5 w-full z-20 h-full text-sm bg-black rounded-e-lg border-2 border-primary-b focus:ring-primary-b focus:border-primary-b"
                placeholder="Search Categories ...."
                value={searchValue}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="absolute top-0 end-0 py-2.5 px-4 h-full text-sm font-medium text-white bg-primary-b rounded-e-lg border-primary-b hover:border-primary-b hover:bg-opacity-85 "
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <p className="capitalize text-primary-b font-bold text-[35px] ">
          {searchText}
        </p>
        {noCategoryFound && (
          <p className="capitalize text-white text-[16px]">
            {searchText} has no result
          </p>
        )}
        {!noCategoryFound && searchText && (
          <p className="capitalize text-white text-[16px]">
            From HD videos to amazing AI-generated images, find the content you
            want in the format you need
          </p>
        )}
      </div>

      <div className="box w-full grid md:grid-cols-3 xl:grid-cols-4 gap-10 mt-5">
        {filteredCategory.map((item, index) => (
          <div
            key={item._id}
            className="relative overflow-hidden cursor-pointer hover:brightness-75 group w-full h-72 lg:h-96 hover:scale-105 transition-all duration-500 ease-in-out"
            onClick={() => handleClick(item)}
          >
            <LazyLoad className="object-cover h-full w-full">
              <img
                className="object-cover h-full w-full"
                src={item.categoryImage !== "" ? item.categoryImage : NoImage}
                alt="design"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
              {!imageLoaded && <LogoLoader />}
            </LazyLoad>
            <div className="w-full h-full flex flex-col justify-center items-center absolute bottom-0 bg-gradient-to-b from-[#00000054] to-[#000000d4] ">
              <div className="flex justify-center items-center h-full w-full">
                <p className="text-white drop-shadow-sm text-transform: capitalize text-xl xl:text-2xl text-wrap mb-0 font-bold font-[montserrat] max-w-[50%] mx-auto  whitespace-nowrap text-center">
                  {item?.tag}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Marquee;
