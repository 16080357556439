import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCarousel,
  MDBCarouselItem,
  MDBRipple,
} from "mdb-react-ui-kit";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { Close, CloseOutlined } from "@mui/icons-material";
import Zip from "../../assets/images/zip-svgrepo-com.svg";
export default function CenteredModal({
  centredModal,
  setCentredModal,
  productId,
  onSaveChanges,
  redirection,
}) {
  const toggleOpen = () => setCentredModal(!centredModal);
  const [productDetail, setproductDetail] = useState();
  const [productName, setProductName] = useState();
  const [productDescription, setProductDescription] = useState();
  const [productPrice, setProductPrice] = useState();
  const [loader, setloader] = useState(true);
  const baseURL = process.env.REACT_APP_URL;
  const [mockupImage, setMockupImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productFeatures, setProductFeatures] = useState([]);
  const [zipFile, setZipFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const uploadImage = async (event) => {
    const files = event.target.files;
    try {
      if (files.length === 0) return;

      setLoading(true);

      const formData = new FormData();
      formData.append("image", files[0]);

      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-many`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response?.data?.message);
      setMockupImage(response.data.url[0]); // Assuming the API response contains the URL of the uploaded image
    } catch (error) {
      setError(
        "Unable to upload image. Server rejected the file. Please ensure the file format is supported and try again later"
      );
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setProductName(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setProductDescription(event.target.value);
  };
  const handlePriceChange = (event) => {
    setProductPrice(event.target.value);
  };
  const handleFeaturesChange = (event) => {
    setProductFeatures(event.target.value);
  };
  const handleAddFeature = (event) => {
    if (event.key === "Enter") {
      setProductFeatures([...productFeatures, event.target.value]);
      event.target.value = ""; // Clear the input after adding a feature
    }
  };
  const handleRemoveFeature = (index) => {
    setProductFeatures(productFeatures.filter((_, i) => i !== index));
  };
  // In getProductDetail function

  const handleSave = () => {
    const token = localStorage.getItem("adminToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const data = {
      title: productName,
      description: productDescription,
      mockupImage: mockupImage,
      price: productPrice,
      features: productFeatures,
      folderPath: zipFile,
    };

    axios
      .put(`${process.env.REACT_APP_URL}/${redirection}/${productId}`, data, {
        headers,
      })
      .then((response) => {
        console.log(response.data);
        toggleOpen();
        onSaveChanges();
        toast.success(response?.data?.message);
      })

      .catch((error) => {
        console.error(error);
        toast.success(error?.response?.data?.message);
      });
  };

  const getProductDetail = async () => {
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_URL}/products/${productId}`, config)
      .then((response) => {
        setproductDetail(response.data.product);
        setloader(false);
        setMockupImage(response?.data?.product?.mockupImage);

        setProductFeatures(response?.data?.product?.features || []);

        // In getDraftDetail function
        setProductFeatures(response?.data?.draft?.features || []);
        setZipFile(response?.data?.product?.folderPath || null);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getDraftDetail = async () => {
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_URL}/drafts/${productId}`, config)
      .then((response) => {
        console.log("successfull", response.data);
        setproductDetail(response.data.draft);
        setloader(false);
        setZipFile(response?.data?.product?.folderPath || null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    switch (redirection) {
      case "drafts":
        getDraftDetail();
        break;
      case "products":
        getProductDetail();
        break;
      default:
        break;
    }
  }, []);

  function stripHtmlTags(htmlString) {
    return htmlString?.replace(/<[^>]*>/g, "");
  }

  const removeImage = () => {
    setMockupImage(null);
  };

  const removeImageCerousel = (index) => {
    const updatedImages = [...productDetail.images];
    updatedImages.splice(index, 1);
    setproductDetail({ ...productDetail, images: updatedImages });
  };
  const uploadZipFile = async (event) => {
    const files = event.target.files;
    try {
      if (files.length === 0) return;

      setLoading(true);

      const formData = new FormData();
      formData.append("zip", files[0]); // Assuming only one file is selected for the zip file

      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-folder`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response?.data?.message);
      setZipFile(response.data.uploadedFile?.Location); // Assuming the API response contains the URL of the uploaded zip file
    } catch (error) {
      // setZipFile(response.data.uploadedFile?.Location); // Assuming the API response contains the URL of the uploaded zip file
      toast.error(
        "Unable to upload image. Server rejected the file. Please ensure the file format is supported and try again later"
      );
      console.error("Error uploading zip file:", error);
    } finally {
      setLoading(false);
    }
  };
  const removeZipFile = () => {
    setZipFile(null);
    setIsDeleteModalOpen(false); // Close the confirmation modal
  };

  return (
    <>
      {/* //centeed modal */}
      <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
        <MDBModalDialog centered>
          {loader ? (
            <Loader />
          ) : (
            <MDBModalContent className="bg-black ">
              <MDBModalHeader className="bg-tw-primary">
                <h3 className="mt-2 text-xl font-bold uppercase text-primary-b">
                  Update Product
                </h3>
                <div className="bg-secondary-g rounded-full p-0.5 ">
                  <MDBBtn
                    className="btn-close grayscale brightness-200 m-0 z-50"
                    color="none"
                    onClick={toggleOpen}
                  ></MDBBtn>
                </div>
              </MDBModalHeader>
              {/* <MDBModalBody>


            */}
              <MDBModalBody className="bg-tw-primary">
                {productDetail && (
                  <>
                    <div className="overflow-auto relative w-full lg:h-60 h-40  border-2 border-dashed border-primary-b mb-5 flex justify-center items-center p-2">
                      {loading ? (
                        <div className="flex justify-center items-center w-full">
                          <div className="animate-spin  rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                        </div>
                      ) : error ? (
                        <div className="text-red-500">{error}</div>
                      ) : mockupImage ? (
                        <div className="relative w-full h-full overflow-auto">
                          <img
                            src={mockupImage}
                            className="object-cover object-top "
                            alt="Mockup"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                          />
                          <button
                            className="absolute top-2 right-2 p-1 bg-primary-b border-0 rounded-full text-black"
                            onClick={removeImage}
                          >
                            <CloseOutlined />
                          </button>
                        </div>
                      ) : (
                        <label
                          htmlFor="mockupImageInput"
                          className="cursor-pointer"
                        >
                          <p className="text-primary-b">Upload Mockup image</p>
                          <input
                            type="file"
                            id="mockupImageInput"
                            className="hidden"
                            onChange={uploadImage}
                          />
                        </label>
                      )}
                    </div>

                    <div className="relative mb-4">
                      {productDetail.images &&
                      productDetail.images.length > 0 ? (
                        <>
                          <MDBCarousel showControls showIndicators>
                            {productDetail.images.map((image, i) => (
                              <MDBCarouselItem itemId={i + 1} key={i}>
                                <div
                                  style={{
                                    backgroundImage: `url(${image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "top left",
                                    backgroundSize: "cover",
                                  }}
                                  alt={`Product ${i + 1}`}
                                  className="w-full h-52 rounded-lg object-contain cursor-pointer transition-all duration-1000 bg-bottom-left"
                                />
                                {/* Trash icon to remove the image */}
                                <div
                                  className="z-50 icons-container absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-black opacity-50 p-1 rounded-lg"
                                  onClick={() => removeImageCerousel(i)}
                                >
                                  <i
                                    className="fas fa-trash-alt fa-lg mx-2"
                                    style={{ color: "white" }}
                                  ></i>
                                </div>
                              </MDBCarouselItem>
                            ))}
                          </MDBCarousel>
                        </>
                      ) : (
                        <div className="w-full h-52 flex justify-center items-center text-gray-500 relative">
                          <span>
                            No image available. Please upload an image.
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="overflow-auto relative w-full lg:h-60 h-40 border-2 border-dashed border-primary-b mb-5 flex justify-center items-center p-2">
                      {loading ? (
                        <div className="flex justify-center items-center w-full">
                          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                        </div>
                      ) : error ? (
                        <div className="text-red-500">{error}</div>
                      ) : zipFile ? (
                        <div className="relative w-full flex justify-between items-center border-2 border-dashed border-primary-b p-2 ">
                          <div className="flex items-center gap-1">
                            <img
                              src={Zip}
                              alt="Zip File"
                              className="w-8 h-8 mr-2"
                            />
                            <span className="text-primary-b">Zip File</span>
                          </div>
                          <button
                            className=" py-1 bg-secondary-g border-0 hover:bg-opacity-80 rounded-2xl text-black px-3 text-sm font-semibold my-2"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <label
                          htmlFor="zipFileInput"
                          className="cursor-pointer"
                        >
                          <p className="text-primary-b">Upload Zip File</p>
                          <input
                            type="file"
                            id="zipFileInput"
                            className="hidden"
                            onChange={uploadZipFile}
                          />
                        </label>
                      )}
                    </div>

                    <p className="m-0 p-0 text-white">Title</p>
                    <input
                      className="block w-full my-1 mb-3 border-2 border-primary-b p-2 rounded-lg bg-black text-white"
                      placeholder="Title"
                      value={
                        productName !== undefined
                          ? productName
                          : productDetail && productDetail.title
                      }
                      onChange={handleInputChange}
                    />
                    <p className="m-0 p-0 text-white">Price</p>
                    <input
                      className="block w-full my-1 mb-3 border-2 border-primary-b p-2 rounded-lg bg-black text-white"
                      placeholder="Price"
                      value={
                        productPrice !== undefined
                          ? productPrice
                          : productDetail && productDetail.price
                      }
                      onChange={handlePriceChange}
                    />
                    <p className="m-0 p-0 text-white">Description</p>
                    <textarea
                      rows={4}
                      className="block w-full my-1 mb-3  first-letter:capitalize border-2 border-primary-b p-2 rounded-lg bg-black text-white resize-none"
                      value={stripHtmlTags(
                        productDescription !== undefined
                          ? productDescription
                          : productDetail && productDetail.description
                      )}
                      onChange={handleDescriptionChange}
                    />

                    <div className="relative mb-4">
                      <p className="m-0 p-0 text-white">Features</p>
                      <input
                        className="block w-full my-1 mb-3 border-2 border-primary-b p-2 rounded-lg bg-black text-white"
                        placeholder="Add a feature and press Enter"
                        onKeyDown={handleAddFeature}
                      />
                      <ul className="list-disc list-inside text-white p-0 max-h-96 overflow-y-auto">
                        {productFeatures.map((feature, index) => (
                          <li
                            key={index}
                            className="flex justify-between items-center border-b pl-2 border-[#9ADD3A]"
                          >
                            {feature}
                            <button
                              className=" py-1 bg-secondary-g border-0 hover:bg-opacity-80 rounded-2xl text-black px-3 text-sm font-semibold my-2"
                              onClick={() => handleRemoveFeature(index)}
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </MDBModalBody>
              <MDBModalFooter className="bg-black">
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  <span className="text-black">Close</span>
                </MDBBtn>
                <MDBBtn onClick={() => handleSave()}>Save changes</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          )}
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        tabIndex="-1"
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Confirm Delete</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              Are you sure you want to delete the zip file?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </MDBBtn>
              <MDBBtn color="danger" onClick={removeZipFile}>
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
