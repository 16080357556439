import React, { useEffect, useState } from "react";
import Cut from "../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";
import Check from "../../../src/assets/images/check-png.6b960a3f.png";
import Payment from "./Payment";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModel from "../projects/confirmModel";

const Cart = ({ purchasePopupOpen, setPurchasePopupOpen, product }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setPurchasePopupOpen(false);
    }
  };
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [productToSave, setProductToSave] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [savedLocally, setSavedLocally] = useState([]);

  // Fetch saved products from localStorage
  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    setSavedLocally(savedProducts.map((item) => item._id));
  }, []);

  
  const saveProductsToLocal = (product) => {
    let savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    const existingProduct = savedProducts.find((p) => p._id === product._id);
    if (existingProduct) {
      toast.info("Product already in cart!");
    } else {
      savedProducts.push(product);
      localStorage.setItem("products", JSON.stringify(savedProducts));
      setSavedLocally([...savedLocally, product._id]);
      toast.success("Item successfully added to your cart!");
      navigate("/payment");
    }
  };

  // const handleModalClose = () => {
  //   setShowModal(false);
  //   setProductToSave(null);
  // };

  // const handleModalConfirm = () => {
  //   let savedProducts = [productToSave]; // Ensure this is always an array
  //   localStorage.setItem("products", JSON.stringify(savedProducts));
  //   setSavedLocally([productToSave._id]);
  //   toast.success("Item successfully replaced in your cart!");
  //   handleModalClose();
  // };

  return (
    <div className="w-full flex justify-center items-center">
      <div
        onClick={handleOverlayClick}
        className="bg-black w-full h-[100vh] fixed top-0 flex flex-col justify-center items-center opacity-60"
      ></div>

      <div className="py-10 px-10 bg-black border-[#9ADD3A] border-[1px] text-white fixed top-20 rounded-3xl opacity-100 md:max-w-[400px] w-[90%]">
        <span
          className="text-white absolute right-5 top-5"
          onClick={() => setPurchasePopupOpen(false)}
        >
          <img src={Cut} alt="Close" className="md:w-7 w-5 cursor-pointer" />
        </span>
        <div className="flex flex-col justify-start items-start">
          <p className="lg:text-[25px] text-[16px] text-[#9ADD3A]">
            Your Product:{" "}
            <span className="text-primary-b">{product?.title}</span>
          </p>
          {product?.features && product.features.length > 0 && (
            <div className="border-y-[1px] font-[montserrat] text-[18px] border-[#9ADD3A] py-3 w-full">
              {product.features.map((feature, index) => (
                <div key={index} className="flex items-center gap-3 py-2">
                  <img src={Check} alt="Check" className="w-4" />
                  <p className="m-0">{feature}</p>
                </div>
              ))}
            </div>
          )}
          <div className="text-[#9ADD3A] lg:py-4 lg:text-[20px] text-[15px]">
            <p>
              Price: <span className="text-primary-b">${product?.price}</span>
            </p>
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => saveProductsToLocal(product)}
        >
          <p className="w-full text-[#5eefe8] text-center border-[#5eefe8] border-[1px] my-2 rounded-lg capitalize py-3">
            Add to cart
          </p>
        </div>
      </div>
      {/* <ConfirmModel
        isOpen={showModal}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        productToSave={productToSave}
      /> */}
    </div>
  );
};

export default Cart;
