import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdatePassword = () => {
  const [Password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleChange = (e) => {
    setPassword({ ...Password, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/change`,
        Password,
        config
      );
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const isButtonDisabled = !Password.currentPassword || !Password.newPassword;

  return (
    <div className="text-white flex flex-col justify-start items-start w-full">
      <ToastContainer />
      <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
        <span className="text-secondary-g mr-2">Update</span> Password
      </p>
      <form className="w-full space-y-4 flex flex-col justify-start items-start my-10">
        <div className="flex flex-col lg:w-1/3 w-full relative">
          <label>Current Password</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            type={showPassword ? "text" : "password"}
            name="currentPassword"
            value={Password.currentPassword}
            onChange={handleChange}
            placeholder="XXXXXXXXXXXXXXXXXXXX"
          />
          <span
            className="absolute right-3 top-10 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: "black", fontSize: "22px" }} />
            ) : (
              <FaEye style={{ color: "black", fontSize: "22px" }} />
            )}
          </span>
        </div>
        <div className="flex flex-col lg:w-1/3 w-full relative">
          <label>New Password</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            value={Password.newPassword}
            onChange={handleChange}
            placeholder="XXXXXXXXXXXXXXXXXXXX"
          />
          <span
            className="absolute right-3 top-10 cursor-pointer"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <FaEyeSlash style={{ color: "black", fontSize: "22px" }} />
            ) : (
              <FaEye style={{ color: "black", fontSize: "22px" }} />
            )}
          </span>
        </div>
        <button
          className="font-[montserrat] rounded bg-secondary-g border-none px-5 disabled:bg-gray-500"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          Done
        </button>
      </form>
    </div>
  );
};

export default UpdatePassword;
