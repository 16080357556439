export const PlusIcon = ({ color = "#000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="3"
      stroke={color}
      class="w-5 h-5"
      style={{ marginTop: 1 }}
    >
      <path
        stroke-linecap="none"
        stroke-linejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

export const FigmaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="css-1ty7gog"
      viewBox="0 0 38 57"
      aria-label="Homepage"
      className="w-6"
    >
      <path
        fill="#1abcfe"
        d="M19 28.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0z"
      ></path>
      <path
        fill="#0acf83"
        d="M0 47.5A9.5 9.5 0 0 1 9.5 38H19v9.5a9.5 9.5 0 1 1-19 0z"
      ></path>
      <path fill="#ff7262" d="M19 0v19h9.5a9.5 9.5 0 1 0 0-19H19z"></path>
      <path
        fill="#f24e1e"
        d="M0 9.5A9.5 9.5 0 0 0 9.5 19H19V0H9.5A9.5 9.5 0 0 0 0 9.5z"
      ></path>
      <path
        fill="#a259ff"
        d="M0 28.5A9.5 9.5 0 0 0 9.5 38H19V19H9.5A9.5 9.5 0 0 0 0 28.5z"
      ></path>
    </svg>
  );
};

export const UploadIcon = ({ isDragging }) => {
  return (
    <svg
      class="w-14 h-auto xs:w-8 mb-4 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      viewBox="0 0 20 16"
    >
      <path
        stroke={isDragging ? "#00F3F4" : "#fff"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.6"
        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
      ></path>
    </svg>
  );
};

export const EyeIcon = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.75"
      height="20.824"
      viewBox="0 0 26.75 20.824"
      className={size ? "w-5" : "w-15"}
    >
      <g id="eye" transform="translate(0 -56.735)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="4.457"
          cy="4.457"
          r="4.457"
          transform="translate(8.919 62.69)"
          fill={color ? color : "#BDBDBD"}
        />
        <path
          id="Path_185"
          data-name="Path 185"
          d="M25.933,64.271c-1.728-2.814-5.659-7.536-12.558-7.536S2.546,61.457.818,64.271a5.468,5.468,0,0,0,0,5.751c1.728,2.814,5.659,7.536,12.558,7.536s10.83-4.722,12.558-7.536A5.468,5.468,0,0,0,25.933,64.271ZM13.375,73.832a6.685,6.685,0,1,1,6.685-6.685A6.685,6.685,0,0,1,13.375,73.832Z"
          fill={color ? color : "#BDBDBD"}
        />
      </g>
    </svg>
  );
};

export const EyeOffIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.75"
      height="24.505"
      viewBox="0 0 26.75 24.505"
      className="w-5"
    >
      <g id="eye-crossed" transform="translate(0 -21.492)">
        <path
          id="Path_186"
          data-name="Path 186"
          d="M25.932,30.855A17.677,17.677,0,0,0,22.176,26.5l3.12-3.12a1.114,1.114,0,0,0-1.575-1.575l-3.393,3.4a13.43,13.43,0,0,0-6.954-1.883c-6.9,0-10.829,4.722-12.558,7.536a5.468,5.468,0,0,0,0,5.751,17.677,17.677,0,0,0,3.756,4.355l-3.12,3.12A1.114,1.114,0,1,0,3,45.685l.027-.027,3.4-3.4a13.43,13.43,0,0,0,6.946,1.886c6.9,0,10.829-4.722,12.558-7.536A5.468,5.468,0,0,0,25.932,30.855ZM6.69,33.731a6.674,6.674,0,0,1,10.552-5.443l-1.618,1.618a4.4,4.4,0,0,0-2.25-.632,4.457,4.457,0,0,0-4.457,4.457,4.4,4.4,0,0,0,.632,2.25L7.932,37.6A6.641,6.641,0,0,1,6.69,33.731Zm6.685,6.685a6.641,6.641,0,0,1-3.867-1.242l1.618-1.618a4.4,4.4,0,0,0,2.25.632,4.457,4.457,0,0,0,4.457-4.457,4.4,4.4,0,0,0-.632-2.25l1.618-1.618a6.674,6.674,0,0,1-5.443,10.552Z"
          transform="translate(0 0)"
          fill={color ? color : "#BDBDBD"}
        />
      </g>
    </svg>
  );
};

export const DropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.861"
      height="8.816"
      viewBox="0 0 17.861 8.816"
    >
      <path
        id="angle-down_2_"
        data-name="angle-down (2)"
        d="M1.127,6.079a1.111,1.111,0,0,1,.789.328l5.712,5.712a1.861,1.861,0,0,0,2.633,0l5.7-5.7a1.117,1.117,0,1,1,1.579,1.579l-5.7,5.7a4.1,4.1,0,0,1-5.791,0L.337,7.986a1.117,1.117,0,0,1,.79-1.907Z"
        transform="translate(-0.009 -6.079)"
        fill="#64748b"
      />
    </svg>
  );
};
export const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="md:w-7 md:h-7 w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
};
export const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#F87171"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
      />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
      />
    </svg>
  );
};

export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#000"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
      />
    </svg>
  );
};
export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#000"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
      />
    </svg>
  );
};
