import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
// import { DropdownIcon } from "../../SVG";

const StatsChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [selectedView, setSelectedView] = useState("Daily");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalCounts, setTotalCounts] = useState({});
  const [totalBalances, setTotalBalances] = useState({});

  // Reference to the dropdown element
  const dropdownRef = useRef(null);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdownOnClickOutside = (event) => {
    if (
      dropdownRef?.current &&
      !dropdownRef?.current.contains(event.target) &&
      chartRef?.current &&
      !chartRef?.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", closeDropdownOnClickOutside);

    return () => {
      document.body.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const ctx = chartRef?.current?.getContext("2d");

      if (chartInstance.current) {
        chartInstance?.current?.destroy();
      }

      const totals = {};

      data.forEach((item) => {
        const { month, day, totalAmount } = item;
        const dateKey = `${month}/${day}`;

        if (!totals[dateKey]) {
          totals[dateKey] = { totalCount: 0, totalBalance: 0 };
        }

        totals[dateKey].totalCount++;
        totals[dateKey].totalBalance += totalAmount;
      });

      setTotalCounts(totals);
      setTotalBalances(totals);

      const labels = Object.keys(totals);
      const counts = labels.map((key) => totals[key].totalCount);
      const balances = labels.map((key) => totals[key].totalBalance);

      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Total Orders Counts",
              data: counts,
              backgroundColor: "#9ADD3A",
              borderColor: "#9ADD3A",
              borderWidth: 2,
              pointRadius: 5,
              pointHoverRadius: 8,
            },
            {
              label: "Total Amount",
              data: balances,
              backgroundColor: "#00F3F4",
              borderColor: "#00F3F4",
              borderWidth: 2,
              pointRadius: 5,
              pointHoverRadius: 8,
            },
          ],
        },
        options: {
          scales: {
            x: {
              ticks: {
                color: "#FFFFFF",
                font: {
                  family: "Arial, sans-serif",
                },
              },
            },
            y: {
              ticks: {
                color: "#FFFFFF",
                font: {
                  family: "Arial, sans-serif",
                },
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: (tooltipItem, data) => {
                const datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "";
                const value = tooltipItem.yLabel;
                return `${datasetLabel}: ${value}`;
              },
            },
          },
          color: "#FFFFFF",
        },
      });
    }
  }, [data, selectedView]);

  const handleViewChange = (view) => {
    setSelectedView(view);
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative justify-between bg-tw-primary border-2 border-secondary-g p-4 rounded-lg shadow-2xl">
      <div className="w-full transition-opacity duration-300 opacity-100 hover:opacity-100 overflow-hidden top-13 translate-y-1">
        {data.length === 0 && (
          <p className="text-white text-lg">No Chart Data Available</p>
        )}
        {data.length > 0 && (
          <div className="flex justify-between lg:mb-0 sm:mt-0 items-center lg:items-start sm:mb-8 lg:gap-0 xs:flex-wrap xs:gap-1">
            <p className="md:text-3xl text-white xs:font-bold font-bold">
              Total Revenue
            </p>
            <div
              className="relative cursor-pointer"
              onClick={toggleDropdown}
              ref={dropdownRef}
            >
              <p className="flex gap-1 items-center justify-center bg-boxPrimary rounded-3xl mt-2">
                <span className="text-lg text-white xs:text-base block whitespace-nowrap">
                  Sort by :
                </span>
                <span className="text-lg font-bold w-16 xs:text-base text-white">
                  {selectedView}
                </span>
              </p>
              {isDropdownOpen && (
                <div className="mt-2 border rounded-lg overflow-hidden shadow-lg">
                  <ul>
                    {data?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleViewChange(Object.keys(item)[0])}
                        className={`borderLastChild text-base text-white font-bold cursor-pointer hover:bg-boxPrimary text-center py-2.5 border-b border-boxSecondary ${
                          selectedView === Object.keys(item)[0] ? "bg-boxPrimary" : ""
                        }`}
                      >
                        {Object.keys(item)[0]}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {data.length > 0 && (
        <div className="w-full">
          <canvas ref={chartRef} width="100%" />
        </div>
      )}
    </div>
  );
}
  export default StatsChart;