import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Activity = () => {
  const baseURL = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [generalInfo, setGeneralInfo] = useState({});
  const savedProduct = generalInfo.savedProducts || [];
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setGeneralInfo(response?.data?.data);

        localStorage.setItem("users", JSON.stringify(response?.data?.data));
      } catch (error) {
        toast.info(error?.response?.data?.message);
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [baseURL, token]);
  const handleButtonClick = (product) => {
    navigate(`/activity/${product._id}`);
  };
  const handleButtonRedirect = (product) => {
    navigate(`/details/${product.title.toLowerCase().replace(/ /g, "_")}`, {
      state: { product },
    });
  };
  const [saved, setSaved] = useState([]);
  const handleSaveItem = async (productId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (saved.includes(productId)) {
        await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        setSaved(saved.filter((id) => id !== productId));
        toast.warning("Product unsaved!");
      } else {
        await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        setSaved([...saved, productId]);
        toast.success("Save Product!");
      }
    } catch (error) {
      console.error("Error saving/unsaving product:", error);
      toast.error("Please log in to access this feature.");
    }
  };

  return (
    <div>
      <div className="w-[65vw] top-10 ">
        <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
          <span className="text-secondary-g mr-2">Save</span> Products
        </p>{" "}
        {savedProduct.length === 0 ? (
          <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
            <span className="text-secondary-g mr-2">No Product Save</span> Yet
          </p>
        ) : (
          <div className="flex gap-4 flex-wrap w-full">
            {savedProduct.map((product) => (
              <div
                key={product._id}
                className="relative overflow-hidden rounded-3xl cursor-pointer group w-80 h-72 lg:h-96 border-4 border-[#c7c7c7]"
              >
                <img
                  className="object-cover rounded-2xl w-full h-full"
                  src={product.images[0]} // Use NoImage if product images are empty
                  alt={product.title || "Product image"}
                />

                <div
                  // onClick={() => handleButtonRedirect(product)}
                  className="hidden h-0 group-hover:h-full w-full group-hover:flex flex-col justify-end items-center absolute bottom-0 bg-gradient-to-b from-transparent to-[#000000] rounded-b-2xl"
                >
                  <div className="flex justify-between py-4 items-center w-full px-[20px]">
                    <div className="w-full">
                      <div className="flex flex-col">
                        <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                          {product?.title}
                        </p>
                      </div>
                      <div>
                        <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                          Price: $ {product?.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Activity;
