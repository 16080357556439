import React, { useState } from "react";
import Gif from "../../../src/assets/images/gif/gif2 (3).gif";
import SignUpPopup from "./SignUpPopup";
import axios from "axios";
import Verification from "./Verification";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const SignUp = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const closePopup = () => {
    setOpenModal(false);
  };

  return (
    <div className="w-full flex lg:flex-row flex-col relative h-screen">
      <div className="w-[60%] h-screen xl:block hidden sticky top-0">
        <img
          src={Gif}
          alt="Decorative Gif"
          className="w-full h-full object-cover object-top"
        />
      </div>
      <div className="flex flex-col justify-center items-center xl:items-start  w-full xl:px-20 px-10 h-full overflow-auto">
        <div className="max-w-2xl w-full h-full my-4 flex items-center ">
          <div className="w-full h-full">
            <div className="mt-5 ">
              <p className="text-primary-b font-[Monument] lg:text-2xl text-xl p-0 mb-2.5">
                <span className="text-secondary-g">Sign up to</span> Pixel Pulse
              </p>
            </div>
            <div className="flex flex-col space-y-4">
              <p className="text-white text-[15px]">
                By creating an account you agree with our Terms of Service,
                Privacy Policy, and our default Notification Settings.
              </p>
            </div>
            <SignUpPopup />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
