import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import Photo from "../../assets/images/photuploader.svg";
import axios from "axios";
import { toast } from "react-toastify";

const ImageUploader = ({ baseUrl, token, setImageResponse, getImage }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const maxNumber = 1; // Max number of images allowed

  const onChange = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setLoading(true);

    const formData = new FormData();
    formData.append("image", imageList[0].file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/images/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Image uploaded successfully");
      setImageResponse(response?.data?.url);
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onRemoveImage = () => {
    setImages([]);
  };

  return (
    <div>
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <div className="upload__image-wrapper">
            {loading ? (
              <p>Uploading...</p>
            ) : images.length > 0 ? (
              <div className="w-[4rem] relative">
                <img 
                className="w-full h-full rounded-full"
                src={images[0].data_url}
                 alt="uploaded" />
              
              </div>
            ) : (
              <div>
                <button
                  className="p-0 m-0 size-32 rounded-full"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  {getImage ? (
                    <img
                      src={getImage}
                      alt="photo"
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <img
                      src={Photo}
                      alt="photo"
                      className="w-full h-full rounded-full"
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUploader;
