import React, { useState } from "react";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from "mdb-react-ui-kit";
import "./Sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const location = useLocation();
  const currnetPage = location.pathname;

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin/sign_in");
  };
  return (
    <div
      className={`bg-black transition-none overflow-auto w-0 col-lg-2  sidebar border-r-2 border-gray-400 ovw ${
        isOpen ? "open w-auto" : ""
      }`}
    >
      <div className="sidebar-content">
        <button className="sidebar-toggle d-lg-none " onClick={toggleSidebar}>
          <MDBIcon icon={isOpen ? "times" : "bars"} className="text-dark" />
        </button>

        <MDBListGroup>
          <Link to="/admin/dashboard">
            <img
              className="max-w-52 h-auto mx-3 mb-6"
              src={require("../../assets/images/dipixels-logo-full.png")}
            />
          </Link>

          <Link to="/admin/dashboard">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/dashboard" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="home"
                className={` me-3  ${
                  currnetPage == "/admin/dashboard"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/dashboard" && "dark_color"
                }`}
              >
                Dashboard
              </p>
            </MDBListGroupItem>
          </Link>

          <Link to={"/admin/product"}>
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/product" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="cloud-upload-alt"
                className={` me-3  ${
                  currnetPage == "/admin/product"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/product" && "dark_color"
                }`}
              >
                Upload Product
              </p>
            </MDBListGroupItem>
          </Link>
          <Link to="/admin/listing">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/listing" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="box"
                className={` me-3  ${
                  currnetPage == "/admin/listing"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/listing" && "dark_color"
                }`}
              >
                Products
              </p>
            </MDBListGroupItem>
          </Link>

          {/* <Link to="/admin/drafts">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/drafts" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="edit"
                className={` me-3  ${
                  currnetPage == "/admin/drafts" ? "text-dark_active" : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/drafts" && "dark_color"
                }`}
              >
                Draft
              </p>
            </MDBListGroupItem>
          </Link> */}

          <Link to="/admin/users">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/users" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="users"
                className={` me-3  ${
                  currnetPage == "/admin/users"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/users" && "dark_color"
                }`}
              >
                Users
              </p>
            </MDBListGroupItem>
          </Link>

          <Link to="/admin/orders">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/orders" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="shopping-cart"
                className={` me-3  ${
                  currnetPage == "/admin/orders"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/orders" && "dark_color"
                }`}
              >
                Orders
              </p>
            </MDBListGroupItem>
          </Link>

          <Link to="/admin/categories">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/categories" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="th-large"
                className={` me-3  ${
                  currnetPage == "/admin/categories"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/categories" && "dark_color"
                }`}
              >
                Categories
              </p>
            </MDBListGroupItem>
          </Link>
          <Link to="/admin/customization">
            <MDBListGroupItem
              className={`parent_group ${
                currnetPage == "/admin/customization" && "parent_group_active"
              }`}
            >
              <MDBIcon
                icon="highlighter"
                className={` me-3  ${
                  currnetPage == "/admin/customization"
                    ? "text-dark_active"
                    : "text-dark"
                }`}
                size="lg"
              />
              <p
                className={`light_color ${
                  currnetPage == "/admin/customization" && "dark_color"
                }`}
              >
                Customization
              </p>
            </MDBListGroupItem>
          </Link>

          <MDBListGroupItem className="parent_group" onClick={handleLogout}>
            <MDBIcon icon="sign-out-alt" className="me-3 text-dark" size="lg" />
            <p className="light_color">Logout</p>
          </MDBListGroupItem>
        </MDBListGroup>
      </div>
    </div>
  );
};

export default Sidebar;
