import React from "react";
function LogoLoader() {
  return (
    <div>
      <img
        src={require("../../assets/images/gif/logoGIf.gif")}
        className="w-24 h-auto mx-auto"
      />
    </div>
  );
}

export default LogoLoader;
