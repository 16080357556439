import React, { useState } from "react";
import axios from "axios";
import Verification from "./Verification";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { EyeIcon, EyeOffIcon } from "../../svg";

const SignUpPopup = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");

  const handleButtonClick = () => {
    setOpenModal(true);
    setEmail(formData.email);
  };
  const closePopup = () => {
    setOpenModal(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const baseURL = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    phone: "",
    country: "",
  });
  const notify = () => toast("Wow so easy!");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChange2 = (value) => {
    setFormData({ ...formData, phone: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/register`,
        formData
      );


      handleButtonClick();

      toast.success("Registration successful!");
      setLoading(false);
      // Handle success response
    } catch (error) {
      setLoading(false);
      toast.error(error?.response.data.message);

      // toast.error(error);
      console.error("Registration failed:", error?.response?.data?.message); // Handle error
    }
  };
  const [countryid, setCountryid] = useState(0);


  return (
    <div className="text-white w-full">
      {/* {error && <div className="text-red-400 text-[20px] py-5">{error}</div>} */}

      <form className="w-full md:space-y-5 space-y-2.5" onSubmit={handleSubmit}>
        <div className="flex md:flex-row flex-col gap-3">
          <div className="w-full flex flex-col md:space-y-2 space-y-1 ">
            <label className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]">
              User Name
            </label>
            <input
              className=" w-full font-[montserrat] text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl focus:outline-secondary-g"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="John sons"
            />
          </div>
        </div>
        <div className="w-full  flex flex-col md:space-y-2 space-y-1">
          <label className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]">
            Email
          </label>
          <input
            className=" w-full font-[montserrat] text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl focus:outline-secondary-g"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Johnons@gmail.com"
          />
        </div>

        <div className="md:space-y-5 space-y-2.5">
          <div className="w-full  flex flex-col md:space-y-2 space-y-1">
            <label className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]">
              Phone
            </label>

            <PhoneInput
              className=" w-full font-[montserrat] text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 pr-3 overflow-hidden rounded-xl"
              country={"us"}
              value={formData.phone}
              onChange={handleChange2}
            />
          </div>
          <div className="md:space-y-5 space-y-2.5">
            <div className="space-y-2">
              <label className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]">
                Country
              </label>
              <CountrySelect
                onChange={(e) => {
                  setCountryid(e.id);
                  setFormData({ ...formData, country: e.name });
                }}
                placeHolder="Select Country"
              />
            </div>

            <div className="w-full relative flex flex-col space-y-2">
              <label className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className=" w-full font-[montserrat] text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl focus:outline-secondary-g"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <div
                className="mt-0 absolute top-1/2 min-h-7 flex items-center right-3"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeIcon color="#000" />
                ) : (
                  <EyeOffIcon color="#000" />
                )}
              </div>
            </div>
          </div>
          {loading ? (
            <button
              className="font-[montserrat] rounded-md"
              type="submit"
              onClick={handleSubmit}
            >
              Creating......
            </button>
          ) : (
            <div
              className="font-[montserrat] mt-4 border-2 border-primary-b bg-primary-b text-[#000] font-semibold px-4 py-2 cursor-pointer md:py-2.5 text-center md:w-fit rounded-md transition-all duration-300 hover:bg-transparent hover:text-primary-b"
              onClick={handleSubmit}
            >
              Create Account
            </div>
          )}
          <p className="font-[montserrat]">
            Already have an account?{" "}
            <Link to="/login">
              <span className="text-secondary-g">Login </span>
            </Link>
          </p>
        </div>
      </form>
      {openModal && <Verification email={email} onClose={closePopup} />}
    </div>
  );
};

export default SignUpPopup;
