import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const LikeIcon = ({ id, product, userId, fetchProduct }) => {
  const [isLikedByUser, setIsLikedByUser] = useState(false);
  const baseURL = process.env.REACT_APP_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkLikedStatus = () => {
      setIsLikedByUser(product?.some((like) => like._id === userId));
    };

    checkLikedStatus();
  }, [product, userId]);

  const handleClick = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/products/like/${id}`,
        null,
        config
      );

      if (response.data?.data) {
        // Update the liked status
        setIsLikedByUser(!isLikedByUser);
        fetchProduct();
      }
    } catch (error) {
      console.error("Error liking product:", error);
      toast.error("Please log in to access this feature.");
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`shadow-sm h-10 w-10 flex items-center justify-center rounded-full border-white  ${
        isLikedByUser ? "bg-[#FCECFA]" : "bg-white"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={isLikedByUser ? "#EA64D9" : "white"}
        role="img"
        class="icon "
      >
        <path
          d="M10.7408 2C13.0889 2 14.6667 4.235 14.6667 6.32C14.6667 10.5425 8.11856 14 8.00004 14C7.88152 14 1.33337 10.5425 1.33337 6.32C1.33337 4.235 2.91115 2 5.2593 2C6.60745 2 7.48893 2.6825 8.00004 3.2825C8.51115 2.6825 9.39263 2 10.7408 2Z"
          stroke={isLikedByUser ? "#EA64D9" : "#000"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill={isLikedByUser ? "red" : "gray"}
      >
        <g>
          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
            fill="none"
            stroke="white"
            strokeWidth="1"
          />
        </g>
      </svg> */}
    </div>
  );
};

export default LikeIcon;
