import { Button } from "@mui/material";
import React, { useState } from "react";

const TimePicker = ({ onTimeSelect, selectedDate }) => {
  const times = [
    "12:00 am", "12:30 am",
    "1:00 am", "1:30 am",
    "2:00 am", "2:30 am",
    "3:00 am", "3:30 am",
    "4:00 am", "4:30 am",
    "5:00 am", "5:30 am",
    "6:00 am", "6:30 am",
    "7:00 am", "7:30 am",
    "8:00 am", "8:30 am",
    "9:00 am", "9:30 am",
    "10:00 am", "10:30 am",
    "11:00 am", "11:30 am",
    "12:00 pm", "12:30 pm",
    "1:00 pm", "1:30 pm",
    "2:00 pm", "2:30 pm",
    "3:00 pm", "3:30 pm",
    "4:00 pm", "4:30 pm",
    "5:00 pm", "5:30 pm",
    "6:00 pm", "6:30 pm",
    "7:00 pm", "7:30 pm",
    "8:00 pm", "8:30 pm",
    "9:00 pm", "9:30 pm",
    "10:00 pm", "10:30 pm",
    "11:00 pm", "11:30 pm",
];


  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleTimeClick = (time, index, e) => {
    e.preventDefault();
    setSelectedTime(time);
    setSelectedIndex(index);
    if (time) {
      onTimeSelect(time);
    }
  };

  // const handleSelectClick = (e) => {
  //   e.preventDefault()
  //   if (selectedTime) {
  //     onTimeSelect(selectedTime);
  //   }
  // };

  return (
    <div className="h-[435px] w-full md:w-fit overflow-y-auto">
      <div className="flex flex-col gap-3 lg:justify-start justify-center lg:items-start items-center mx-4 ">
        {times.map((time, index) => (
          <div key={index}>
            {selectedDate ? (
              selectedIndex !== index ? (
                <div
                  className="text-center bg-black border-[2px] border-[#013440]   w-[275px] py-2 cursor-pointer"
                  onClick={(e) => handleTimeClick(time, index, e)}
                >
                  <p className="m-0">{time}</p>
                </div>
              ) : (
                <div className="text-center bg-black flex gap-2 w-[275px]">
                  <div className="whitespace-nowrap border-[2px] border-[#013440]  w-1/2 py-2">
                    {selectedTime}
                  </div>
                  <div
                    className="bg-gray-500  hover:bg-primary-b w-1/2 py-2 border-0 text-black"
                    // onClick={(e) => handleSelectClick(e)}
                  >
                    Selected
                  </div>
                </div>
              )
            ) : (
              <div className="text-center border-[2px] border-[#013440] rounded-lg w-[230px] py-2 cursor-not-allowed  text-sm font-[montserrat]">
                <p className="m-0">Select a date first</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimePicker;
