import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageUploader from "./ImageUploader";
import Loader from "../../Component/Loader/Loader";
import { toast } from "react-toastify";

const General = () => {
  const baseURL = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(false);
  const [imageResponse, setImageResponse] = useState("");
  const [generalInfo, setGeneralInfo] = useState({ image: "" });
  const [profileInfo, setProfileInfo] = useState({});

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("users"));

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseURL}/users/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGeneralInfo(response?.data?.data);
        setProfileInfo(response?.data?.data);
        localStorage.setItem("users", JSON.stringify(response?.data?.data));
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.info(error?.response?.data?.message);
      }
    };
    fetchUserData();
  }, [baseURL, token]);

  // Update localStorage when profileInfo.firstName changes
  useEffect(() => {
    if (profileInfo.firstName) {
      localStorage.setItem("firstName", profileInfo.firstName);
    }
  }, [profileInfo.firstName]);

  const handleChange = (e, field) => {
    setProfileInfo({ ...profileInfo, [field]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const id = user._id;

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/users/${id}`,
        profileInfo,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      localStorage.setItem("firstName", response?.data?.data?.firstName);
      toast.success(response?.data.message);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="w-full  flex justify-center xl:items-start items-center flex-col lg:absolute lg:top-[30%] ">
      <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
        <span className="text-secondary-g mr-2">General</span> Information
      </p>
      <span className="pb-2">
        <ImageUploader
          baseUrl={baseURL}
          token={token}
          setImageResponse={(url) =>
            setProfileInfo({ ...profileInfo, image: url })
          }
          getImage={profileInfo.image}
        />
      </span>
      <div className="my-10 flex flex-wrap gap-7 items-start">
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>First Name</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            value={profileInfo?.firstName || ""}
            onChange={(e) => handleChange(e, "firstName")}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>Last Name</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            value={profileInfo?.lastName || ""}
            onChange={(e) => handleChange(e, "lastName")}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>Email</label>
          <input
            disabled
            className="text-white rounded-xl px-3 py-2 my-2"
            value={generalInfo?.email}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>Phone Number</label>
          <input
            className="text-black rounded-xl p-2 my-2"
            value={profileInfo?.phone || ""}
            onChange={(e) => handleChange(e, "phone")}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>Country</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            value={profileInfo?.country || ""}
            onChange={(e) => handleChange(e, "country")}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full">
          <label>City</label>
          <input
            className="text-black rounded-xl px-3 py-2 my-2"
            value={profileInfo?.city}
            onChange={(e) => handleChange(e, "city")}
          />
        </div>
      </div>
      {loading ? (
        <button className="font-[montserrat] rounded-full">
          Saving Changes..
        </button>
      ) : (
        <button
          className="font-[montserrat] rounded bg-secondary-g border-none px-5"
          onClick={handleClick}
        >
          Update
        </button>
      )}
    </div>
  );
};

export default General;
