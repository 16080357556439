import React from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/images/Banner_3.gif";

const Quotation = () => {
  return (
    <div className="relative flex gap-6 lg:flex-row flex-col  justify-between items-center font-[Macoxs] lg:mx-[5rem] mx-[2rem] my-[5rem] lg:my-[10rem]">
      <div className="">
        <h2 className="lg:text-[45px] sm:text-[25px] text-[#00F3F4] uppercase tracking-[5px]">
          <span className="text-[#9ADD3A]">our</span> Featured <br></br> themes
        </h2>
        <p className="text-white lg:text-base text-sm leading-8 font-[montserrat] capitalize xl:w-1/3 w-full">
          build a unique identity for your brand and allow it to thrive in the
          marketplace. our customised logo designs capture the true essence of
          what you stand for. we make sure every detail is on-brand and one of a
          kind because you deserve the best.
        </p>
      </div>
      <div className="xl:w-1/4 lg:w-1/2 xl:absolute  xl:left-[55%] ">
        <img src={BgImg} alt="" className="w-full" />
      </div>
    </div>
  );
};

export default Quotation;
