import React from "react";
import Logo from "../../../assets/images/Group 344.svg";
import Line from "../../../assets/images/Rectangle 185.png";
import { Link, useLocation } from "react-router-dom";
import FaqPage from "../../../Pages/ProfilePage/Faq/FaqPage";

const Footer = () => {
  const location = useLocation();
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className=" text-white  px-2 font-[montserrat] max-w-screen-2xl">
        <div className="flex justify-center items-center px-4 sm:px-0 o">
          <img src={Line} alt="" className=" w-[25%] lg:w-full" />
          <img
            src={Logo}
            alt=""
            className="lg:w-[25%] w-[50%]  mx-4 lg:mx-10"
          />
          <img src={Line} alt="" className="w-[25%] lg:w-full" />
        </div>
        <div className="flex md:flex-row flex-col  justify-between uppercase  border-b-2 border-white py-10">
          <ul className="space-y-3 py-4">
            <li className="text-[#9ADD3A] font-[Macoxs]">CONTACT US</li>
            <li>
              <Link className="text-white " to="tel:469-988-4899">
                469-988-4899
              </Link>
            </li>
            <li>
              <Link className="text-white" to="mailto:info@dipixels.com">
                info@dipixels.com
              </Link>
            </li>
          </ul>
          <ul className="space-y-3 py-4">
            <li className="text-[#9ADD3A] font-[Macoxs]">find us</li>
            <li>dipixels llc p.o. box 1</li>
            <li>hales corners, w153130,usa</li>
          </ul>
        </div>
        <div className="flex justify-between py-5">
          <p> © 2024 Pixel Pulse | All Rights Reserved.</p>
          <p>
            <Link
              to="/terms-and-conditions"
              className={`  ${
                location.pathname == "/terms-and-conditions"
                  ? "text-primary-b"
                  : "text-[#fff] hover:text-gray-400"
              }`}
            >
              Terms and Conditions{" "}
            </Link>
            |{" "}
            <Link
              to="/privacy-policy"
              className={`  ${
                location.pathname == "/privacy-policy"
                  ? "text-primary-b"
                  : "text-[#fff] hover:text-gray-400"
              }`}
            >
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              to="/faq"
              className={`  ${
                location.pathname == "/faq"
                  ? "text-primary-b"
                  : "text-[#fff] hover:text-gray-400"
              }`}
            >
              FAQ
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
