import React, { useState, useEffect } from "react";

const Calendar = ({ onDateSelect }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const getFebDays = (year) => {
    return isLeapYear(year) ? 29 : 28;
  };

  // State variables for current month and year
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [daysOfMonth, setDaysOfMonth] = useState([]);

  // Function to generate the calendar for a given month and year
  const generateCalendar = (month, year) => {
    let firstDay = new Date(year, month, 1);
    let daysInMonth = [
      31,
      getFebDays(year),
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    let days = [];

    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null); // Add null placeholders for days before the first day of the month
    }

    for (let i = 1; i <= daysInMonth[month]; i++) {
      days.push(i); // Add days of the month
    }

    setDaysOfMonth(days);
  };

  // Update the calendar when the month or year changes
  useEffect(() => {
    generateCalendar(currMonth, currYear);
  }, [currMonth, currYear]);

  // Function to change month forward
  const changeMonthForward = () => {
    let newMonth = currMonth + 1;
    let newYear = currYear;

    // Adjust year if newMonth exceeds December
    if (newMonth > 11) {
      newMonth = 0; // Set to January
      newYear += 1; // Increment year
    }

    setCurrMonth(newMonth);
    setCurrYear(newYear);
  };

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleDateClick = (day) => {
    const today = new Date();
    const selectedDate = new Date(currYear, currMonth, day);

    // Prevent selecting dates before today
    if (selectedDate < today) {
      return;
    }

    const formattedDate = `${monthNames[currMonth]} ${day}, ${currYear}`;
    onDateSelect(formattedDate);
    setSelectedDate(formattedDate);
  };

  return (
    <div className={`grow calendar ${isDarkMode ? "dark" : "light"}`}>
      <div className="calendar-header  text-sm font-[montserrat]">
        <span
          className="month-year-change"
          onClick={() => setCurrMonth(currMonth - 1)}
        >
          {"< "}
        </span>
        {monthNames[currMonth]} {currYear}
        <span className="month-year-change" onClick={changeMonthForward}>
          {" >"}
        </span>
      </div>
      <div className="calendar-body  text-sm font-[montserrat]">
        <div className="calendar-week-day  text-sm font-[montserrat]">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="calendar-days">
          {daysOfMonth.map((day, index) => {
            const today = new Date();
            const date = new Date(currYear, currMonth, day);
            const isBeforeToday = date < today && day !== null;

            return (
              <div
                key={index}
                className={`calendar-day${day === null ? " empty" : ""}${
                  date.getDate() === today.getDate() &&
                  currMonth === today.getMonth() &&
                  currYear === today.getFullYear()
                    ? " current"
                    : ""
                }${
                  selectedDate === `${monthNames[currMonth]} ${day}, ${currYear}`
                    ? " bg-primary-b text-black text-sm font-[montserrat]"
                    : ""
                }${isBeforeToday ? " disabled" : ""}`}
                onClick={() => !isBeforeToday && handleDateClick(day)}
                style={{ cursor: isBeforeToday ? "not-allowed" : "pointer" }}
              >
                {day}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="calendar-footer">
        <div className="toggle">
          <span>Dark Mode</span>
          <div className="dark-mode-switch" onClick={toggleDarkMode}>
            <div className="dark-mode-switch-ident"></div>
          </div>
        </div>
      </div> */}
      <div className="month-list"></div>
    </div>
  );
};

export default Calendar;