import React from "react";
import { useNavigate } from "react-router-dom";

const ProductNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
      <h2 className="text-3xl font-bold mb-4 font-[Macoxs]">404 - Product Not Found</h2>
      <p className="text-lg font-[montserrat] text-white mb-6">
        Sorry, the product you are looking for does not exist.
      </p>
      <button onClick={() => navigate(-1)} className="hover:bg-opacity-75 font-[montserrat] bg-primary-b font-bold rounded-full text-black px-4 py-2  hover:bg-9ADD3A hover:text-white focus:outline-none">
        Go Back
      </button>
    </div>
  );
};

export default ProductNotFound;
