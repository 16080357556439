import React, { useState, useEffect } from 'react';

function Alert(props) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
    const timeout = setTimeout(() => {
      setShow(false);
    }, 2000); 

    return () => clearTimeout(timeout);
  }, [props.text]); 

  return (
    <>
      {show && (
        <div 
        className={`alert ${props.class} position-fixed top-0 end-0 p-3 m-3`}
        role="alert">
          {props.text}
        </div>
      )}
    </>
  );
}

export default Alert;
