import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CancelIcon, SearchIcon } from "../../../svg/utils";
import Logo from "../../../assets/images/Group 344.svg";
import Cart from "../../../assets/images/cart.svg";
import { toast, ToastContainer } from "react-toastify";
import { ArrowRightIcon, LogoutIcon, ProfileIcon } from "../../../svg";
import axios from "axios";

const Header = () => {
  const baseURL = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const token = localStorage.getItem("token");
  const firstName = localStorage.getItem("firstName");
  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setError("");
  };

  const fetchProducts = async () => {
    try {
      if (!searchQuery.trim()) {
        setError("Please enter a search query");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/products/`,
        {
          query: {
            title: searchQuery,
          },
        }
      );

      setProducts(response.data);

      if (response.data.data.length > 0) {
        navigate(`/search/${searchQuery.toLowerCase().replace(/ /g, "_")}`, {
          state: { product: response.data.data },
        });
      } else {
        navigate("/product-not-found");
      }
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };

  
  useEffect(() => {
    const products = JSON.parse(localStorage.getItem("products")) || [];
    setQuantity(products);
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("products");
    localStorage.removeItem("users");
    localStorage.removeItem("pixelUser_Details");
    localStorage.removeItem("updatedProducts");
    setShowDropdown(false); // Close the dropdown on logout
    toast.success("Logged out successfully!");
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const menuItemStyle = {
    display: "block",
    padding: "8px",
    borderBottom: "1px solid #f3f4f6",
    color: "#83edf9",
    textDecoration: "none",
    transition: "background-color 0.3s ease",
  };

  menuItemStyle.hover = {
    backgroundColor: "#f3f4f6",
  };

  return (
    <>
      <div className="flex sm:gap-0 gap-2  text-[14px] text-white justify-between items-center my-10 xl:mx-[5rem] md:mx-[3rem] mx-[1rem]">
        <Link to={"/"} className="lg:w-[12rem] md:w-[9rem] w-[6rem]">
          <img className="w-full h-full" src={Logo} alt="Logo" />
        </Link>

        <div className="flex gap-3 justify-end items-center">
          <div className="flex relative">
            <span className="cursor-pointer" onClick={toggleSearch}>
              {!isSearchOpen ? <SearchIcon /> : <CancelIcon />}
            </span>
            {isSearchOpen && (
              <div
                className={`absolute md:top-[-8px]  md:right-8 right-[-8.8rem] top-[3.5rem] md:w-auto w-[85vw]   transition-all duration-500 overflow-hidden py-2 focus:outline-none border-2 flex pl-3 gap-0 md:gap-4  border-primary-b caret-primary-b rounded-md`}
              >
                <div className="scale-90">
                  <SearchIcon />
                </div>
                <input
                  type="search"
                  placeholder={error ? error : "Search"}
                  className={`focus:outline-0 placeholder-primary pl-2 font-semibold  text-[13px] caret-primary text-white bg-black border-0 ${
                    error ? "placeholder-red-500" : ""
                  }`}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchProducts();
                    }
                  }}
                />

                <button
                  className="py-0 px-2 -my-2 ml-auto bg-primary-b border-0 "
                  onClick={fetchProducts}
                >
                  <ArrowRightIcon />
                </button>
              </div>
            )}
          </div>

          {token ? (
            <div className="relative">
              <div
                className="raise border-[#54e9e4] border-[1px] md:px-3 px-2 py-2 rounded-full lg:text-[15px] md:text-[12px] text-[8px] lg:gap-2 gap-1  flex justify-center items-center"
                onClick={toggleDropdown}
              >
                {/* <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg"
                  alt="Profile"
                  className="w-8 h-8 rounded-full mr-2"
                /> */}

                <ProfileIcon />

                {firstName}
              </div>
              {showDropdown && (
                <div
                  className="absolute z-20 w-[9.25rem] top-full right-0 text-black bg-black shadow-lg rounded-md mt-1 border-primary-b border-2"
                  style={{ padding: "8px", fontSize: "14px" }}
                >
                  <ul
                    className="m-0 p-0 text-primary-b"
                    style={{ listStyleType: "none", paddingLeft: "0px" }}
                  >
                    {/* <li>
                      <Link
                        className="text-primary-b"
                        to="/"
                        style={menuItemStyle}
                      >
                        Dashboard
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile" style={menuItemStyle}>
                        Profile
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/settings" style={menuItemStyle}>
                        Settings
                      </Link>
                    </li> */}
                    <li>
                      <button
                        onClick={handleLogOut}
                        className="font-[montserrat] flex items-center gap-1.5 justify-center bg-primary-b text-black mt-2 w-full rounded-lg hover:bg-white  p-2 font-bold"
                      >
                        <LogoutIcon />
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="flex gap-2 items-center">
              <Link to="/login">
                <button className="font-[montserrat] raise py-2 px-3 rounded-md lg:text-[15px] text-[12px] ">
                  Log In
                </button>
              </Link>
              <Link to="/signup">
                <button className=" font-[montserrat] raise py-2 px-3 rounded-md lg:text-[15px] text-[12px]  ">
                  Sign Up
                </button>
              </Link>
            </div>
          )}

          {/* {token && ( */}
          <div>
            <Link to="/payment">
              <div className="relative raise rounded-full border-[1px] mx-2 border-[#00F3F4] lg:p-3 p-2  flex justify-center items-center">
                <img src={Cart} alt="Cart" className="lg:w-5 w-4" />
                {quantity.length > 0 && (
                  <p className="text-gray-900 font-bold text-center absolute -top-4 -left-2 bg-secondary-g rounded-full w-6 h-6">
                    {quantity.length}
                  </p>
                )}
              </div>
            </Link>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default Header;
