import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Select from "react-select";
import axios from "axios";
import { MDBBtn } from "mdb-react-ui-kit";
import ApiUrl from "../../Constant/ApiUrl";
import Alert from "../../Component/Alert/Alert";
import { toast } from "react-toastify";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import closeIcon from "../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Popup from "./components/Popup";

export const Product = () => {
  const baseURL = process.env.REACT_APP_URL;
  const [images, setImages] = useState([]);
  const [mockupimages, setMockupImages] = useState("");
  const [folderPath, setFolderPath] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingMockup, setIsDraggingMockup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [initialOptions, setInitialOptions] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [titleError, setTitleError] = useState("");
  const [imagesError, setImagesError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [addDetailError, setAddDetailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Upload");
  const [inputs, setInputs] = useState([{ id: 1, value: "" }]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isMockupImageLoading, setisMockupImageLoading] = useState(false);
  const [mockupImagesError, setMockupImagesError] = useState("");
  const [zipFile, setZipFile] = useState("");
  const [isZipLoading, setIsZipLoading] = useState(false);
  const [zipFileError, setZipFileError] = useState("");
  const [zipUploaded, setZipUploaded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editorState, setEditorState] = useState();
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "embedded",
      "remove",
      "history",
    ],
    inline: {
      inDropdown: false,
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "monospace",
        "superscript",
        "subscript",
      ],
    },
    colorPicker: {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 14.5l3.5-4.5h-7z" opacity=".3"/><path d="M17 11c0 3.87-3.13 7-7 7s-7-3.13-7-7 3.13-7 7-7 7 3.13 7 7zm-4-3v2h-4v2h4v2h-2c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h2v2h-4v2h4c1.1 0 2-.9 2-2v-3c0-1.1-.9-2-2-2h-2v-2h4v-2h-4v-2h2c1.1 0 2 .9 2 2v1c0 1.1-.9 2-2 2h-2z"/></svg>',
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: [
        "rgba(0,0,0,0)",
        "red",
        "orange",
        "yellow",
        "green",
        "blue",
        "indigo",
        "violet",
        "#ffffff",
      ], // Add white color here
    },
  };

  const contentStyle = {
    color: "#FFFFFF", // Set default text color to white
    backgroundColor: "#000000", // Set background color if needed
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    setDescription(html);
  };

  const handleInputChange = (id, value) => {
    const updatedInputs = inputs.map((input) =>
      input.id === id ? { ...input, value: value } : input
    );
    setInputs(updatedInputs);
  };

  const handleAddInput = () => {
    const newInput = { id: inputs.length + 1, value: "" };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (id) => {
    const updatedInputs = inputs.filter((input) => input.id !== id);
    setInputs(updatedInputs);
  };
  const validateAddDetails = () => {
    const trimmedInputs = inputs.map((input) => input?.value?.trim());
    const hasNonEmptyInput = trimmedInputs?.some((input) => input !== "");
    if (!hasNonEmptyInput) {
      setAddDetailError("Detail is required");
      return false;
    }
    setAddDetailError("");
    return true;
  };

  const validateTitle = () => {
    if (!title.trim()) {
      setTitleError("Title is required");
      return false;
    }
    setTitleError("");
    return true;
  };

  const validatePrice = () => {
    if (!price.trim()) {
      setPriceError("Price is required");
      return false;
    }
    if (isNaN(price) || price <= 0) {
      setPriceError("Price must be a valid number greater than 0");
      return false;
    }
    setPriceError("");
    return true;
  };

  const validateDescription = () => {
    if (description.trim().length < 15) {
      setDescriptionError("Description must be at most 15 characters");
      return false;
    }
    setDescriptionError("");
    return true;
  };

  const validateCategory = () => {
    if (!selectedOption) {
      setCategoryError("Category is required");
      return false;
    }
    setCategoryError("");
    return true;
  };

  const validateForm = () => {
    const isTitleValid = validateTitle();
    const isPriceValid = validatePrice();
    const isDescriptionValid = validateDescription();
    const isCategoryValid = validateCategory();
    const isDetailValid = validateAddDetails();
    return (
      isTitleValid &&
      isPriceValid &&
      isDescriptionValid &&
      isCategoryValid &&
      isDetailValid
    );
  };

  const handleImageUpload = async (files) => {
    setIsImageLoading(true);

    if (files.length === 0) return;

    if (images.length + files.length > 4) {
      toast.error("Maximum 4 images can be uploaded");
      setIsImageLoading(false);

      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }

    try {
      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-many`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setImagesError("");
      setIsImageLoading(false);
      toast.success(response?.data?.message);
      setImages([...images, ...response.data.url]);
    } catch (error) {
      toast.error(
        "Unable to upload image. Server rejected the file. Please ensure the file format is supported and try again later"
      );
      setIsImageLoading(false);
    }
  };
  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleMockupImageUpload = async (files) => {
    setisMockupImageLoading(true);

    if (files.length === 0) return;

    const formData = new FormData();
    formData.append("image", files[0]); // Assuming only one file is selected for mockup image

    try {
      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-many`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMockupImagesError("");
      setisMockupImageLoading(false);
      toast.success(response?.data?.message);
      setMockupImages(response.data.url[0]);
    } catch (error) {
      toast.error(
        "Unable to upload image. Server rejected the file. Please ensure the file format is supported and try again later"
      );
      setisMockupImageLoading(false);
     }
  };
  //zip file
  const handleZipFileUpload = async (files) => {
    setIsZipLoading(true);
    setZipUploaded(false);
    if (files.length === 0) {
      setIsZipLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("zip", files[0]);

    try {
      const token = localStorage.getItem("adminToken");
      if (!token) {
        throw new Error("No authorization token found.");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-folder`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setZipFileError("");
      setZipUploaded(true);
      toast.success(response?.data?.message);
      setFolderPath(response.data?.hostedUrl);
    } catch (error) {
      console.error(
        "Error details:",
        error.response ? error.response.data : error.message
      );
      toast.error(
        "Unable to upload file. Server rejected the file. Please ensure the file format is supported and try again later"
      );
    } finally {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = ""; // Clear the file input value
      }
      setIsZipLoading(false);
    }
  };

  const removeMockupImage = () => {
    setMockupImages("");
  };
  const removeZipImage = () => {
    setFolderPath("");
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleImageUpload(files);
  };
  //handle zip
  const handleDropZip = (e) => {
    e.preventDefault();
    setIsDraggingMockup(false);
    const files = Array.from(e.dataTransfer.files);
    handleZipFileUpload(files);
  };
  const handleDropMockup = (e) => {
    e.preventDefault();
    setIsDraggingMockup(false);
    const files = Array.from(e.dataTransfer.files);
    handleMockupImageUpload(files);
  };
  const handleUpload = (e) => {
    const files = e.target.files;
    handleImageUpload(files);
  };
  //zip
  const handleUploadZip = (e) => {
    const files = e.target.files;
    handleZipFileUpload(files);
  };
  const handleUploadMockup = (e) => {
    const files = e.target.files;
    handleMockupImageUpload(files);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleSubmit = () => {
    if (images.length === 0) {
      setImagesError("At least one image is required");
    }
    if (mockupimages === "") {
      setMockupImagesError("Mockup is required");
    }
    if (validateForm()) {
      const token = localStorage.getItem("adminToken");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const data = JSON.stringify({
        title: title,
        description: description,
        categories: selectedOptionId,
        price: price,
        images: images,
        mockupImage: mockupimages,
        folderPath: folderPath,
        features: inputs.map((input) => input.value),
      });
      if (selectedRadio === "Upload") {
        axios
          .post(`${process.env.REACT_APP_URL}/products/add`, data, { headers })
          .then((response) => {
            setImages([]);
            setTitle("");
            setInputs([{ id: 1, value: "" }]);
            setDescription("");
            setPrice("");
            setSelectedOption(null);
            setImagesError("");
            setMockupImages("");
            setMockupImagesError("");
            setFolderPath("");
            setEditorState("");
            toast.success(response.data.message);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              const errorMessage = error.response.data.error;
              if (errorMessage.includes("duplicate key error")) {
                toast.error("This product already exists.");
              } else {
                toast.error(errorMessage);
              }
            }
          });
      } else if (selectedRadio === "Draft") {
        axios
          .post(`${process.env.REACT_APP_URL}/drafts/add`, data, { headers })
          .then((response) => {
            setImages([]);
            setTitle("");
            setDescription("");
            setPrice("");
            setInputs([{ id: 1, value: "" }]);
            setSelectedOption(null);
            setImagesError(""); // Reset imagesError state
            setMockupImages("");
            setMockupImagesError("");
            setFolderPath("");
            toast.success(response.data.message);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              const errorMessage = error.response.data.error;
              if (errorMessage.includes("duplicate key error")) {
                toast.error("This product already exists.");
              } else {
                toast.error(errorMessage);
              }
            }
          });
      }
    }
  };

  const getCategories = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/categories/`,
        null,
        config
      );
      setInitialOptions(
        response.data.data.map((option) => ({
          value: option.tag,
          label: option.tag,
          id: option._id,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="row bg-black w-full m-0 ">
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 md:p-5 px-4  pt-16  min-h-screen">
        <div className="flex justify-between items-center flex-wrap mb-3">
          <h1 className=" text-xl font-bold uppercase text-primary-b">
            Upload Product
          </h1>
          <button
            className="border-primary-b text-primary-b"
            onClick={openPopup}
          >
            Import From Excel
          </button>
        </div>

        <div
          className={`mb-2 border-2 border-dashed rounded-lg min-h-44 p-5 flex items-center justify-center flex-col ${
            isDraggingMockup ? "border-primary-b" : "border-secondary-g"
          }`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDraggingMockup(true);
          }}
          onDragLeave={() => setIsDraggingMockup(false)}
          onDrop={handleDropMockup}
        >
          <label className="flex cursor-pointer  font-bold items-center justify-center flex-col gap-4 text-2xl text-secondary-g  text-center ">
            {isDraggingMockup ? (
              <span className="text-primary-b ">Drop here</span>
            ) : (
              <span>Drag & drop or click to upload Mockup image</span>
            )}
            <input
              type="file"
              accept="image/*"
              multiple
              className="hidden"
              onChange={handleUploadMockup}
            />
          </label>
          {mockupImagesError && (
            <p className="text-red-500 mt-1">{mockupImagesError}</p>
          )}

          <div className="">
            {mockupimages ? (
              <div className="relative mt-4">
                <img
                  src={mockupimages}
                  alt={`Image 1`}
                  className="w-24 h-24 object-contain rounded-md shadow-md"
                />
                <img
                  onClick={() => removeMockupImage(0)} // Assuming you always want to remove the first image
                  src={closeIcon}
                  alt={`closeIcon`}
                  width={20}
                  className="absolute -top-4 -right-4 cursor-pointer object-contain rounded-md shadow-md"
                />
              </div>
            ) : (
              isMockupImageLoading && (
                <div className="w-20 h-20 rounded-md shadow-md animate-pulse bg-slate-700"></div>
              )
            )}
          </div>
        </div>

        <div
          className={`mb-2 border-2 border-dashed rounded-lg min-h-96 p-5 flex items-center justify-center flex-col ${
            isDragging ? "border-primary-b" : "border-secondary-g"
          }`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop} // Make sure handleDrop
        >
          <label className="flex cursor-pointer text-transform:  font-bold items-center justify-center flex-col gap-4 text-2xl text-secondary-g  text-center ">
            {isDragging ? (
              <span className="text-primary-b ">Drop here</span>
            ) : (
              <span>Drag & drop or click to upload images</span>
            )}
            <input
              type="file"
              accept="image/*"
              multiple
              className="hidden"
              onChange={handleUpload}
            />
          </label>
          {imagesError && <p className="text-red-500 mt-1">{imagesError}</p>}

          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 p-4">
            {images?.map((image, index) => (
              <div className="relative">
                {" "}
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="w-24 h-24 object-contain rounded-md shadow-md"
                />
                <img
                  onClick={() => removeImage(index)}
                  src={closeIcon}
                  alt={`closeIcon`}
                  width={20}
                  className="absolute -top-4 -right-4 cursor-pointer object-contain rounded-md shadow-md"
                />
              </div>
            ))}
            {isImageLoading && (
              <div className="w-20 h-20 rounded-md shadow-md animate-pulse bg-slate-700"></div>
            )}
          </div>
        </div>

        <div
          className={`mb-2 border-2 border-dashed rounded-lg min-h-44 p-5 flex items-center justify-center flex-col ${
            isZipLoading ? "border-primary-b" : "border-secondary-g"
          }`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsZipLoading(true);
          }}
          onDragLeave={() => setIsZipLoading(false)}
          onDrop={handleDropZip}
        >
          <label className="flex cursor-pointer text-transform:  font-bold items-center justify-center flex-col gap-4 text-2xl text-secondary-g  text-center ">
            {isZipLoading ? (
              <span className="text-primary-b animate-pulse">Uploading File...</span>
            ) : (
              <span>
                {zipUploaded ? (
                  <>Zip File Uploaded Successfully</>
                ) : (
                  <>Drag & drop or click to upload Zip file</>
                )}
              </span>
            )}
            <input
              type="file"
              accept=".zip"
              className="hidden"
              id="fileInput"
              onChange={handleUploadZip}
            />
          </label>
          {zipFileError && <p className="text-red-500 mt-1">{zipFileError}</p>}

          {/* <div className="">
            {zipFile ? (
              <div className="relative ">
                <span className="w-24 h-24 object-contain rounded-md shadow-md">
                  {zipFile}
                </span>
                <img
                  onClick={() => setZipFile("")} // Assuming you want to remove the ZIP file
                  src={closeIcon}
                  alt={`closeIcon`}
                  width={20}
                  className="absolute -top-4 -right-4 cursor-pointer object-contain rounded-md shadow-md"
                />
              </div>
            ) : (
              isZipLoading && (
                <div className="w-20 h-20 rounded-md shadow-md animate-pulse bg-slate-700"></div>
              )
            )}
          </div> */}
        </div>

        <div className="mb-3">
          <label
            htmlFor="title"
            className="block font-medium text-secondary-g mb-2"
          >
            Title
          </label>
          <input
            id="title"
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={handleTitleChange}
            className={`border border-gray-300 text-white bg-transparent rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              titleError ? "border-red-500" : ""
            }`}
          />
          {titleError && <p className="text-red-500 mt-1">{titleError}</p>}
        </div>

        <div className="mb-3 mt-6 select_cat">
          <label
            htmlFor="categories"
            className="block font-medium text-secondary-g mb-2  "
          >
            Categories
          </label>
          <Select
            value={selectedOption}
            onChange={(selectedOption) => {
              setSelectedOption(selectedOption);
              if (selectedOption) {
                const selectedTag = initialOptions.find(
                  (option) => option.label === selectedOption.label
                );
                setSelectedOptionId(selectedTag.id);
              }
            }}
            className="text-white"
            options={initialOptions}
          />
          {categoryError && (
            <p className="text-red-500 mt-1">{categoryError}</p>
          )}
        </div>
        <div className="mb-3">
          <label
            htmlFor="title"
            className="block  font-medium text-secondary-g mb-2"
          >
            Price
          </label>
          <input
            id="Price"
            type="number"
            min={1}
            placeholder="Enter Price"
            value={price}
            onChange={handlePriceChange}
            className={`border border-gray-300 text-white bg-transparent rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              priceError ? "border-red-500" : ""
            }`}
          />
          {priceError && <p className="text-red-500 mt-1">{priceError}</p>}
        </div>
        <div className="mb-3">
          <label
            htmlFor="description"
            className="block  font-medium text-secondary-g mb-2"
          >
            Description
          </label>
          <div className="border border-gray-50">
            <Editor
              editorState={editorState}
              toolbar={toolbarOptions}
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              editorStyle={contentStyle}
            />
          </div>

          {descriptionError && (
            <p className="text-red-500 mt-1">{descriptionError}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_details"
            className="block  font-medium text-secondary-g mb-2"
          >
            Add Features
          </label>
          {inputs.map((input) => (
            <div
              key={input.id}
              className="mb-3 flex flex-col sm:flex-row items-center gap-2"
            >
              <input
                type="text"
                placeholder="Enter feature"
                value={input.value}
                onChange={(e) => handleInputChange(input.id, e.target.value)}
                className="border text-white bg-transparent rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {inputs.length > 1 && (
                <button
                  className=" bg-secondary-g text-sm py-2.5 rounded-md hover:bg-opacity-75 px-3 border-0 font-bold text-black hover:text-black focus:outline-none "
                  onClick={() => handleRemoveInput(input.id)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          {descriptionError && (
            <p className="text-red-500 mt-1">{addDetailError}</p>
          )}
          <MDBBtn className="hover:brightness-90" onClick={handleAddInput}>
            <span className="text-black">Add Input</span>
          </MDBBtn>
        </div>
        <div className="flex items-center gap-4 mb-6">
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="upload"
              name="option"
              value="Upload"
              checked={selectedRadio === "Upload"}
              onChange={handleOptionChange}
              className="accent-[#96ff00] scale-125"
            />
            <label
              htmlFor="upload"
              className={`text-lg font-semibold ${
                selectedRadio === "Upload" ? "text-secondary-g" : "text-white"
              }`}
            >
              Upload
            </label>
          </div>
          {/* <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="draft"
              name="option"
              value="Draft"
              checked={selectedRadio === "Draft"}
              onChange={handleOptionChange}
              className="accent-[#96ff00] scale-125"
            />
            <label
              htmlFor="draft"
              className={`text-lg font-semibold ${selectedRadio === "Draft" ? "text-secondary-g" : "text-white"
                }`}
            >
              Draft
            </label>
          </div> */}
        </div>
        <MDBBtn className="hover:brightness-90" onClick={handleSubmit}>
          <span className="text-black">Submit</span>
        </MDBBtn>
        {isPopupOpen && (
          <Popup onClose={closePopup} categories={initialOptions} />
        )}
      </div>
    </div>
  );
};
