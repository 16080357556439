import React, { useState } from "react";
import axios from "axios";
import ResetPassword from "./ResetPassword";
import Cut2 from "../../assets/images/Icon ionic-ios-close-circle-outlineblack.svg";
import { CancelIcon } from "../../svg/utils";
import { toast } from "react-toastify";

const ForgetPassword = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({});

    // Email validation
    let newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    // Set errors if any
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/forget`,
        { email }
      );
      setShowModal(true);
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="fixed bg-[#000] backdrop-blur-sm h-screen w-full top-0 left-0 bg-opacity-45">
      <div className="text-white  absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2 w-full flex flex-col justify-center items-center">
        <div className="bg-[#000] border-2 border-primary-b max-w-[25rem] w-full space-y-6 p-10 rounded-2xl relative">
          <span
            className="cursor-pointer text-white text-[45px] scale-125 absolute top-6 right-6"
            onClick={onClose}
          >
            <CancelIcon />
          </span>
          <h2 className="lg:text-[30px] md:text-[20px] text-[13px] font-bold text-center text-primary-b">
            Forgot Password?
          </h2>
          <form className="flex flex-col">
            <label className="text-gray-300 lg:text-lg text-sm">Email Address</label>
            <input
              placeholder="Email"
              className=" rounded-full py-2 px-3 my-2 focus:outline-secondary-g focus:placeholder:text-secondary-g focus:bg-black text-white outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <span className="text-red-500">{errors.email}</span>
            )}
            <button
              className="font-[montserrat] rounded-full my-3 p-2.5 hover:bg-white hover:text-black font-bold"
              onClick={handleSubmit}
            >
              Reset Password
            </button>
          </form>
        </div>
        {showModal && <ResetPassword email={email} />}
      </div>
    </div>
  );
};

export default ForgetPassword;
