import React, { useState } from "react";
import dipixels from "../../assets/images/Projects/Dipixels.png";
// import Icon from "../../../assets/images/Icon feather-arrow-up-right.svg";
import Icon from "../../../src/assets/images/Icon feather-arrow-up-right.svg";
import IconBlue from "../../../src/assets/images/icon-blue.svg";
const Animation = () => {
  const [offsetX, setOffsetX] = useState(5);

  const handleMouseMove = (e) => {
    setOffsetX(e.clientX);
  };

  return (
    <div onMouseMove={handleMouseMove} className="my-20">
      <div className="flex justify-center">
        {/* <img
          className="lg:h-full md:h-[15vh]"
          src={dipixels}
          alt=""
          style={{ transform: `translateX(-${offsetX}px)` }}
        /> */}
        <h2
          className="text-[9.65vw] flex gap-10 font-[Macoxs]  text-clip text-transparent"
          style={{
            transform: `translateX(-${offsetX}px)`,
            WebkitTextStroke: "3px rgba(0, 243, 244,0.6)",
          }}
        >
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
        </h2>
        {/* <img
          className="lg:h-full md:h-[15vh]"
          src={dipixels}
          alt=""
          style={{
            transform: `translateX(-${offsetX}px)`,
            marginRight: "10px",
          }}
        /> */}
      </div>
      <div className="flex justify-center items-center lg:gap-10 gap-2 lg:flex-row flex-col mx-2">
        <p className="lg:text-[55px] font-[Macoxs] md:text-[45px] text-[30px] text-center text-secondary-g uppercase ">
          START A NEW PROJECT
        </p>
        <img src={IconBlue} alt="" className="hidden lg:block w-10 -mt-5" />
      </div>
      {/* Second set of images moving right */}
      <div className="flex justify-center">
        {/* <img
          className="lg:h-full md:h-[15vh]"
          src={dipixels}
          alt=""
          style={{ transform: `translateX(${offsetX}px)` }}
        />
        <img
          className="lg:h-full md:h-[15vh]"
          src={dipixels}
          alt=""
          style={{ transform: `translateX(${offsetX}px)` }}
        /> */}
        <h2
          className="text-[9.65vw] flex gap-10 font-[Macoxs]  text-clip text-transparent "
          style={{
            transform: `translateX(${offsetX}px)`,
            WebkitTextStroke: "3px rgba(0, 243, 244,0.6)",
          }}
        >
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
          <span>DIPIXELS</span>
        </h2>
      </div>
    </div>
  );
};

export default Animation;
