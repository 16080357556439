import React, { useState } from "react";
// import Project from "../../../components/projects/Project";
import Animation from "../../components/projects/Animation";
import Marquee from "../../components/projects/Marquee";
import Quotation from "../../components/projects/Quotation";
import Header from "../../components/Common/header/Header";
import Footer from "../../components/Common/footer/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Quotation />
      {/* <SubNav /> */}

     

      <div className="p-4 lg:p-10">
        <Marquee />
      </div>
      {/* <Project /> */}
      <Animation />
      <Footer />
    </div>
  );
};

export default Home;
