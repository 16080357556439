import React from "react";
import arrowback from "../../../src/assets/images/arrowback.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/dipixels-logo-full.png";

export const OnBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(-1);

    if (location.pathname !== window.location.pathname) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div
      className="cursor-pointer mt-4 mb-4 flex gap-3 text-center"
      onClick={handleClick}
    >
      <img src={arrowback} alt="" className="lg:w-[3rem] w-[2rem]" />
      <img className="lg:w-[12rem] md:w-[9rem] w-[8rem] lg:px-0 " src={Logo} alt="" />
    </div>
  );
};
