import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";
import OptionsDropdown from "../../Component/Dropdown/OptionsDropdown";
import CenteredModal from "../../Component/CenterModal/CenteredModal";
import NoImage from "../../assets/images/No_Image_Available.jpg";

import Alert from "../../Component/Alert/Alert";
import axios from "axios";
import Loader from "../../Component/Loader/Loader";
import ApiUrl from "../../Constant/ApiUrl";
import { CancelIcon } from "../../svg/utils";
import { useLocation } from "react-router-dom";
import OrderDetailModa from "./OrderDetailModa";
import { toast } from "react-toastify";
import { Pagination, Stack } from "@mui/material";

function Orders() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [basicActive, setBasicActive] = useState("Pending");
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderModal, setCreateOrder] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;
  const baseURL = process.env.REACT_APP_URL;

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setBasicActive(location.state.activeTab);
    }
  }, []);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      setBasicActive(value);
      setCurrentPage(1);
    }
  };

  const getOrders = async (status) => {
    setLoader(true);
    const token = localStorage.getItem("adminToken");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const data = {
      filter: {
        status: "Completed",
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/orders?page=${currentPage}&limit=${limit}`,
        data,
        {
          headers,
        }
      );
      setOrders(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getOrders(basicActive);
  }, [basicActive, currentPage]);

  const handleUpdateStatus = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };
  const [selectedCustomerOrder, setSelectedCustomerOrder] = useState(null);

  const detailOrder = (id) => {
    const selectedOrder = orders.find((order) => order._id === id);
    setCreateOrder(true);
    setSelectedProductId(id);
    setSelectedCustomerOrder(selectedOrder);
  };

  const handleStatusUpdate = async () => {
    setLoader(true);

    try {
      const token = localStorage.getItem("adminToken");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/orders/${selectedOrder._id}`,
        {
          status: selectedStatus,
        },
        {
          headers,
        }
      );
      toast.success(response.data.message);
      setShowModal(false);
      getOrders(basicActive, currentPage);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getOrders(basicActive, value);
  };
  return (
    <div className="row m-0">
      {showModal && (
        <div
          className={`fixed z-10 inset-0 bg-[#000] bg-opacity-10 backdrop-blur-sm overflow-y-auto ${
            showModal ? "" : "hidden"
          }`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            <div className="relative  border-2 border-primary-b rounded-2xl bg-black w-[90%]  sm:w-2/3 md:w-1/2 lg:max-w-96 xl:w-1/4 max-h-full overflow-auto">
              <div className="bg-black p-8 rounded shadow-lg">
                <div className="flex justify-between items-center mb-4">
                  <h3
                    className="text-lg lg:text-2xl font-semibold mb-0 text-primary-b"
                    id="modal-title"
                  >
                    Update Status
                  </h3>
                  <button
                    className="border-0 scale-125 p-0"
                    onClick={() => setShowModal(false)}
                  >
                    <CancelIcon />
                  </button>
                </div>
                <div className="mb-4">
                  <h5 className=" text-white text-base mb-4">
                    Order ID: {selectedOrder && selectedOrder._id}
                  </h5>
                  <select
                    className="form-select mb-3 bg-black text-white outline-primary-b border-primary-b"
                    value={selectedStatus}
                    defaultValue={basicActive}
                    onChange={handleStatusChange}
                  >
                    {basicActive === "Pending" && (
                      <option value="Pending">Pending</option>
                    )}
                    <option value="Processing">Processing</option>
                    {basicActive !== "Pending" && (
                      <>
                        <option value="Completed">Completed</option>
                      </>
                    )}
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    className="font-[montserrat] bg-primary-b text-center hover:bg-opacity-80 text-black font-bold py-2 w-full rounded-lg focus:outline-none focus:shadow-outline"
                    onClick={handleStatusUpdate}
                  >
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <Loader />}
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      <div
        className="col-lg-10 col-md-12 col-sm-12 tableList "
        style={{ backgroundColor: "#000", height: "100vh" }}
      >
        <MDBRow
          className="no-gutters"
          style={{ marginTop: "50px", padding: 20 }}
        >
          <h1 className=" text-xl font-bold uppercase text-primary-b">
            Orders
          </h1>
        </MDBRow>

        {/* <MDBTabs className="mb-3">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("Completed")}
              active={basicActive === "Completed"}
            >
              Complete
            </MDBTabsLink>
          </MDBTabsItem> */}
        {/* <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("Processing")}
              active={basicActive === "Processing"}
            >
              Processing
            </MDBTabsLink>
          </MDBTabsItem> */}
        {/* <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("Completed")}
              active={basicActive === "Completed"}
            >
              Completed
            </MDBTabsLink>
          </MDBTabsItem> */}
        {/* <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("Cancelled")}
              active={basicActive === "Cancelled"}
            >
              Cancelled
            </MDBTabsLink>
          </MDBTabsItem> */}
        {/* </MDBTabs> */}

        <MDBTabsContent>
          <MDBTabsPane open={basicActive === "Pending"}>
            <OrderTable
              orders={orders}
              status={basicActive}
              handleUpdateStatus={handleUpdateStatus}
              detailOrder={detailOrder}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "Processing"}>
            <OrderTable
              orders={orders}
              status={basicActive}
              handleUpdateStatus={handleUpdateStatus}
              detailOrder={detailOrder}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "Completed"}>
            <OrderTable
              orders={orders}
              status={basicActive}
              handleUpdateStatus={handleUpdateStatus}
              detailOrder={detailOrder}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "Cancelled"}>
            <OrderTable
              orders={orders}
              status={basicActive}
              handleUpdateStatus={handleUpdateStatus}
              detailOrder={detailOrder}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </MDBTabsPane>
        </MDBTabsContent>

        {showAlert && <Alert text={alertMessage} class={alertClass} />}
      </div>
      {orderModal && (
        <OrderDetailModa
          orderModal={orderModal}
          setCreateOrder={setCreateOrder}
          productId={selectedProductId}
          products={selectedCustomerOrder?.products || []}
        />
      )}
    </div>
  );
}

const OrderTable = ({
  orders,
  status,
  handleUpdateStatus,
  detailOrder,
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  return (
    <div className="table-responsive">
      <table className=" table text-white">
        <thead>
          <tr className="whitespace-nowrap">
            <th>S/N</th>
            <th>CLIENT INFO</th>
            {/* <th>COUNTRY</th> */}
            <th>TRANSACTION ID</th>
            <th>PAYMENT STATUS</th>
            <th>Theme Downloaded </th>
            <th>DETAILS</th>
            {/* <th>PAYMENT STATUS</th> */}
          </tr>
        </thead>
        <tbody>
          {[orders, ...orders].reverse().map((order, index) => (
            <tr key={order._id}>
              <td>
                <span className="text-gray-400">
                  {(currentPage - 1) * 10 + index + 1}
                </span>
              </td>

              <td>
                <div className="d-flex align-items-center">
                  <img
                    src={
                      order?.customer?.image != null
                        ? order?.customer?.image
                        : NoImage
                    }
                    alt=""
                    style={{ width: "45px", height: "45px" }}
                    className="rounded-circle"
                  />
                  <div className="ms-3">
                    <p className="fw-bold text-[12px] mb-1 text-secondary-g">
                      {order?.customer?.firstName} {order?.customer?.lastName}
                    </p>
                    <p className="mb-1 text-[11px] text-gray-100">
                      {order?.customer?.email}
                    </p>

                    <p className="mb-0 text-[11px] text-gray-100">
                      {order?.customer?.phone}
                    </p>
                  </div>
                </div>
              </td>
              {/* <td>
                <p className="fw-normal mb-1 text-gray-100">{order?.country}</p>
                <p className="fw-normal mb-1 text-gray-100">{order?.city}</p>
              </td> */}
              <td>
                <p className="fw-normal mb-1 text-gray-100">
                  {order?.transactionId}
                </p>
              </td>
              <td>
                <p className="fw-normal mb-1 text-gray-100">
                  {order?.paymentStatus}
                </p>
              </td>
              <td>
                <p className="fw-normal mb-1 text-secondary-g">
                  {order?.themeDownloaded ? "Yes" : "No"}
                </p>
              </td>
              <td>
                <button
                  className="bg-secondary-g hover:bg-opacity-80 text-black font-bold py-1.5 px-6 rounded-lg focus:outline-none focus:shadow-outline"
                  onClick={() => detailOrder(order._id)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center gap-2 pb-5 mt-5">
        <Stack spacing={2}>
          <Pagination
            onChange={handlePageChange}
            count={totalPages}
            page={currentPage}
            color="primary"
            size="large"
            shape="circular"
            sx={{
              "& .Mui-selected": {
                color: "#000 !important",
                fontWeight: "700",
              },
            }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default Orders;
