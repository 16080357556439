import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrow from "../../assets/images/Iconfeather-arrow-up-right.svg";
import bgImg from "../../assets/images/Component 13 – 2.png";
import GetInTouchPopup from "./GetInTouchPopup";
import SignUp from "../Auth/SignUp";
import Suggestion from "./suggestion";
import Cart from "../cart/cart";
import Cut from "../../../src/assets/images/arrowback.svg";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { OnBack } from "../../Component/OnBack/OnBack";
import { toast } from "react-toastify";
import Footer from "../Common/footer/Footer";
import Animation from "./Animation";
import Cut2 from "../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";
import Swal from "sweetalert2";

const Details = ({ onClose }) => {
  const _UserId = localStorage.getItem("userId");
  const [allProducts, setAllProducts] = useState({});
  const { state } = useLocation();
  const { product } = state || {};

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [purchasePopupOpen, setPurchasePopupOpen] = useState(false);
  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handlePurchase = () => {
    setPurchasePopupOpen(true);
  };
  const handleClose = () => {
    setPurchasePopupOpen(false);
  };
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const selectedTag = product?.categories?._id;

  const fetchProduct = async () => {
    try {
      const filter = {
        categories: selectedTag,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/products`,
        { filter },
        config
      );
      setAllProducts(response.data?.data);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [selectedTag]);

  let ratings;
  if (Array.isArray(allProducts)) {
    const matchedProduct = allProducts.find(
      (matched) => matched._id === product._id
    );
    ratings = matchedProduct?.ratings;
  } else {
    console.error("allProducts is not an array.");
  }

  const [rating, setRating] = useState(product?.ratings || 0);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
  const handleRatingClick = async (newRating) => {
    setRating(newRating);
    if (newRating > 0) {
      const { value: feedback } = await Swal.fire({
        title: "Feedback",
        html: '<textarea id="swal-textarea" class="swal2-textarea" placeholder="Enter your feedback here.." style="height: 100px; color:"white";"></textarea>',
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "bg-custom-color ",
          cancelButton: "text-black bg-white",
          content: "text-white",
          popup: "bg-black",
          title: "text-white",
        },
        preConfirm: () => {
          return document.getElementById("swal-textarea").value;
        },
      });

      await submitRating(newRating, feedback);
    }
  };

  const countRatings = (ratings) => {
    const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    ratings?.forEach((rating) => {
      counts[rating] += 1;
    });
    return counts;
  };

  const ratingCounts = countRatings(ratings);

  const submitRating = async (newRating, feedback) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = { rating: newRating };
      if (feedback) {
        data.feedback = feedback;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/products/ratings/${product._id}`,
        data,
        config
      );
      toast.info("Rated Sucessfully");
      fetchProduct();
      setIsRatingSubmitted(true);
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.error("Please log in to access this feature.");
    }
  };
  useEffect(() => {}, [product?.ratings]);

  const filteredRatedBy = product?.ratedBy.filter(
    (item) => item.user === _UserId
  );

  const activeUserRatings = filteredRatedBy.map((user) => user.rating);
  return (
    <div className="flex flex-col justify-center">
      <div className="lg:px-20 px-4 my-5 flex justify-between items-start  mb-4">
        <div className=" lg:w-[19rem] w-[6rem]">
          <OnBack />
        </div>

        <span className="text-white">
          <Link to="/">
            {" "}
            <img src={Cut2} alt="" className="w-6" />
          </Link>
        </span>
      </div>

      <div className="w-[80vw]  font-[Macoxs]  mx-auto bg-black text-white z-10">
        <div className="flex  flex-col w-full my-6">
          <div className=" md:flex lg:flex-row flex-col  md:justify-between justify-center items-start md:items-center ">
            <div className="w-auto">
              <div className="flex items-baseline flex-col gap-1.5">
                <p className="text-base md:text-2xl  text-[#9ADD3A] mb-0">
                  Title: {product?.title}
                </p>
                <p className="text-base md:text-2xl  text-[#00F3F4]">
                  Price: ${product?.price}
                </p>
              </div>
            </div>
            <div className="flex md:flex-row gap-4 flex-col">
              <button
                className="uppercase rounded-lg hover:bg-opacity-75 bg-[#00F3F4] text-black"
                onClick={() => handlePurchase()}
              >
                PURCHASE
              </button>
              <button
                className="hover:bg-white hover:text-black uppercase rounded-lg"
                onClick={handleButtonClick}
              >
                customization
              </button>
            </div>
          </div>
          <div className="flex items-center  justify-center w-full h-full flex-col gap-10 my-[3rem] relative  lg:py-10  lg:mb-10">
            {/* 
          <div className="big-img  flex justify-center w-[85vw] lg:w-[50vw] h-[50vh] md:h-[70vh] rounded-2xl lg:p-5 p-0 BG-RED-500">
            {product?.images &&
              product.images.map((image, index) => (
                <div
                  key={index}
                  className="border-[#52d7d7] w-full  h-[35vh] md:h-[70vh] rounded-2xl md:bg-contain bg-cover"
                  style={{
                    display: index === selectedImageIndex ? "block" : "none",
                    backgroundImage: `url(${image})`,

                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              ))}
          </div> */}

            <div className="slider flex justify-center  gap-4   xl:gap-10 lg:gap-3 md:gap-3 items-center">
              {product?.images &&
                product.images.map((image, index) => (
                  <div
                    key={index}
                    className="border-[#52d7d7] rounded-full border-[1px]  cursor-pointer size-12 overflow-hidden"
                    onClick={() => handleImageClick(index)}
                  >
                    <img
                      src={image}
                      className="size-full object-cover"
                      alt="image"
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="w-11/12 md:w-2/3 mx-auto">
            <div className="flex justify-between flex-wrap">
              <div className="flex items-center">
                <FaStar size={15} className="text-yellow-500 -mt-0.5" />
                <p className="my-0 p-0 mx-1 text-base font-[montserrat] font-medium ">
                  Reviews{" "}
                  <span className="text-yellow-500">({ratings?.length})</span>
                </p>
              </div>
              <div className="flex md:flex-row flex-col gap-2 items-center justify-end relative">
                <p className="my-0 p-0 mx-1 text-base font-[montserrat] font-medium text-yellow-500">
                  {activeUserRatings > 0 ? `${activeUserRatings}.0` : "Rate Us"}
                </p>
                <div className="flex">
                  {[...Array(5)].map((_, index) => {
                    return (
                      <FaStar
                        size={14}
                        key={index}
                        className={`w-5 hover:text-opacity-80 ${
                          index < activeUserRatings
                            ? "text-yellow-500"
                            : "text-gray-200"
                        }`}
                        onClick={() => handleRatingClick(index + 1)}
                        style={{ cursor: "pointer" }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className=" h-full  my-6 text-center ">
              {product?.images &&
                product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt="image"
                    className="mx-auto rounded-xl"
                    style={{
                      display: index === selectedImageIndex ? "block" : "none",
                      backgroundImage: `url(${image})`,
                    }}
                  />
                ))}
            </div>
            <div className="my-8">
              <h2 className="lg:text-2xl text-lg text-[#00F3F4] uppercase">
                Product <span className="text-[#9ADD3A]">Description</span>
              </h2>
              <p className="lg:text-lg text-sm  w-full mx-auto  font-[montserrat]  first-letter:capitalize">
                <p
                  dangerouslySetInnerHTML={{ __html: product?.description }}
                ></p>
              </p>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <Suggestion
              selectedTag={product?.categories?._id}
              currentProductId={product?._id}
            />
          </div>
        </div>
        <Animation />
        <Footer />
        <img src={Cut2} alt="" className="w-6" />
        {isPopupOpen && (
          <GetInTouchPopup
            isPopupOpen={isPopupOpen}
            setIsPopupOpen={setIsPopupOpen}
            productId={product?._id}
            product={product || {}}
          />
        )}
        {purchasePopupOpen && (
          <Cart
            purchasePopupOpen={purchasePopupOpen}
            setPurchasePopupOpen={setPurchasePopupOpen}
            product={product}
          />
        )}
      </div>
    </div>
  );
};

export default Details;
