import React from "react";
import { useNavigate } from "react-router-dom";

const ConfirmModel = ({ isOpen, onClose, onConfirm, productToSave }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    onConfirm();
       navigate("/payment");

    
  };

  const handleModalClose = () => {
    onClose(); // Close the modal
  };

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-75"></div>
        <div className="relative bg-black border-primary-b border-[1px] rounded-3xl max-w-md w-full p-6">
          <div className="flex justify-between items-center mb-4">
            <h5 className="text-xl text-primary-b font-bold">
              Confirm Replace <span className="text-secondary-g"> Product</span>
            </h5>
            <div
              onClick={handleModalClose}
              className="text-white hover:text-gray-700 border-primary-b border-[1px] p-2 rounded-full "
            >
              <svg
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div className="text-sm text-gray-200 leading-7 mb-4">
            <p>
              Do you want to replace the existing product in the cart? Product
              to Replace: {productToSave ? productToSave.title : ""}
            </p>
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={handleConfirm}
              className="bg-transparent  border-[1px] border-primary-b text-white px-4 py-2 rounded hover:bg-secondary-g focus:outline-none transition duration-300"
            >
              Yes
            </button>
            <button
              onClick={handleModalClose}
              className="bg-gray-200 border-[1px] border-primary-b text-gray-800 px-4 py-2 rounded  hover:bg-red-500 focus:outline-none"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModel;
