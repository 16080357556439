import React from "react";
import { Modal } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const SuccessModal = ({ ProductData }) => {
  const navigate = useNavigate();
  const totalAmount = localStorage.getItem("totalAmount");
  const productsArray = JSON.parse(localStorage.getItem("productsArray"));

  const handleClose = () => {
    navigate("/");
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "535px",
          backgroundColor: "black",
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          border: "5px solid #9ADD3A",
        }}
      >
        <div
          className="flex flex-col justify-center text-center"
          style={{ alignItems: "center" }}
        >
          <CheckCircleOutline
            style={{ color: "#9ADD3A", marginRight: "10px", fontSize: "200px" }}
          />
          <h2 className="text-white font-bold mb-4">Payment Successful!</h2>
        </div>
        <p className="text-primary-b text-center">
          Your payment <b className="text-secondary-g">${totalAmount}</b> has
          been successfully processed. Thank you for your purchase{" "}
          <b className="text-secondary-g">
            {productsArray
              .map((p) => p?.title)
              .filter((title) => title)
              .join(", ")}
          </b>
          !
        </p>

        <center>
          <button
            onClick={handleClose}
            variant="contained"
            className="px-24 bg-secondary-g text-black border-0 rounded-2xl"
          >
            Close
          </button>
        </center>
      </div>
    </Modal>
  );
};

export default SuccessModal;
