// ProductGrid.js
import React, { useState } from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRipple,
} from "mdb-react-ui-kit";
import NoImage from "../../assets/images/No_Image_Available.jpg";

import ImageModal from "./ImageModal";

export const ProductGrid = ({
  products,
  setCentredModal,
  setSelectedProductId,
  deleteProduct,
}) => {
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleDescription = (index) => {
    setExpandedDescriptionIndex(
      expandedDescriptionIndex === index ? null : index
    );
  };

  const handleOptionClick = (option, productId) => {
    if (option === "Edit") {
      setCentredModal(true);
      setSelectedProductId(productId);
    } else {
      setCentredModal(false);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  return (
    <div className="">
      <div className="row p-2">
        {products.length < 1 && (
          <p style={{ paddingLeft: "1.5rem" }} className="text-white">
            No products found
          </p>
        )}
        {products?.map((product, index) => (
          <div className="col-md-4 col-lg-3 col-sm-12 my-2" key={index}>
            <MDBCard>
              <MDBRipple
                onClick={() => handleImageClick(product.mockupImage || NoImage)}
                rippleColor="light"
                rippleTag="div"
                className="bg-image hover-overlay"
              >
                <div className="w-full h-[200px] object-cover cursor-pointer transition-all duration-700 bg-bottom-left">
                  <img
                    src={
                      product.mockupImage != null
                        ? product.mockupImage
                        : NoImage
                    }
                    alt="Mockup Image"
                    className="w-full h-full object-top object-cover"
                  />
                </div>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </MDBRipple>
              <MDBCardBody className="lg:h-[240px] h-auto">
                <MDBRow className="items-center justify-between">
                  <MDBCardTitle className="w-fit">{product.title}</MDBCardTitle>
                  <MDBCardTitle className="w-fit">
                    <span className="text-secondary-g">$</span>
                    {product.price}
                  </MDBCardTitle>
                </MDBRow>
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    scrollbarColor: "blue lightgray",
                    marginBottom: "5px",
                  }}
                >
                  <MDBCardText className="first-letter:capitalize min-h-16">
                    {expandedDescriptionIndex === index ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.description.slice(0, 20),
                        }}
                      />
                    )}
                    {product.description.length > 100 && (
                      <>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => toggleDescription(index)}
                        >
                          {expandedDescriptionIndex === index
                            ? " See less"
                            : " See more..."}
                        </span>
                      </>
                    )}
                  </MDBCardText>
                </div>
                <div className="flex flex-wrap gap-2">
                  <MDBBtn
                    className="sm:w-fit w-[48%] font-[montserrat]"
                    onClick={() => handleOptionClick("Edit", product._id)}
                  >
                    Update
                  </MDBBtn>
                  <MDBBtn
                    className="sm:w-fit w-[48%] font-[montserrat]"
                    onClick={() => deleteProduct(product._id)}
                  >
                    Delete
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        ))}
      </div>
      <ImageModal
        showModal={showModal}
        setShowModal={setShowModal}
        imageSrc={selectedImage}
      />
    </div>
  );
};
