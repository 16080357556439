import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import {
  MDBBtn,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRipple,
  MDBCarousel,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
import CenteredModal from "../../Component/CenterModal/CenteredModal";
import Alert from "../../Component/Alert/Alert";
import Loader from "../../Component/Loader/Loader";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";
import { Pagination, Stack, PaginationItem } from "@mui/material";
import { CancelIcon, SearchIcon } from "../../svg/utils";
import { ArrowRightIcon } from "../../svg";
import { ProductList } from "../ProductList/ProductList";
import { ProductGrid } from "../ProductGrid/ProductGrid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";

function Drafts() {
  const baseURL = process.env.REACT_APP_URL;
  const [centredModal, setCentredModal] = useState(false);
  const [loader, setloader] = useState(true);
  const [products, setProducts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const limit = 8;
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const fetchProducts = async () => {
    try {
      if (!searchQuery.trim()) {
        setError("Please enter a search query");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/products/`,
        {
          query: {
            title: searchQuery,
          },
        }
      );

      setProducts(response.data.data);
      setTotalPages(response?.data?.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setError("");
    // setTimeout(() => {
    //   fetchProducts();
    // }, 1000);
  };

  const toggleDescription = (index) => {
    setExpandedDescriptionIndex(
      expandedDescriptionIndex === index ? null : index
    );
  };

  const handleOptionClick = (option, productId) => {
    setSelectedProductId(productId);

    if (option === "Edit") {
      setCentredModal(true);
      setSelectedProductId(productId);
    } else if (option === "Publish") {
      publish(productId);
    }
  };

  const publish = async (productId) => {
    const token = localStorage.getItem("adminToken");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/drafts/publish/${productId}`, null, {
        headers,
      })
      .then((response) => {
        setloader(false);
        getDrafts();
        setAlertClass("alert-info");
        setAlertMessage(`Product Publish Successfully`);
        setShowAlert(!showAlert);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteProduct = async (id) => {
    setloader(true);
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(`${process.env.REACT_APP_URL}/drafts/${id}`, config)
      .then((response) => {
        getDrafts();
        setAlertClass("alert-danger");
        setAlertMessage(`${response.data.data.title} ${response.data.message}`);
        setShowAlert(!showAlert);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDrafts = async () => {
    const token = localStorage.getItem("adminToken");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/drafts?page=${currentPage}&limit=${limit}`,
        {},
        { headers }
      )
      .then((response) => {
        setProducts(response.data.data);
        setTotalPages(response?.data?.pagination.totalPages);
        setloader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getDrafts();
  }, [currentPage]);

  const [viewMode, setViewMode] = useState("grid");

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  return (
    <div className="row w-full m-0 ">
      {loader ? <Loader /> : null}
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div
          className="col-lg-10 col-md-12 col-sm-12 min-h-100"
          style={{
            backgroundColor: "#000",
            overflowY: "auto",
          }}
        >
          <MDBRow
            className="no-gutters"
            style={{ marginTop: "50px", padding: 20 }}
          >
            <div className="flex justify-between flex-wrap items-center">
              {" "}
              <h1 className=" text-xl font-bold uppercase text-primary-b">
                Draft
              </h1>{" "}
              <div className="flex relative ml-auto mr-5">
                <span className="cursor-pointer" onClick={toggleSearch}>
                  {!isSearchOpen ? <SearchIcon /> : <CancelIcon />}
                </span>
                {isSearchOpen && (
                  <div
                    className={`absolute md:top-[-8px]  md:right-8 right-[-8.8rem] top-[3.5rem] md:w-auto w-[85vw]   transition-all duration-500 overflow-hidden py-2 focus:outline-none border-2 flex pl-3 gap-0 md:gap-4  border-primary-b caret-primary-b rounded-md`}
                  >
                    <div className="scale-90">
                      <SearchIcon />
                    </div>
                    <input
                      type="search"
                      placeholder={error ? error : "Search"}
                      className={`focus:outline-0 placeholder-primary pl-2 font-semibold  text-[13px] caret-primary text-white bg-black border-0 ${
                        error ? "placeholder-red-500" : ""
                      }`}
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchProducts();
                        }
                      }}
                    />

                    <button
                      className="py-0 px-2 -my-2 ml-auto bg-primary-b border-0 "
                      onClick={fetchProducts}
                    >
                      <ArrowRightIcon />
                    </button>
                  </div>
                )}
              </div>
              <div>
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={handleViewModeChange}
                >
                  <ToggleButton
                    value="list"
                    style={{
                      backgroundColor:
                        viewMode === "list" ? "#00F3F4" : "transparent",
                      color: viewMode === "list" ? "#000" : "transparent",
                    }}
                  >
                    <ViewListIcon
                      style={{
                        color: viewMode === "list" ? "#000" : "#fff",
                      }}
                    />
                  </ToggleButton>
                  <ToggleButton
                    value="grid"
                    style={{
                      backgroundColor:
                        viewMode === "grid" ? "#00F3F4" : "transparent",
                    }}
                  >
                    <ViewModuleIcon
                      style={{
                        color: viewMode === "grid" ? "#000" : "#fff",
                      }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </MDBRow>

          {viewMode === "grid" ? (
            <ProductGrid
              products={products}
              setCentredModal={setCentredModal}
              setSelectedProductId={setSelectedProductId}
              deleteProduct={deleteProduct}
            />
          ) : (
            <ProductList
              products={products}
              setCentredModal={setCentredModal}
              setSelectedProductId={setSelectedProductId}
              deleteProduct={deleteProduct}
            />
          )}
          {/* modal for edit */}
          {centredModal && (
            <CenteredModal
              centredModal={centredModal}
              setCentredModal={setCentredModal}
              productId={selectedProductId}
              onSaveChanges={getDrafts}
              redirection={"drafts"}
            />
          )}

          {/* alert for delete */}
          {showAlert && <Alert text={alertMessage} class={alertClass} />}
        </div>
      )}
      <div className="flex justify-center gap-2 pb-5">
        <Stack spacing={2} justifyContent="center" mt={4}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            shape="circular"
            sx={{
              "& .Mui-selected": {
                color: "#000 !important",
                fontWeight: "700",
              },
            }}
          />
        </Stack>
      </div>
    </div>
  );
}

export default Drafts;
