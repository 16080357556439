import React, { useState, useEffect } from "react";
import Header from "../Common/header/Header";
import Footer from "../Common/footer/Footer";
import { EyeIcon } from "../../svg";
import LikeIcon from "../projects/Like";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NoImage from "../../assets/images/No_Image_Available.jpg";
import ConfirmModel from "../projects/confirmModel";

export const Search = () => {
  const LoginData = localStorage.getItem("userId");
  const location = useLocation();
  const product = location.state.product;
  const searchedQuery = location.pathname
    .split("/search/")[1]
    .replace("_", " ");
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_URL;
  const navigate = useNavigate();

  const [generalInfo, setGeneralInfo] = useState({});
  const [saved, setSaved] = useState([]);
  const handleSaveItem = async (productId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Check if the product is already saved
      if (saved.includes(productId)) {
        // If saved, send a request to remove the product ID
        await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        // Update the saved state by removing the product ID
        setSaved(saved.filter((id) => id !== productId));
        toast.warning("Product unsaved!");
      } else {
        // If not saved, send a request to add the product ID
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        // Update the saved state by adding the product ID
        setSaved([...saved, productId]);

        toast.success("Save Product!");
      }

      // Optionally, you can update the UI to reflect that the product has been saved or unsaved
    } catch (error) {
      console.error("Error saving/unsaving product:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/users/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGeneralInfo(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [baseURL, token]);

  const handleButtonClick = async (product) => {
    try {
      navigate(`/details/${product.title.toLowerCase().replace(/ /g, "_")}`, {
        state: { product },
      });
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_URL}/products/view/${product._id}`,
        null,
        config
      );
    } catch (error) {
      console.error("Error viewing product:", error);
    }
  };

  const [savedLocally, setSavedLocally] = useState([]);

  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    setSavedLocally(savedProducts.map((item) => item._id));
  }, []);

  const saveProductsToLocal = (product) => {
    let savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    const existingProduct = savedProducts.find((p) => p._id === product._id);
    if (existingProduct) {
      toast.info("Product already in cart!");
    } else {
      savedProducts.push(product);
      localStorage.setItem("products", JSON.stringify(savedProducts));
      setSavedLocally([...savedLocally, product._id]);
      toast.success("Item successfully added to your cart!");
      navigate("/payment");
    }
  };
 
  useEffect(() => {
    if (generalInfo?.savedProducts) {
      setSaved(generalInfo.savedProducts.map((item) => item._id));
    }
  }, [generalInfo?.savedProducts]);

  return (
    <>
      <Header />
      <div className=" xl:mx-[5rem] md:mx-[3rem] mx-[1rem] xl:mt-20 mt-14 mb-28">
        {product?.length === 0 ? (
          <div className="text-white flex justify-center text-center w-full">
            <p className="my-5">
              {" "}
              No products found for the selected category.
            </p>
          </div>
        ) : (
          <>
            <div className="text-center mb-6">
              <h1 className=" font-[Macoxs] md:text-3xl text-2xl text-center text-[#00F3F4] uppercase  ">
                {searchedQuery}
              </h1>
              <p className="mb-0 text-white">
                Here you can find a wide range of products matching your search
                query.
              </p>
              <p className="mb-0 text-white">
                Feel free to explore and find what you're looking for.
              </p>
            </div>
            <div className="box w-full grid md:grid-cols-3 xl:grid-cols-4  gap-4">
              {product?.map((array, i) => (
                <div className="pd_hover relative overflow-hidden rounded-3xl cursor-pointer group w-full h-72 lg:h-96 border-4 border-[#c7c7c7]">
                  <img
                    className="object-cover   h-auto w-full"
                    src={
                      array.mockupImage != null ? array.mockupImage : NoImage
                    }
                    alt="design"
                  ></img>

                  <div
                    onClick={() => handleButtonClick(array)}
                    className=" hidden h-0 group-hover:h-full w-full group-hover:flex flex-col justify-end items-center absolute bottom-0 bg-gradient-to-b from-transparent to-[#000000] rounded-b-2xl"
                  >
                    <div className="flex justify-between py-4 items-center w-full px-[20px]">
                      <div className="w-full">
                        <div className="flex flex-col">
                          <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                            {array?.title}
                          </p>
                        </div>
                        <div>
                          <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                            Price: $ {array?.price}
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-center ml-3 items-center gap-1">
                        <EyeIcon size={true} color="#fff" />
                        <p className="text-white mb-0 font-[montserrat] font-bold drop-shadow-sm">
                          {array.views}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icons_aside flex items-center flex-col gap-2 absolute top-2/3 right-4 -translate-y-1/2 -mt-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => saveProductsToLocal(array)}
                    >
                      <button
                        className={`shadow-sm h-10 font-[montserrat] w-10 flex p-0 items-center justify-center font-bold rounded-full hover:border-transparent ${
                          savedLocally.includes(array._id)
                            ? "border-[#FCECFA] bg-[#FCECFA]"
                            : "border-white bg-white"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          id="Cart"
                          width="16"
                          height="16"
                        >
                          <path
                            d="M8.5,19A1.5,1.5,0,1,0,10,20.5,1.5,1.5,0,0,0,8.5,19ZM19,16H7a1,1,0,0,1,0-2h8.49121A3.0132,3.0132,0,0,0,18.376,11.82422L19.96143,6.2749A1.00009,1.00009,0,0,0,19,5H6.73907A3.00666,3.00666,0,0,0,3.92139,3H3A1,1,0,0,0,3,5h.92139a1.00459,1.00459,0,0,1,.96142.7251l.15552.54474.00024.00506L6.6792,12.01709A3.00006,3.00006,0,0,0,7,18H19a1,1,0,0,0,0-2ZM17.67432,7l-1.2212,4.27441A1.00458,1.00458,0,0,1,15.49121,12H8.75439l-.25494-.89221L7.32642,7ZM16.5,19A1.5,1.5,0,1,0,18,20.5,1.5,1.5,0,0,0,16.5,19Z"
                            fill={
                              savedLocally && savedLocally.includes(array._id)
                                ? "#EA64D9"
                                : "#000"
                            }
                            class="color000000 svgShape"
                          ></path>
                        </svg>{" "}
                      </button>
                    </div>
                    <LikeIcon
                      product={array.likedBy}
                      id={array._id}
                      userId={LoginData}
                      fetchProduct={fetchUserData}
                    />
                    <button
                      onClick={() => handleSaveItem(array._id)}
                      className={`shadow-sm font-[montserrat] h-10 w-10 flex p-0 items-center justify-center font-bold rounded-full hover:border-transparent   ${
                        saved && saved.includes(array._id)
                          ? "border-[#FCECFA] bg-[#FCECFA]"
                          : " border-white bg-white"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill={
                          saved && saved.includes(array._id)
                            ? "#EA64D9"
                            : "#FFFFFF"
                        }
                        role="img"
                        class="icon "
                      >
                        <path
                          d="M3.33337 5.2C3.33337 4.0799 3.33337 3.51984 3.55136 3.09202C3.74311 2.71569 4.04907 2.40973 4.42539 2.21799C4.85322 2 5.41327 2 6.53337 2H9.46671C10.5868 2 11.1469 2 11.5747 2.21799C11.951 2.40973 12.257 2.71569 12.4487 3.09202C12.6667 3.51984 12.6667 4.0799 12.6667 5.2V14L8.00004 11.3333L3.33337 14V5.2Z"
                          stroke={
                            saved && saved.includes(array._id)
                              ? "#EA64D9"
                              : "#000"
                          }
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* <ConfirmModel
              isOpen={showModal}
              onClose={handleModalClose}
              onConfirm={handleModalConfirm}
              productToSave={productToSave}
            /> */}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
