import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCarousel,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
import axios from "axios";
import Loader from "../../Component/Loader/Loader";
import ApiUrl from "../../Constant/ApiUrl";
import ImageModal from "../Users/Component/ImageModal";

export default function OrderDetailModa({
  orderModal,
  setCreateOrder,
  productId,
  onSaveChanges,
  products,
}) {
  const toggleOpen = () => setCreateOrder(!orderModal);
  const [loader, setLoader] = useState(true);
  const [productDetail, setProductDetail] = useState({});
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const toggleDescription = (index) => {
    setExpandedDescriptionIndex(
      expandedDescriptionIndex === index ? null : index
    );
  };

  const getOrderDetail = async () => {
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_URL}/orders/${productId}`, config)
      .then((response) => {
        const product = response.data.order.products.productId;
        setProductDetail(product);
        setTitle(product?.title);
        setPrice(product?.price);
        setDescription(product?.description);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  function stripHtmlTags(htmlString) {
    return htmlString?.replace(/<[^>]*>/g, "");
  }

  return (
    <>
      <MDBModal tabIndex="-1" open={orderModal} setOpen={setCreateOrder}>
        <MDBModalDialog centered>
          {loader ? (
            <Loader />
          ) : (
            <MDBModalContent className="bg-tw-primary">
              <MDBModalHeader className="bg-tw-primary px-0">
                <h3 className="mt-2 text-xl font-bold uppercase text-primary-b">
                  Order Detail
                </h3>
                <div className="bg-secondary-g rounded-full p-0.5">
                  <MDBBtn
                    className="btn-close grayscale brightness-200 m-0 z-50"
                    color="none"
                    onClick={toggleOpen}
                  ></MDBBtn>
                </div>
              </MDBModalHeader>

              <MDBModalBody className="bg-tw-primary px-0">
                <div className="relative">
                  <MDBCarousel showControls showIndicators>
                    {productDetail?.images?.map((image, i) => (
                      <MDBCarouselItem
                        itemId={i + 1}
                        key={i}
                        className="bg-gray-300"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top left",
                            backgroundSize: "cover",
                          }}
                          alt={`Product ${i + 1}`}
                          className="w-full h-52 object-contain cursor-pointer transition-all duration-700 bg-bottom-left"
                        />
                      </MDBCarouselItem>
                    ))}
                  </MDBCarousel>
                </div>

                {products.length > 0 && (
                  <div className="w-full h-auto ">
                    <div className=" overflow-y-auto lg:max-h-[650px] small-scroller grid grid-cols-1 md:grid-cols-2 gap-4">
                      {products?.map((item, index) => (
                        <div
                          key={index}
                          className="lg:my-0 sm:my-4 border-[#9ADD3A] border-[1px] rounded-xl overflow-hidden"
                        >
                          <div className="flex flex-col">
                            <div className="flex-wrap flex-nowrap-lg w-full  flex  justify-between rounded-lg text-white bg-black gap-4 md:gap-10 items-start">
                              <div className="lg:h-52 w-full h-36 rounded-lg overflow-hidden relative">
                                <div
                                  onClick={() =>
                                    handleOpen(
                                      item?.productId?.images[0] || "No Image"
                                    )
                                  }
                                  style={{
                                    backgroundImage: `url(${item?.productId?.images[0]})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "top left",
                                    backgroundSize: "cover",
                                  }}
                                  className="w-full h-full object-cover cursor-pointer transition-all duration-700 bg-bottom-left"
                                />
                              </div>

                              <div className="w-full p-2.5">
                                <p className="flex flex-col sm:text-[15px] text-[12px] text-primary-b font-medium">
                                  {item?.productId?.title
                                    ? item?.productId?.title
                                    : "No title"}
                                </p>

                                <p className="flex flex-col sm:text-[15px] text-sm font-medium text-gray-400">
                                  ${item.price}
                                </p>
                                <div>
                                  {item?.productId?.description && (
                                    <div className="max-h-32 overflow-y-auto">
                                      {expandedDescriptionIndex === index ? (
                                        <span
                                          className="text-sm"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item?.productId?.description,
                                          }}
                                        />
                                      ) : (
                                        <span
                                          className="text-sm"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item?.productId?.description.slice(
                                                0,
                                                20
                                              ),
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  {item?.productId?.description.length > 20 && (
                                    <>
                                      <span
                                        style={{
                                          color: "#00F3F4",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => toggleDescription(index)}
                                      >
                                        {expandedDescriptionIndex === index
                                          ? " See less"
                                          : " See more..."}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </MDBModalBody>

              <ImageModal
                showModal={openModal}
                setShowModal={setOpenModal}
                imageSrc={selectedImage}
              />
              <MDBModalFooter className="bg-black">
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  <span className="text-black">Close</span>
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          )}
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
