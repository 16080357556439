import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TrashIcon } from "../../svg";
import { toast } from "react-toastify";
import { OnBack } from "../../Component/OnBack/OnBack";
import { useAcceptJs } from "react-acceptjs";
import Cart from "../../assets/images/cart.svg";
import Footer from "../Common/footer/Footer";
import ImageModal from "./ImageModel";
import SuccessModal from "../SuccessModal/SuccessModal";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import moment from "moment";

// Test Creads
// const authData = {
//   AUTHORIZE_LOGIN: "6wj4NEe4Gj",
//   AUTHORIZE__TRANASACTION: "2e5m4GRbHc38J963",
//   AUTHORIZE_baseURL: "https://apitest.authorize.net/xml/v1/request.api",
// };

// Live Creads
const authData = {
  AUTHORIZE_LOGIN: "5zUz7kxgK8u",
  AUTHORIZE__TRANASACTION: "7w62rL7Ny4rUwR7g",
  AUTHORIZE_baseURL: "https://api.authorize.net/xml/v1/request.api",
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Payment = () => {
  const location = useLocation();
  const [emailuser, setEmailuser] = useState("");
  const [isChecked, setIsChecked] = useState(false); // Add state for checkbox
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [tranasactionStatus, settranasactionStatus] = useState(
    "setteldsuccessfully"
  );

  const {
    customerEmail,
    slotDate,
    productId,
    services: initialServices = [],
    amount = 0,
    selectedDate,
    selectedTime,
  } = location.state || {};

  const formattedDate = new Date(slotDate).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const [services, setServices] = useState(initialServices);
  const { error } = useAcceptJs({ authData });
  const [cardData, setCardData] = useState({
    cardNumber: "",
    expiry: "",
    cardCode: "",
    firstName: "",
    lastName: "",
    // company: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    // fax: "",
  });
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("pixelUser_Details"));
    if (storedData) {
      setEmailuser(storedData?.email);
      setCardData({
        firstName: storedData?.firstName,
        lastName: storedData?.lastName,
        phone: storedData?.phone,
        city: storedData?.city,
        country: storedData?.country,
      });
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "cardNumber") {
      handleCardNumberChange(event);
    } else if (name === "cardCode") {
      handleCardCodeChange(event);
    } else if (name === "expiry") {
      handleExpiryChange(event);
    } else {
      setCardData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleCardCodeChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value.slice(0, 3);
    }
    setCardData((prevState) => ({
      ...prevState,
      cardCode: value,
    }));
  };

  const handleCardNumberChange = (event) => {
    let value = event.target.value.replace(/\s+/g, "");
    if (value > 16) {
      value = value.slice(0, 16);
    }
    value = value.replace(/\D/g, "");
    const formattedValue = value.replace(/(.{4})/g, "$1 ").trim();
    setCardData((prevState) => ({
      ...prevState,
      cardNumber: formattedValue,
    }));
  };

  const handleExpiryChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value.slice(0, 4);
    }
    let month = value.slice(0, 2);
    let year = value.slice(2, 4);
    if (month.length === 1 && month > 1) {
      month = `0${month}`;
    } else if (month.length === 2) {
      if (parseInt(month, 10) < 1) {
        month = "01";
      } else if (parseInt(month, 10) > 12) {
        month = "12";
      }
    }
    if (month && year) {
      value = `${month}/${year}`;
    } else {
      value = month + (year ? `/${year}` : "");
    }
    setCardData((prevState) => ({
      ...prevState,
      expiry: value,
    }));
  };

  const handleEmailChange = (event) => {
    setEmailuser(event.target.value);
  };

  const [product, setProduct] = useState(() => {
    const savedProducts = JSON.parse(localStorage.getItem("products"));
    return savedProducts ? savedProducts : [];
  });

  useEffect(() => {
    if (!product || (product.length == 0 && services?.length == 0)) {
      setRunAgain(true);
    } else {
      setRunAgain(false);
    }
  }, [product]);

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [paybtn, setPaybtn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedProductTitle, setSelectedProductTitle] = useState(null);

  const openLightbox = (index) => {
    setSelectedImage(product[index].images[0]);
    setSelectedProductTitle(product[index].title);
    // setSelectedImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setSelectedImageIndex(null);
    setLightboxIsOpen(false);
  };

  const totalProductAmount = product.reduce(
    (total, product) => total + product.price,
    0
  );

  const totalAmount = totalProductAmount + amount;

  const [loadingImages, setLoadingImages] = useState(true);

  const [order, setOrder] = useState([]);
  const [runAgain, setRunAgain] = useState(false);

  const fetchOrder = async (tid, transactionTime) => {
    try {
      const token = localStorage.getItem("token");
      const productsArray = product.map((p) => ({
        productId: p?._id,
        title: p?.title,
        price: p?.price,
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/orders/add`,
        {
          products: productsArray,
          quantity: product.length,
          email: emailuser,
          totalAmount: totalAmount,
          transactionId: tid,
          purchaseDate: transactionTime,
          paymentStatus: "settledSuccessfully",
          firstName: cardData.firstName,
          lastName: cardData.lastName,
          address: cardData.address,
          city: cardData.city,
          state: cardData.state,
          postalCode: cardData.zip,
          country: cardData.country,
          phone: parseInt(cardData.phone),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("totalAmount", totalAmount || amount);
      localStorage.setItem("productsArray", JSON.stringify(product) || "");
      setPaybtn(false);
      setOrder(response.data);
      localStorage.removeItem("products");
      setShowSuccessModal(true);
      setProduct([]);
    } catch (err) {
      setPaybtn(false);
      toast.error("There might be an issue");
    }
  };

  const fetchCustomizationOrder = async (e) => {
    const token = localStorage.getItem("token");

    const productsArray = product.map((p) => ({
      productId: p?._id,
      title: p?.title,
      price: p?.price,
    }));

    try {
      const combinedDateTime = moment(
        `${selectedDate} ${selectedTime}`
      ).toISOString();

      await axios.post(
        `${process.env.REACT_APP_URL}/customizations/add`,
        {
          firstName: cardData.firstName,
          lastName: cardData.lastName,
          phone: parseInt(cardData.phone),
          customerEmail: customerEmail,
          slotDate: combinedDateTime,
          productId: productId,
          services: services,
          amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("totalAmount", totalAmount || amount);
      localStorage.setItem("productsArray", JSON.stringify(product) || "");
      setPaybtn(false);
      localStorage.removeItem("products");
      setShowSuccessModal(true);
      setProduct([]);
    } catch (err) {
      console.error("Error submitting customization request:", err);
      toast.error("Error");
    }
  };

  useEffect(() => {
    const imagePromises = product.map((item) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = item.images[0];
        img.onload = () => resolve();
      });
    });

    Promise.all(imagePromises)
      .then(() => setLoadingImages(false))
      .catch((error) => console.error("Error loading images:", error));
  }, [product]);

  const handleDeleteProduct = (productId) => {
    const updatedProducts = product.filter((item) => item._id !== productId);
    localStorage.setItem("products", JSON.stringify(updatedProducts));
    setProduct(updatedProducts);
    // setRunAgain(true);
  };

  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [transitionId, setTransitionId] = useState(null);

  useEffect(() => {
    if (success) {
      setShow(false);
    }
    ShowPaypal();
  }, [success, runAgain]);

  const ShowPaypal = () => {
    if (product.length == 0 && services?.length == 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaybtn(true);

    const merchantAuthenticationType = {
      name: authData.AUTHORIZE_LOGIN,
      transactionKey: authData.AUTHORIZE__TRANASACTION,
    };
    const cleanedExpiry = cardData.expiry.replace("/", ""); // Remove the slash from expiry date
    const cleanedCardNumber = cardData.cardNumber.replace(/\s+/g, "");
    const paymentData = {
      cardNumber: cleanedCardNumber,
      expirationDate: cleanedExpiry,
      cardCode: cardData.cardCode,
    };

    const transactionRequest = {
      transactionType: "authCaptureTransaction",
      amount: totalAmount || amount,
      payment: {
        creditCard: paymentData,
      },
      billTo: {
        firstName: cardData.firstName,
        lastName: cardData.lastName,
        // company: cardData.company,
        address: cardData.address,
        city: cardData.city,
        state: cardData.state,
        zip: cardData.zip,
        country: cardData.country,
        phoneNumber: cardData.phone,
        // faxNumber: cardData.fax,
        email: emailuser,
      },
    };

    try {
      const response = await axios.post(authData.AUTHORIZE_baseURL, {
        createTransactionRequest: {
          merchantAuthentication: merchantAuthenticationType,
          transactionRequest,
        },
      });

      const result = response.data;

      setTransitionId(response.data.transactionResponse.transId);

      if (result.messages.resultCode === "Ok") {
        setTransitionId(response.data.transactionResponse.transId);
        getTransactionDetails(response.data.transactionResponse.transId);
      } else {
        setPaybtn(false);
        toast.error(result.errors.errors[0].errorText);
      }
    } catch (error) {
      setPaybtn(false);
      toast.error("Error Processing Payment. Please verify your details");
      if (error.message) {
        console.error("Error message:", error.message);
      }
      if (error.response) {
        console.error("Error response:", error.response);
      }
    }
  };

  async function getTransactionDetails(transactionId) {
    try {
      const response = await axios.post(authData.AUTHORIZE_baseURL, {
        getTransactionDetailsRequest: {
          merchantAuthentication: {
            name: authData.AUTHORIZE_LOGIN,
            transactionKey: authData.AUTHORIZE__TRANASACTION,
          },
          transId: transactionId,
        },
      });

      const { data } = response;

      const transactionStatus = data.transaction.transactionStatus;

      settranasactionStatus(transactionStatus);
      handleTransaction(data, services, transactionStatus);
      return data;
    } catch (error) {
      console.error("Error fetching transaction details:", error);
      throw error;
    }
  }

  function handleTransaction(data, services, transactionStatus) {
    if (services.length > 0 && product.length > 0) {
      fetchCustomizationOrder();
      fetchOrderOrShowMessage(data, transactionStatus);
    } else if (services.length > 0) {
      fetchCustomizationOrder();
    } else {
      fetchOrderOrShowMessage(data, transactionStatus);
    }
  }

  function fetchOrderOrShowMessage(data, transactionStatus) {
    switch (transactionStatus) {
      case "authorizedPendingCapture":
      case "capturedPendingSettlement":
      case "settledSuccessfully":
        fetchOrder(data.transaction.transId, data.transaction.submitTimeUTC);
        toast.info(
          `${data.transaction.transactionStatus}: ${data.transaction.responseReasonDescription}`
        );
        break;
      case "refundPendingSettlement":
      case "refundSettledSuccessfully":
      case "voided":
      case "declined":
      case "expired":
      case "failedReview":
        toast.error(
          `${data.transaction.transactionStatus}: ${data.transaction.responseReasonDescription}`
        );
        break;
      default:
        toast.error(
          `${data.transaction.transactionStatus}: ${data.transaction.responseReasonDescription}`
        );
    }
  }

  return (
    <div className="min-h-screen flex flex-col justify-between px-1 md:px-4 sm:pt-4 lg:pt-10  mx-auto w-full ">
      <div className="px-4">
        <div className="w-auto ml-4">
          <OnBack />
        </div>
        {runAgain == false ? (
          <div className="max-w-6xl mx-auto w-full flex lg:flex-row flex-col-reverse justify-center lg:gap-12 sm:gap-0  mb-6 sm:mb-3 md:pt-0 pt-[2rem] mt-6 ">
            <div className="lg:mt-5">
              {show ? (
                <div className="text-white rounded-3xl mt-4 ">
                  <div className="w-full mx-auto mt-10 lg:py-8 py-2 bg-black shadow-lg rounded-lg">
                    <p className="uppercase lg:text-[30px] text-[24px] py-4 text-[#00F3F4] font-[Macoxs]">
                      <span className="text-secondary-g ">billing </span>{" "}
                      details
                    </p>
                    <form className="space-y-7" onSubmit={handleSubmit}>
                      <div className="flex gap-3">
                        <div className="w-[49%]">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="FirstName"
                          >
                            First Name:
                          </label>

                          <input
                            id="firstName"
                            type="text"
                            name="firstName"
                            placeholder="First name"
                            value={cardData.firstName}
                            onChange={handleChange}
                            variant="outlined"
                            className="w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                            required
                          />
                        </div>
                        <div className="w-[49%]">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="LastName"
                          >
                            Last Name:
                          </label>

                          <input
                            id="lastName"
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                            value={cardData.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                          htmlFor="email"
                        >
                          Email Address:
                        </label>
                        <input
                          placeholder="Enter your email address"
                          id="email"
                          type="email"
                          value={emailuser}
                          onChange={handleEmailChange}
                          variant="outlined"
                          className="mt-2 w-full bg-white px-3 lg:text-[14px] text-[12px] text-black h-10 rounded-xl"
                        />
                      </div>
                      <div>
                        <label
                          className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                          htmlFor="Phone"
                        >
                          Phone:
                        </label>

                        <input
                          id="phone"
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          value={cardData.phone}
                          onChange={handleChange}
                          variant="outlined"
                          className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          required
                        />
                      </div>
                      <div className="flex gap-3">
                        <div className="w-[49%]">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="Address"
                          >
                            Address:
                          </label>

                          <input
                            id="address"
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={cardData.address}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                        <div className="w-[49%]">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="Country"
                          >
                            Country:
                          </label>

                          <input
                            id="country"
                            type="text"
                            name="country"
                            placeholder="Country"
                            value={cardData.country}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col gap-3">
                        <div className="lg:w-[33%] w-full">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="City"
                          >
                            City:
                          </label>

                          <input
                            id="city"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={cardData.city}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                        <div className="lg:w-[33%] w-full">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="StateProvince"
                          >
                            State:
                          </label>

                          <input
                            id="state"
                            type="text"
                            name="state"
                            placeholder="State/Province"
                            value={cardData.state}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                        <div className="lg:w-[33%] w-full">
                          <label
                            className="whitespace-nowrap block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="Zip"
                          >
                            Zip Code:
                          </label>

                          <input
                            id="zip"
                            type="text"
                            name="zip"
                            placeholder="Zip/Postal Code"
                            value={cardData.zip}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          />
                        </div>
                      </div>

                      {/* <div>
                        <label
                          className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                          htmlFor="Fax "
                        >
                          Fax:
                        </label>

                        <input
                          id="fax"
                          type="text"
                          name="fax"
                          placeholder="Fax"
                          value={cardData.fax}
                          onChange={handleChange}
                          variant="outlined"
                          className=" w-full text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                          required
                        />
                      </div> */}

                      <div>
                        <label
                          className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                          htmlFor="cardNumber"
                        >
                          Credit Card:
                        </label>
                        <p className="text-gray-400 text-[12px] mt-1">
                          The digit on the front of your card{" "}
                        </p>
                        <input
                          placeholder="0000 0000 0000 0000"
                          id="cardNumber"
                          type="text"
                          name="cardNumber"
                          value={cardData.cardNumber}
                          onChange={handleChange}
                          minLength={16}
                          variant="outlined"
                          className=" w-full bg-white px-3 h-10 lg:text-[14px] text-[12px] text-black rounded-xl"
                          required
                        />
                      </div>
                      <div className="flex md:flex-row flex-col  gap-3">
                        <div className="lg:w-[49%] w-full">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="expiry"
                          >
                            expiry date:
                          </label>
                          <p className="text-gray-400 text-[12px] mt-1">
                            The date your credit card expire{" "}
                          </p>
                          <input
                            minLength={5}
                            placeholder="MM/YY"
                            id="expiry"
                            name="expiry"
                            type="text"
                            maxLength="5"
                            value={cardData.expiry}
                            onChange={handleExpiryChange}
                            required
                            className=" w-full bg-white px-3 h-10 lg:text-[14px] text-[12px] text-black rounded-xl "
                          />
                        </div>
                        <div className="lg:w-[49%] w-full">
                          <label
                            className="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
                            htmlFor="cardCode"
                          >
                            Security Code:
                          </label>
                          <p className="text-gray-400 text-[12px] mt-2">CVV</p>
                          <input
                            minLength={3}
                            id="cardCode"
                            type="password"
                            name="cardCode"
                            placeholder="****"
                            value={cardData.cardCode}
                            onChange={handleChange}
                            variant="outlined"
                            className=" w-full text-black lg:text-[17px] text-[12px] bg-white h-10 px-3 rounded-xl pt-1"
                            required
                          />
                        </div>
                      </div>

                      <div className="flex space-x-2  ">
                        <input
                          type="checkbox"
                          className="inline accent-secondary-g"
                          checked={isChecked} // Add checked property
                          onChange={(e) => setIsChecked(e.target.checked)} // Add onChange handler
                        />
                        <p className="text-white mt-3">
                          I agree to{" "}
                          <span>
                            <Link
                              to="/terms-and-conditions"
                              className="text-secondary-g underline"
                            >
                              Term of Services
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span className="text-secondary-g underline">
                            <Link
                              to="/privacy-policy"
                              className="text-secondary-g underline"
                            >
                              Privacy Policy
                            </Link>
                          </span>
                        </p>
                      </div>

                      <button
                        type="submit"
                        disabled={!isChecked}
                        className={`w-full font-Macoxs lg:text-sm text-[10px] uppercase tracking-2px rounded-xl lg:my-5 sm:my-3 font-bold ${
                          !isChecked
                            ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                            : "bg-secondary-g text-black hover:bg-green-500 hover:text-white"
                        }`}
                      >
                        Place Order
                      </button>
                      {error && <p className="text-red-500">{error.message}</p>}
                    </form>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="space-y-4 lg:mt-48 lg:max-w-md  w-full ">
              {services?.length > 0 ? (
                <>
                  <div className="lg:max-h-[650px]  font-[Macoxs] w-full h-auto  p-4 border-[#9ADD3A] border-[1px] rounded-xl">
                    <p className="flex flex-col sm:text-[14px] text-[10px] text-gray-400 font-medium border-b-[1px] pb-2 border-gray-300">
                      Email{" "}
                      <span className="text-[#9ADD3A]">{customerEmail}</span>
                    </p>

                    <p className="flex flex-col sm:text-[14px] text-[10px] text-gray-400 font-medium border-b-[1px] pb-2 border-gray-300">
                      Services:
                      <ul className="list-disc pl-5 text-[#9ADD3A]">
                        {services?.map((service, index) => (
                          <li key={index}>{service}</li>
                        ))}
                      </ul>
                      <span className="block text-[#9ADD3A]">
                        Amount: ${amount}
                      </span>
                    </p>

                    <p className="flex flex-col sm:text-[14px] text-[10px] text-gray-400 font-medium border-b-[1px] pb-2 border-gray-300">
                      Services{" "}
                      <span className="text-[#9ADD3A]">{formattedDate}</span>
                    </p>
                  </div>
                </>
              ) : null}
              {product.length > 0 && (
                <div className=" w-full h-auto border-[#9ADD3A] border-[1px] rounded-xl ">
                  <p className="flex gap-2 border-b border-[#9ADD3A] items-center font-[Macoxs] text-sm uppercase tracking-[2px] text-[#9ADD3A] md:text-xl mb-0 py-3 font-bold px-4 mt-2">
                    <img className="lg:w-[2rem] w-10" src={Cart} alt="Cart" />
                    <span>Cart Summary</span>
                  </p>
                  <div className="divide-y divide-gray-600 overflow-y-auto lg:max-h-[650px] small-scroller">
                    {product?.map((item, index) => (
                      <div key={index} className="lg:my-0 sm:my-4">
                        <div className="flex flex-col ">
                          <div className="whitespace-nowrap w-full px-4 flex my-4 justify-between rounded-lg text-white bg-black gap-4 md:gap-10 items-center ">
                            <div className="lg:w-20 lg:h-20 w-14 h-14 rounded-lg overflow-hidden relative">
                              {loadingImages && (
                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                                  <span className="loader"></span>
                                </div>
                              )}
                              <div
                                style={{
                                  backgroundImage: `url(${item?.images[0]})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "top left",
                                  backgroundSize: "cover",
                                }}
                                onClick={() => openLightbox(index)}
                                alt=""
                                className="w-full h-full object-cover cursor-pointer transition-all duration-700 bg-bottom-left"
                              />
                            </div>

                            <p className="flex flex-col sm:text-[15px] text-[12px] text-gray-400 font-medium">
                              <span className="sm:text-[15px] text-[13px] text-primary-b">
                                Title
                              </span>
                              {item.title}
                            </p>

                            <p className="flex flex-col sm:text-[15px] text-sm font-medium text-gray-400">
                              <span className="sm:text-[15px] text-[13px] text-primary-b">
                                Price
                              </span>
                              ${item.price}
                            </p>
                            <button
                              className="rounded-full hover:scale-110 transition-all p-0 md:w-16 md:h-10 w- 8 h-8 bg-white flex items-center justify-center"
                              onClick={() => handleDeleteProduct(item._id)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="border-[#9ADD3A] border-t-[1px] flex items-center justify-end">
                    <p className="text-white text-base md:text-[14px] mb-0 py-3 font-bold px-3">
                      Total :{" "}
                      <span className="text-[#9ADD3A]">${totalAmount}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center mt-5">
            <ShoppingCartOutlinedIcon
              style={{
                color: "#9ADD3A",
                marginRight: "10px",
                fontSize: "230px",
              }}
            />

            <p className="uppercase lg:text-[30px] text-[24px] py-4 text-[#00F3F4] font-[Macoxs] flex items-center">
              <span className="text-secondary-g mr-2">Your cart is </span> empty
            </p>
          </div>
        )}
        <ImageModal
          isOpen={lightboxIsOpen}
          onClose={closeLightbox}
          imageUrl={selectedImage}
          title={selectedProductTitle}
        />
      </div>
      {showSuccessModal && (
        <SuccessModal
          onClose={() => setShowSuccessModal(false)}
          ProductData={totalAmount}
        />
      )}
      <Footer />
    </div>
  );
};

export default Payment;
