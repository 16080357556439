import React, { useState } from "react";
import Logo from "../../assets/images/Group 344.svg";
import Cut from "../../../src/assets/images/back-arrow-navigation-svgrepo-com.svg";
import { Link } from "react-router-dom";
import UpdatePassword from "./updatePassword";
import Billing from "./Billing";
import General from "./General";
import Activity from "./Activity";
import History from "./History";
import { OnBack } from "../../Component/OnBack/OnBack";
const userTabs = [
  {
    id: "history",
    tab: "History",
    component: <History />,
  },
  {
    id: "general",
    tab: "General",
    component: <General />,
  },
  {
    id: "activity",
    tab: "Activity",
    component: <Activity />,
  },
  // {
  //   id: "billing",
  //   tab: "Billing",
  //   component: <Billing />,
  // },
  {
    id: "updatepassword",
    tab: "Update Password",
    component: <UpdatePassword />,
  },
];
const Profile = () => {
  const firstName = localStorage.getItem("firstName");
  const [activeTab, setActiveTab] = useState("history");

  return (
    <div className="w-full lg:px-10 px-3  text-white py-5">
      <OnBack />
      
      {/* <h2 className="md:text-[20px] text-[15px] text-white">
       Profile ">>" {firstName} ">>" {userTabs.find((tab) => tab.id === activeTab)?.tab}{" "}
      </h2> */}

      <div className="flex  gap-10 w-full flex-col lg:flex-row">
        <div className="flex  md:text-[20px] text-[16px] flex-col justify-start items-center w-full lg:my-20 my-10 lg:w-1/3">
          {/* <p className="text-[15px] text-wrap text-left w-full relative lg:top-[-4rem]">
            Update your information and manage your account
          </p> */}
          {userTabs.map((item, index) => (
            <Link
              href={`#${item.id}`}
              key={index}
              onClick={() => {
                setActiveTab(item.id);
              }}
              style={{
                border: `2px solid ${
                  activeTab === item.id ? "#49DBEE" : "gray"
                }`,
                backgroundColor: activeTab === item.id ? "#83edf9" : "white",
                color: activeTab === item.id ? "black" : "initial",
                padding: "10px 7px",
                width: "100%",
                borderRadius: "18px",
                margin: "10px 0",
                textAlign: "center",
              }}
            >
              {item?.tab}
            </Link>
          ))}
        </div>
        <div className=" md:w-full md:flex md:justify-start md:items-center md:my-20 md:px-5">
          {userTabs.find((tab) => tab.id === activeTab)?.component}
        </div>
      </div>
    </div>
  );
};

export default Profile;
