import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/images/dipixelsLogo.png";
import OTPInput from "react-otp-input";
import axios from "axios";
import { CancelIcon } from "../../svg/utils";
import { toast } from "react-toastify";

const Verification = ({ onClose, email }) => {
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (timer > 0 && resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer, resendDisabled]);
  const handleResend = async () => {
    setResendDisabled(true);
    setTimer(60);
    try {
      await axios.post(`${process.env.REACT_APP_URL}/auth/resend`, { email });
      toast.success("OTP successfully sent");
    } catch (err) {
      console.error("Resend request failed:", err);
      toast.error("Error sending email. Please check your credentials");
    }
  };

  const [verificationData, setVerificationData] = useState({
    otp: "",
    email: email,
  });
  const handleChange = (otp) => {
    setVerificationData({ ...verificationData, otp });
  };
  const baseURL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/confirmed`,
        verificationData
      );
      onClose();
      navigate("/login");
      toast.success(response?.data?.message);
    } catch (err) {
      console.error("Registration failed:", err);
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <div className="w-full flex justify-center items-center">
      <div className="bg-black backdrop-blur-lg w-full min-h-screen fixed top-0 left-0 flex flex-col justify-center items-center opacity-75"></div>
      <div className="bg-black border-2 border-primary-b lg:max-w-[400px] max-w-[350px]  w-full text-black space-y-2   fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 py-10 px-7 rounded-3xl opacity-100 flex flex-col justify-center items-center ">
        <span
          className="absolute text-black scale-150 top-6 cursor-pointer right-6 "
          onClick={onClose}
        >
          <CancelIcon />
        </span>

        <div className="flex  flex-col gap-3 justify-center items-center mt-6">
          <img src={Logo} alt="" className="w-20"></img>
          <h2 className="text-4xl text-white">Verification</h2>
        </div>

        <p className=" text-base mt-0   md:text-lg py-3 text-white">
          Your Code was sent to you via email
        </p>

        <OTPInput
          value={verificationData.otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum
          renderSeparator={<span style={{ width: "8px" }}></span>}
          renderInput={(props) => <input {...props} className="otp-input" />}
          shouldAutoFocus={true}
          className="w-[45px] h-[45px]"
          inputStyle={{
            border: "2px solid #fff",
            borderRadius: "8px",
            width: "45px",
            height: "45px",
            fontSize: "16px",
            color: "#fff",
            fontWeight: "800",
            caretColor: "#00F3F4",
            backgroundColor: "#000",
            outlineColor: "#73A62B",
            fontFamily: "Gilroy",
          }}
          focusStyle={{
            border: "none",
            outline: "none",
          }}
        />
        <button
          className="font-[montserrat] rounded-full  hover:bg-opacity-75 font-bold border-primary-b p-2 bg-primary-b text-black  text-transform: uppercase  verify_btn mt-10 w-full"
          onClick={handleSubmit}
        >
          Verify
        </button>
        <div className="text-base text-white w-full flex justify-between items-center mt-3">
          Didnt receive code?{" "}
          <button
            onClick={handleResend}
            disabled={resendDisabled}
            className={` ${
              resendDisabled
                ? "bg-gray-400 text-white cursor-not-allowed "
                : "bg-secondary-g text-black hover:bg-opacity-75 "
            }  font-bold font-[montserrat] text-base rounded-full py-2 px-4 `}
          >
            {resendDisabled ? `Resend in ${timer} sec` : "Resend"}
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default Verification;
