import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { MDBBtn, MDBRow } from "mdb-react-ui-kit";
import Alert from "../../Component/Alert/Alert";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";
import Loader from "../../Component/Loader/Loader";
import Modal from "./Component/Modal";

function Categories() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [centredModal, setCentredModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [modalType, setModalType] = useState("");

  const handleOptionClick = (option, _id) => {
    if (option === "Edit") {
      setCentredModal(true);
      setSelectedCategoryId(_id);
      setModalType("update");
    } else if (option === "Add Category") {
      setCentredModal(true);
      setModalType("add");
    } else {
      setCentredModal(false);
    }
  };

  const getCategories = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/categories/`,
        null,
        config
      );
      setCategories(response.data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const disableCategory = async (id) => {
    setLoader(true);
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_URL}/categories/disable/${id}`,
        null,
        config
      )
      .then((response) => {
        setLoader(false);
        getCategories();
        setAlertClass("alert-info");
        setAlertMessage(`${response.data.message}`);
        setShowAlert(!showAlert);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="row w-full m-0 ">
      {loader ? <Loader /> : null}
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      <div
        className="col-lg-10 col-md-12 col-sm-12 min-h-100"
        style={{ backgroundColor: "#000"}}
      >
        <MDBRow
          className="no-gutters"
          style={{ marginTop: "50px", padding: 20 }}
        >
          <div className="flex justify-between items-center flex-wrap mb-3">
            <h1 className=" text-xl font-bold uppercase text-primary-b">
              Categories
            </h1>{" "}
            <MDBBtn onClick={() => handleOptionClick("Add Category")}>
              Add Category
            </MDBBtn>
          </div>
        </MDBRow>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-secondary-g text-transform: uppercase">
                <th scope="col">S/N</th>
                <th scope="col">Name</th>
                <th scope="col">Number of Products</th>
                <th scope="col">Update</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={category._id}>
                  <td>
                    <span className="text-gray-400">{index + 1}</span>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <p className="fw-bold mb-1 text-gray-100">
                        {category?.tag}
                      </p>
                    </div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1 text-gray-100">
                      {category?.count}
                    </p>
                  </td>
                  <td>
                    <h5>
                      <MDBBtn
                        onClick={() => handleOptionClick("Edit", category?._id)}
                      >
                        Update
                      </MDBBtn>
                    </h5>
                  </td>
                  <td>
                    <MDBBtn
                      className="btn btn-danger"
                      onClick={() => disableCategory(category?._id)}
                    >
                      {category?.isActive == true ? "Enable" : "Disable"}
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* alert for delete */}
        {showAlert && <Alert text={alertMessage} class={alertClass} />}
      </div>

      {centredModal && (
        <Modal
          centredModal={centredModal}
          setCentredModal={setCentredModal}
          productId={selectedCategoryId}
          onSaveChanges={getCategories}
          type={modalType} // Pass the modal type to the Modal component
        />
      )}
    </div>
  );
}

export default Categories;
