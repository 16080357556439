import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import "./Dashboard.css";
import Loader from "../../Component/Loader/Loader";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";
import DashboardStats from "../../Component/DashboardStats/DashboardStats";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const baseURL = process.env.REACT_APP_URL;
  const [info, setInfo] = useState();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({
    series: [
      {
        type: "rangeArea",
        name: "Team A Range",
        data: [
          { x: "Jan", y: [3100, 3400] },
          { x: "Feb", y: [4200, 5200] },
          { x: "Mar", y: [3900, 4900] },
          { x: "Apr", y: [3400, 3900] },
          { x: "May", y: [5100, 5900] },
          { x: "Jun", y: [5400, 6700] },
          { x: "Jul", y: [4300, 4600] },
          { x: "Aug", y: [2100, 2900] },
        ],
      },
      {
        type: "line",
        name: "Team A Median",
        data: [
          { x: "Jan", y: 3300 },
          { x: "Feb", y: 4900 },
          { x: "Mar", y: 4300 },
          { x: "Apr", y: 3700 },
          { x: "May", y: 5500 },
          { x: "Jun", y: 5900 },
          { x: "Jul", y: 4500 },
          { x: "Aug", y: 2400 },
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeArea",
        animations: { speed: 500 },
      },
      colors: ["#00F3F4", "#00F3F4"],
      dataLabels: { enabled: false },
      fill: { opacity: [0.74, 1] },
      stroke: { curve: "straight", width: [0, 2] },
      legend: { show: true, customLegendItems: ["Team A"], inverseOrder: true },
      title: { text: "Income" },
      markers: { hover: { sizeOffset: 5 } },
      zoom: { enabled: true, type: "x", autoScaleYaxis: true },
      toolbar: { autoSelected: "zoom" },
    },
  });

  const getData = async (status) => {
    const token = localStorage.getItem("adminToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_URL}/products/count`, config)
      .then((response) => {
        setInfo(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row w-full m-0 ">
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      <div
        className="col-lg-10 col-md-12 col-sm-12 min-h-100"
        style={{ backgroundColor: "#000" }}
      >
        <div>
          <MDBRow
            className="no-gutters"
            style={{ marginTop: "50px", padding: 20 }}
          >
            <h1 className=" text-xl font-bold uppercase text-primary-b">
              Dashboard
            </h1>
          </MDBRow>
          <MDBRow
            className="no-gutters"
            style={{ marginTop: "0px", padding: 20, paddingTop: "0px" }}
          >
            <MDBCol size="md">
              <div className="overall_box bg-transparent border-2 border-secondary-g min-h-44">
                <MDBIcon
                  icon="chart-line"
                  className="text-white"
                  size="2x"
                  style={{ marginBottom: "10px" }}
                />

                <p className="text-primary-b font-bold">Total Earnings</p>
                <h2 className="text-white">
                  <span className="text-secondary-g">$</span>
                  {info?.totalEarnings}
                </h2>
              </div>
            </MDBCol>
            <MDBCol size="md">
              <div
                onClick={() =>
                  navigate("/admin/orders", {
                    state: { activeTab: "Completed" },
                  })
                }
                className="overall_box cursor-pointer bg-transparent border-2 border-secondary-g min-h-44"
              >
                <MDBIcon
                  icon="shopping-cart"
                  className="text-white"
                  size="2x"
                  style={{ marginBottom: "10px" }}
                />

                <p className="text-primary-b font-bold">Completed Orders</p>
                <h2 className="text-white"> {info?.totalOrders}</h2>
              </div>
            </MDBCol>
            <MDBCol size="md">
              <div
                onClick={() => navigate("/admin/listing")}
                className="overall_box cursor-pointer bg-transparent border-2 border-secondary-g min-h-44"
              >
                <MDBIcon
                  icon="box"
                  className="text-white"
                  size="2x"
                  style={{ marginBottom: "10px" }}
                />

                <p className="text-primary-b font-bold">Total Products</p>
                <h2 className="text-white">{info?.totalProducts}</h2>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <div>
          <DashboardStats />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
