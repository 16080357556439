import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/Icon ionic-ios-close-circle-outline.svg";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

const Popup = ({ onClose }) => {
  const baseURL = process.env.REACT_APP_URL;

  const [initialOptions, setInitialOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("adminToken");
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
    formData.append("Categories", selectedOptionId);

    const config = {
      method: "post",
      url: `${baseURL}/products/excel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      onClose();
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const getCategories = async () => {
    try {
      const token = localStorage.getItem("adminToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/categories/`,
        null,
        config
      );
      setInitialOptions(
        response.data.data.map((option) => ({
          value: option.tag,
          label: option.tag,
          id: option._id,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="flex fixed justify-center top-0 left-0 items-center">
      <div className="bg-black opacity-30 w-screen h-screen "></div>
      <div className="absolute top-[20%]  bg-black border-primary-b text-white border-[1px] lg:w-[550px] w-[90%] rounded-2xl p-4 mx-auto">
        <div className="flex justify-between">
          <p className="text-[23px] font-bold">Upload Excel file</p>
          <img src={CloseIcon} alt="" className="w-7" onClick={onClose} />
        </div>
        <div className="mb-3 w-full mt-2  select_cat ">
          <Select
            value={selectedOption}
            onChange={(selectedOption) => {
              setSelectedOption(selectedOption);
              if (selectedOption) {
                const selectedTag = initialOptions.find(
                  (option) => option.label === selectedOption.label
                );
                setSelectedOptionId(selectedTag.id);
              }
            }}
            className="text-white"
            options={initialOptions}
          />
        </div>
        <div className="mb-2 mt-2 border-2 border-dashed rounded-lg min-h-28 p-5 flex items-center justify-center flex-col  border-primary-b">
          <p>Upload excel file</p>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={false}
            onChange={handleFileChange}
          />
        </div>
        <div className="flex justify-center mt-3 gap-1">
          <button
            className="hover:bg-secondary-g rounded-2xl border-primary-b"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className=" hover:bg-secondary-g rounded-2xl border-primary-b"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
