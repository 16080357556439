import React from "react";
import Logo from "../../src/assets/images/dipixels-logo-full.png";
import { OnBack } from "../Component/OnBack/OnBack";
import Footer from "../components/Common/footer/Footer";
const Terms = () => {
  return (
    <>
      <div className="py-10 lg:px-[5rem] px-4 max-w-7xl mx-auto">
        <div className="w-full absolute left-[40px]">
          <OnBack />
        </div>
        <div className="w-full flex flex-col justify-center items-center ">
          {/* <img src={Logo} alt="" className="lg:w-[17rem] w-[14rem]" /> */}
          <div className="mt-28 text-white text-center">
            <h1 className="text-primary-b text-xl lg:text-3xl  font-[Monument]">
              Terms and Conditions{" "}
            </h1>
            <p className="mt-4 text-[15px] leading-7">
              Welcome to Pixel Pulse! These terms and conditions outline the
              rules and regulations for the use of our website and services. By
              accessing or using any site, service, application, or product
              owned or operated by Pixel Pulse, or by joining as a customer or
              client of any service, you consent to be legally bound by these
              terms and conditions of use, including those terms and conditions
              incorporated by reference. We may revise these terms at any time
              by modifying this posting. You should visit this webpage
              occasionally to check these terms and conditions, since they are
              binding on you.
            </p>
          </div>
        </div>
        <div className="w-full mt-10 flex flex-col text-white justify-start items-start">
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            1. Introduction:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; These terms and conditions govern your use of our website
            and services provided by Pixel Pulse.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            2. Intellectual Property Rights:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; Unless otherwise stated, Pixel Pulse owns the intellectual
            property rights for all material on this website. All intellectual
            property rights are reserved. You may view and/or print pages from
            <a href="https://dipixels.com/" target="_blank">
              <span className="text-secondary-g hover:text-opacity-80 mx-1">
                www.dipixels.com
              </span>
            </a>
            for your personal use subject to restrictions set in these terms and
            conditions.
          </p>
          <h4 className="text-base font-[Macoxs] mb-2 mt-4  text-secondary-g tracking-[2px]">
            3. License to Use:{" "}
          </h4>
          <p className="text-[14px] leading-7 ml-4">
            &#9675; You must not:
            <ul className="list-type-square">
              <li>Republish material from our website.</li>
              <li>Sell, rent, or sub-license material from our website.</li>
              <li>Reproduce, duplicate, or copy material from our website.</li>
              <li>
                Redistribute content from Pixel Pulse (unless the content is
                specifically made for redistribution).
              </li>
            </ul>
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            4. Acceptable Use:{" "}
          </h4>
          <p className="text-[14px] leading-4">
            &#9675; You must not use our website in any way that causes, or may
            cause, damage to the website or impairment of the availability or
            accessibility of the website.
          </p>
          <p className="text-[14px] leading-4">
            &#9675; You must not use our website in any way which is unlawful,
            illegal, fraudulent, or harmful.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            5. User Content:{" "}
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; In these terms and conditions, "your user content" means
            material (including without limitation text, images, audio material,
            video material, and audio-visual material) that you submit to our
            website, for whatever purpose.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; You grant to Pixel Pulse a worldwide, irrevocable,
            non-exclusive, royalty-free license to use, reproduce, adapt,
            publish, translate, and distribute your user content in any existing
            or future media. You also grant to Pixel Pulse the right to
            sub-license these rights and the right to bring an action for
            infringement of these rights.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            6. Pre-screening and Removal{" "}
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; You acknowledge and agree that we shall have the right (but
            not the obligation) in our sole discretion, to pre-screen, refuse,
            or remove any project by the client if its content violates these
            terms or is otherwise objectionable or unlawful, obscene, offensive,
            defamatory, induces religious, racial or ethnic hatred, or infringes
            the rights of others. Any action that comes to our attention which
            appears in our sole judgment to violate the law will be brought to
            the attention of the appropriate authorities.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            7. Access and Disclosure{" "}
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; You acknowledge, consent, and agree that we may access,
            preserve, and disclose your account information and content if
            required to do so by law or in a good faith belief that such access
            preservation or disclosure is reasonably necessary to: (a) comply
            with legal processes; (b) enforce these terms; (c) respond to claims
            that any content violates the rights of third parties; (d) respond
            to your requests for customer service; or (e) protect our rights,
            property, or safety of the rights, property, or safety of our users
            or the public.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            8. No Warranties:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; This website is provided “as is” without any representations
            or warranties, express or implied. Pixel Pulse makes no
            representations or warranties in relation to this website or the
            information and materials provided on this website.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            9. Limitations of Liability:
          </h4>
          <p className="text-[14px] leading-7 ml-4">
            &#9675; Pixel Pulse will not be liable to you in relation to the
            contents of, or use of, or otherwise in connection with, this
            website:
            <ul className="list-type-square">
              <li>for any indirect, special, or consequential loss; or</li>
              <li>
                for any business losses, loss of revenue, income, profits, or
                anticipated savings, loss of contracts or business
                relationships, loss of reputation or goodwill, or loss or
                corruption of information or data.
              </li>
            </ul>
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            10. Indemnification:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; You hereby indemnify Pixel Pulse and undertake to keep Pixel
            Pulse indemnified against any losses, damages, costs, liabilities,
            and expenses (including without limitation legal expenses and any
            amounts paid by Pixel Pulse to a third party in settlement of a
            claim or dispute on the advice of our legal advisers) incurred or
            suffered by Pixel Pulse arising out of any breach by you of any
            provision of these terms and conditions.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            11. Security of Your Information:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; Pixel Pulse ensures the security of the client’s private and
            credit information at all times. The information remains stored in
            our server which is only to be viewed by the concerned staff. This
            means that your information is in safe hands.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            12. Breaches of These Terms and Conditions:
          </h4>
          <p className="text-[14px] leading-7">
            Without prejudice to Pixel Pulse's other rights under these terms
            and conditions, if you breach these terms and conditions in any way,
            Pixel Pulse may take such action as Pixel Pulse deems appropriate to
            deal with the breach.
          </p>
          <p
            style={{ borderTop: "1px solid #00F3F4" }}
            className="text-[14px] w-full leading-7 py-3.5 mt-2.5"
          >
            By utilizing any site, service, application, or product owned or
            operated by Pixel Pulse, you agree to these terms and conditions.
          </p>
        </div>
        <div className="mt-5 text-white">
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            Copyright and Trademark Disclaimer{" "}
          </h4>
          <p className="text-[14px] leading-7 mb-1">
            Any service provided by Pixel Pulse and intended for a specific
            customer is exclusively for that customer’s use. Pixel Pulse does not
            retain any rights to what is created for a customer.
          </p>
          <p className="text-[14px] leading-7 mb-1">
            Pixel Pulse does not file trademark applications for services intended
            for its customers. The customer retains the right to register any
            service made for them by Pixel Pulse as a trademark.
          </p>
          <p className="text-[14px] leading-7 mb-1">
            Should payment for any service not be made according to Pixel Pulse'
            installment policies, all rights to the service provided, including
            any potential trademark rights, will revert to Pixel Pulse.
          </p>
          <p className="text-[14px] leading-7 mb-1">
            Pixel Pulse ensures that its services are original creations and are
            not copied from or inspired by existing services or trademarks.
            However, Pixel Pulse does not guarantee that a service will not
            resemble concepts unknown or non-existent at the time of its
            creation.
          </p>
          <p className="text-[14px] leading-7 mb-1">
            In the event that a service provided by Pixel Pulse unintentionally
            resembles another party's service, Pixel Pulse will offer a
            complimentary redraw session to create a new service.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
