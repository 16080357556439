import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="bg-black min-h-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4 font-[Macoxs] text-white">
          404 - Page Not Found
        </h1>
        <p className="text-lg text-white mb-8 font-[montserrat]">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="font-[montserrat] bg-primary-b hover:bg-opacity-80 text-black font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
        >
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
