import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import "./Login.css";
import Alert from "../../Alert/Alert";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import ApiUrl from "../../../Constant/ApiUrl";
import Spinner from "../../Spinner/Spinner";
import { EyeIcon, EyeOffIcon } from "../../../svg";
import { toast } from "react-toastify";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const baseURL = process.env.REACT_APP_URL;

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = () => {
    setLoader(true);
    const data = {
      email: email,
      password: password,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${process.env.REACT_APP_URL}/auth/login`, data, config)
      .then((response) => {
        setLoader(false);
        const role = response.data.user.role;
        const token = response.data.token;
        localStorage.setItem("adminToken", token);
        if (role === "admin") {
          toast.success(response.data.message);
          navigate("/admin/dashboard");
          setPassword("");
          setEmail("");
        } else {
          toast.error("Invalid role. Please log in as a admin.");
          
        }
      })
      .catch((error) => {
        setLoader(false);
                 toast.error("Error: No response received from the server.");

      });
  };

  return (
    <MDBContainer
      className="overlay_image"
      fluid
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* {localStorage.getItem("token") ? "T" : "F"} */}
      <MDBContainer fluid className="container">
        <MDBRow>
          <MDBCol
            md="6"
            className="text-center text-md-start d-flex flex-column justify-content-center"
          >
            <h1 className="my-4 display-3 fw-bold ls-tight px-3 text-secondary-g text-transform: uppercase">
              The best offer <br />
              <span className="text-primary-b">for your business</span>
            </h1>
            <p className="px-3 text-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
              itaque accusantium odio, soluta, corrupti aliquam quibusdam
              tempora at cupiditate quis eum maiores libero veritatis? Dicta
              facilis sint aliquid ipsum atque?
            </p>
          </MDBCol>
          <MDBCol className="ml-auto" md="5">
            <MDBCard className="my-5">
              <MDBCardBody className="p-3 p-md-5 bg-[#171717] border-2 rounded-lg overflow-hidden border-secondary-g">
                <img
                  className="max-w-64 h-auto mx-auto mb-5"
                  src={require("../../../assets/images/dipixels-logo-full.png")}
                />
                {/* <h2 className="text-4xl mb-5 text-primary-b font-bold text-center">
                  Sign Up
                </h2> */}
                <MDBInput
                  className="text-white"
                  wrapperClass="mb-4"
                  label="Email"
                  placeholder="Email"
                  id="form1"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSignIn();
                    }
                  }}
                />
                <div className="relative">
                  <div className="flex items-center absolute z-50 right-5 top-3.5 cursor-pointer h-4">
                    {showPassword ? (
                      <div onClick={togglePasswordVisibility}>
                        <EyeIcon size={"20px"} />
                      </div>
                    ) : (
                      <div onClick={togglePasswordVisibility}>
                        <EyeOffIcon />
                      </div>
                    )}
                  </div>
                  <MDBInput
                    className="text-white text-transfrom: lowercase"
                    wrapperClass="mb-4"
                    label="Password"
                    id="form2"
                    type={showPassword ? "text" : "password"}
                    size="lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSignIn();
                      }
                    }}
                  />
                </div>
                <MDBBtn
                  className="w-100 mb-4 shadow-none bg-primary-b font-bold text-black hover:brightness-90 transition-all "
                  size="lg"
                  onClick={handleSignIn}
                >
                  <div className="flex items-center gap-2  justify-center">
                    <span className="translate-y-0.5">
                      {!loader ? "Submit" : "Submitting"}
                    </span>
                    {loader && <Spinner />}
                  </div>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {showAlert && <Alert text={alertMessage} class={alertClass} />}
      </MDBContainer>
    </MDBContainer>
  );
}

export default SignIn;
