import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";
import Loader from "../../../Component/Loader/Loader";
import closeIcon from "../../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";

import { toast } from "react-toastify";

export default function Modal({
  centredModal,
  setCentredModal,
  productId,
  onSaveChanges,
  type,
}) {
  const toggleOpen = () => setCentredModal(!centredModal);
  const [categoryName, setCategoryName] = useState();
  const [categoryDetail, setCategoryDetail] = useState();
  const [loader, setloader] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imagesError, setImagesError] = useState("");
  const [images, setImages] = useState([]);
  const [imagesCheck, setImagesCheck] = useState(true);
  const handleInputChange = (event) => {
    setCategoryName(event.target.value);
  };
  const getCategoryDetail = async () => {
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_URL}/categories/${productId}`, config)
      .then((response) => {
        setCategoryDetail(response.data.category);
        setloader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImageUpload = async (files) => {
    setIsImageLoading(true);

    if (files.length === 0) return;

    if (images.length + files.length > 1) {
      toast.error("Maximum 1 images can be uploaded");
      setIsImageLoading(false);

      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }

    try {
      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/images/upload-many`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setImagesError("");
      setIsImageLoading(false);
      toast.success(response?.data?.message);
      setImages([...images, ...response.data.url]);
    } catch (error) {
      toast.error(
        "Unable to upload image. Server rejected the file. Please ensure the file format is supported and try again later"
      );
      setIsImageLoading(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleImageUpload(files);
  };
  const handleSave = () => {
    const token = localStorage.getItem("adminToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const categoryImage = images.join(","); // Change delimiter if necessary

    const data = {
      tag: categoryName,
      categoryImage: categoryImage,
    };

    if (type === "update") {
      axios
        .put(`${process.env.REACT_APP_URL}/categories/${productId}`, data, {
          headers,
        })
        .then((response) => {
          toggleOpen();
          onSaveChanges();
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "add") {
      axios
        .post(`${process.env.REACT_APP_URL}/categories/add`, data, { headers })
        .then((response) => {
          toggleOpen();
          onSaveChanges();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (type === "update") {
      getCategoryDetail();
    }
  }, []);

  const handleUpload = (e) => {
    const files = e.target.files;
    handleImageUpload(files);
  };

  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeUpdateImage = (indexToRemove) => {
    setImagesCheck(false);
  };

  return (
    <>
      <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
        <MDBModalDialog centered>
          {loader ? (
            <Loader />
          ) : (
            <MDBModalContent className="bg-black">
              <MDBModalHeader>
                <MDBModalTitle className="text-white">Categories</MDBModalTitle>
                <div className="bg-secondary-g rounded-full p-0.5 ">
                  <MDBBtn
                    className="btn-close grayscale brightness-200 m-0 z-50"
                    color="none"
                    onClick={toggleOpen}
                  ></MDBBtn>
                </div>
              </MDBModalHeader>

              <MDBModalBody>
                {type === "add" ? (
                  <div
                    className={`mb-2 border-2 border-dashed rounded-lg min-h-96 p-5 flex items-center justify-center flex-col ${
                      isDragging ? "border-primary-b" : "border-secondary-g"
                    }`}
                    onDragOver={(e) => {
                      e.preventDefault();
                      setIsDragging(true);
                    }}
                    onDragLeave={() => setIsDragging(false)}
                    onDrop={handleDrop} // Make sure handleDrop is correctly bound here
                  >
                    <label className="flex cursor-pointer text-transform: uppercase font-bold items-center justify-center flex-col gap-4 text-2xl text-secondary-g  text-center ">
                      {isDragging ? (
                        <span className="text-primary-b ">Drop here</span>
                      ) : (
                        <span>Drag & drop or click to upload images</span>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={handleUpload}
                      />
                    </label>
                    {imagesError && (
                      <p className="text-red-500 mt-1">{imagesError}</p>
                    )}

                    <div className="w-full  overflow-hidden">
                      {images?.map((image, index) => (
                        <div className="relative mt-3">
                          <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-full h-48 object-cover rounded-md shadow-md"
                          />
                          <img
                            onClick={() => removeImage(index)}
                            src={closeIcon}
                            alt={`closeIcon`}
                            width={24}
                            className="absolute top-4 right-4 cursor-pointer object-contain rounded-full shadow-sm"
                          />
                        </div>
                      ))}
                      {isImageLoading && (
                        <div className="w-20 h-20 rounded-md shadow-md animate-pulse bg-slate-700"></div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`mb-2 border-2 border-dashed rounded-lg min-h-96 p-5 flex items-center justify-center flex-col ${
                      isDragging ? "border-primary-b" : "border-secondary-g"
                    }`}
                    onDragOver={(e) => {
                      e.preventDefault();
                      setIsDragging(true);
                    }}
                    onDragLeave={() => setIsDragging(false)}
                    onDrop={handleDrop} // Make sure handleDrop is correctly bound here
                  >
                    {/* Image upload section */}
                    <label className="flex cursor-pointer text-transform: uppercase font-bold items-center justify-center flex-col gap-4 text-2xl text-secondary-g  text-center ">
                      {isDragging ? (
                        <span className="text-primary-b ">Drop here</span>
                      ) : (
                        <span>Drag & drop or click to upload images</span>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={handleUpload}
                      />
                    </label>
                    {imagesError && (
                      <p className="text-red-500 mt-1">{imagesError}</p>
                    )}
                    {/* Display existing category image */}
                    <div className="w-full overflow-hidden">
                      {categoryDetail &&
                        categoryDetail.categoryImage &&
                        imagesCheck && (
                          <div className="relative mt-3">
                            <img
                              src={
                                imagesCheck ? categoryDetail.categoryImage : ""
                              }
                              alt={`Category Image`}
                              className="w-full h-48 object-cover rounded-md shadow-md"
                            />
                            <img
                              onClick={() => removeUpdateImage()}
                              src={closeIcon}
                              alt={`closeIcon`}
                              width={24}
                              className="absolute top-4 right-4 cursor-pointer object-contain rounded-full shadow-sm"
                            />
                          </div>
                        )}
                      {/* Display newly uploaded image */}
                      {images?.map((image, index) => (
                        <div className="relative mt-3" key={index}>
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-full h-48 object-cover rounded-md shadow-md"
                          />
                          <img
                            onClick={() => removeImage(index)}
                            src={closeIcon}
                            alt={`closeIcon`}
                            width={24}
                            className="absolute top-4 right-4 cursor-pointer object-contain rounded-full shadow-sm"
                          />
                        </div>
                      ))}
                      {isImageLoading && (
                        <div className="w-20 h-20 rounded-md shadow-md animate-pulse bg-slate-700"></div>
                      )}
                    </div>
                  </div>
                )}

                <MDBInput
                  wrapperClass="my-4"
                  label="Category Name"
                  className="text-white bg-black h-12"
                  value={
                    categoryName !== undefined
                      ? categoryName
                      : (categoryDetail && categoryDetail.tag) || ""
                  }
                  disabled
                  onChange={handleInputChange}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn onClick={() => handleSave()}>Save </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          )}
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
