import React, { useState, useRef, useEffect } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const Accordion = ({ question, answer, isActive, onToggle }) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isActive]);

  return (
    <>
      {" "}

      <div className="faq-item mb-4">
        <div
          className={`w-full font-semibold text-left px-4 py-3 cursor-pointer flex justify-between items-center border-primary-b border-2 ${
            isActive
              ? "bg-secondary-g border-secondary-g text-[#000]"
              : "bg-tw-primary border-primary-b text-[#fff]"
          }`}
          onClick={onToggle}
        >
          {question}
          {isActive ? (
            <ExpandLess className="text-black" />
          ) : (
            <ExpandMore className="text-text" />
          )}
        </div>
        <div
          ref={contentRef}
          style={{ maxHeight: `${height}px` }}
          className={`faq-answer overflow-hidden transition-max-height duration-300 ease-in-out bg-black text-[#fff] ${
            isActive ? "border-secondary-g border-2" : " "
          }`}
        >
          <div className="p-4">{answer}</div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
