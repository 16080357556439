import React, { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { MDBBtn, MDBRow } from "mdb-react-ui-kit";
import Alert from "../../Component/Alert/Alert";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";
import Loader from "../../Component/Loader/Loader";
import Modal from "./Component/Modal";

import { Pagination, Stack, PaginationItem } from "@mui/material";
import NoImage from "../../assets/images/No_Image_Available.jpg";

function Users() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [centredModal, setCentredModal] = useState(false);
  const [orderModal, setCreateOrder] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [modalType, setModalType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const limit = 10;
  const handleOptionClick = (option, _id) => {
    if (option === "Edit") {
      setCentredModal(true);
      setSelectedCategoryId(_id);
      setModalType("update");
    } else if (option === "Add Category") {
      setCentredModal(true);
      setModalType("add");
    } else if (option === "create") {
      setCreateOrder(true);
    }
  };

  const getUsers = async () => {
    const token = localStorage.getItem("adminToken");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/users?page=${currentPage}&limit=${limit}`,
        {},
        { headers }
      )
      .then((response) => {
        setUsers(response.data.data);
        setTotalPages(response?.data?.pagination.totalPages);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getUsers();
  }, [currentPage]);

  return (
    <div className="row w-full m-0 ">
      {loader ? <Loader /> : null}
      <div className="col-lg-2 col-md-3 col-sm-12">
        <Sidebar />
      </div>
      <div
        className="col-lg-10 col-md-12 col-sm-12 min-h-100"
        style={{ backgroundColor: "#000"}}
      >
        <MDBRow
          className="no-gutters"
          style={{ marginTop: "50px", padding: 20 }}
        >
          <div className="flex justify-between items-center flex-wrap mb-3">
            <h1 className=" text-xl font-bold uppercase text-primary-b">
              Users
            </h1>
          </div>
        </MDBRow>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-secondary-g text-transform: uppercase">
                <th scope="col">S/N</th>
                <th scope="col">Info</th>
                <th scope="col">Completed Orders</th>
                <th scope="col">See Detail</th>
                {/* <th scope="col">Create Order</th> */}
              </tr>
            </thead>
            <tbody>
              {users.map((users, index) => (
                <tr key={users._id}>
                  <td>
                    <span className="text-gray-400">{index + 1}</span>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={users.image != null ? users.image : NoImage}
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                        className="rounded-circle object-cover"
                      />
                      <div className="ms-3">
                        <p className="fw-bold mb-1 text-secondary-g capitalize">
                          {users?.firstName}
                        </p>
                        <p className=" mb-0 text-gray-100">{users?.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1  text-gray-100">
                      {users?.myOrders.length}
                    </p>
                  </td>
                  <td>
                    <h5>
                      <MDBBtn
                        onClick={() => handleOptionClick("Edit", users?._id)}
                        className="btn btn-info"
                      >
                        See Detail
                      </MDBBtn>
                    </h5>
                  </td>
                  {/* <td>
                    <MDBBtn
                      className="btn btn-success"
                      onClick={() => handleOptionClick("create", users?._id)}
                    >
                      Create
                    </MDBBtn>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center gap-2  pb-5">
          <Stack spacing={2} justifyContent="center" mt={4}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
              shape="circular"
              sx={{
                "& .Mui-selected": {
                  color: "#000 !important",
                  fontWeight: "700",
                },
              }}
            />
          </Stack>
        </div>
        {/* alert for delete */}
        {showAlert && <Alert text={alertMessage} class={alertClass} />}
      </div>

      {centredModal && (
        <Modal
          centredModal={centredModal}
          setCentredModal={setCentredModal}
          productId={selectedCategoryId}
          onSaveChanges={getUsers}
          type={modalType} // Pass the modal type to the Modal component
        />
      )}
      {/* {orderModal && (
        <CreateOrderModal
          orderModal={orderModal}
          setCreateOrder={setCreateOrder}
          productId={selectedProductId}
        />
      )} */}
    </div>
  );
}

export default Users;
