import React from "react";
import "./Loader.css";
function Loader() {
  return (
    // <div style={{display:"flex",justifyContent:'center',alignItems:'center',height:'100vh',backgroundColor:'transparent'}}>
    // <img src={require('../../assets/loader_gif.gif')}/>
    // </div>
    <div className="bg-[#000] absolute z-50 bg-opacity-70 h-screen">
      <span class="loader fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></span>
    </div>
  );
}

export default Loader;
