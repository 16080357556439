import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Loader from "../../Component/Loader/Loader";
import axios from "axios";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";

export const Customization = () => {
  const baseURL = process.env.REACT_APP_URL;
  const [loader, setLoader] = useState(false);
  const [customizations, setCustomizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const modalRef = useRef(null);
  const limit = 10;

  const getCustomization = async () => {
    setLoader(true);
    try {
      const token = localStorage.getItem("adminToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${baseURL}/customizations?page=${currentPage}&limit=${limit}`,
        null,
        config
      );
      setCustomizations(response.data.data);
      setTotalPages(response?.data?.pagination.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleViewClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    getCustomization();
  }, [currentPage]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && selectedCustomer && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={modalRef}
            className="bg-black rounded-lg p-4 lg:w-1/3 w-full border-secondary-g border-[1px]"
          >
            <div className="flex justify-between items-center border-b pb-4">
              <h2 className="text-[21px] font-semibold text-primary-b">
                Customization Details
              </h2>
              <button
                onClick={handleCloseModal}
                className="text-secondary-g size-8 flex items-center justify-center p-0 text-lg hover:text-opacity-80 rounded-full "
              >
                x
              </button>
            </div>
            <div className="mt-4 text-gray-300">
              <p className="border-b pb-3 text-secondary-g font-semibold">
                Customer Name:{" "}
                <span className="text-gray-400">
                  {selectedCustomer?.firstName} {selectedCustomer?.lastName}
                </span>
              </p>
              <p className="border-b pb-3 text-secondary-g font-semibold">
                Customer Email:{" "}
                <span className="text-gray-400">
                  {selectedCustomer.customerEmail}
                </span>
              </p>
              {/* <p className="border-b pb-3 text-primary-b">
                Design Brief:{" "}
                <span className="text-secondary-g">
                  {selectedCustomer.designBrief}
                </span>
              </p>
              <p className="border-b pb-3 text-primary-b">
                Description:{" "}
                <span className="text-secondary-g">
                  {selectedCustomer.description}
                </span>
              </p> */}
              <p className="border-b pb-3 text-secondary-g font-semibold">
                Services:{" "}
                <span className="text-gray-400">
                  {selectedCustomer.services?.join(", ")}
                </span>
              </p>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-transparent border-secondary-g  text-secondary-g rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row w-full m-0">
        {loader && <Loader />}
        <div className="col-lg-2 col-md-3 col-sm-12">
          <Sidebar />
        </div>
        <div
          className="col-lg-10 col-md-12 col-sm-12 min-h-100"
          style={{ backgroundColor: "#000", zIndex: 1 }}
        >
          <div
            className="no-gutters"
            style={{ marginTop: "50px", padding: 20 }}
          >
            <div className="flex justify-between items-center flex-wrap mb-3">
              <h1 className="text-xl font-bold uppercase text-primary-b">
                Customization
              </h1>
            </div>
          </div>

          <div className="table-responsive">
            {customizations?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr className="text-secondary-g text-transform: uppercase">
                    <th scope="col">S/N</th>
                    <th scope="col">Client Info</th>
                    <th scope="col">Meeting Scheduled</th>
                    <th scope="col">Services</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customizations.map((item, index) => (
                    <tr key={item._id}>
                      <td>
                        <span className="text-gray-400">{index + 1}</span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ms-3">
                            <p class="fw-bold text-[12px] mb-1 text-secondary-g">
                              {item?.firstName} {item?.lastName}
                            </p>
                            <p class="mb-1 text-sm text-gray-100">
                              {item?.customerEmail}
                            </p>
                            <p class="mb-0 text-sm text-gray-100">
                              {item?.phone}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className=" mb-1 text-white">
                          {moment(item?.slotDate).format(
                            "DD MMMM YYYY HH:mm:ss"
                          )}
                        </p>
                      </td>
                      <td>
                        <p className=" mb-1 text-gray-400 ">
                          {item?.services?.join(", ")}
                        </p>
                      </td>
                      <td>
                        <button
                          onClick={() => handleViewClick(item)}
                          className="px-4 py-2 bg-secondary-g text-black rounded"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center text-xl text-white">
                No data found
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center gap-2 pb-5">
          <Stack spacing={2} justifyContent="center" mt={4}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
              shape="circular"
              sx={{
                "& .Mui-selected": {
                  color: "#000 !important",
                  fontWeight: "700",
                },
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};
