import React, { useState } from "react";
import axios from "axios";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ResetPassword = ({ email }) => {
  const navigate = useNavigate();

  const [forget, setForget] = useState({
    email: email,
    otp: "",
    newPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForget({ ...forget, [e.target.name]: e.target.value });
  };

  const handleOtpChange = (otp) => {
    setForget({ ...forget, otp });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({});

    // Validation
    let newErrors = {};
    if (!forget.otp) {
      newErrors.otp = "OTP is required";
    }
    if (forget.otp.length !== 6) {
      newErrors.otp = "OTP must be 6 digits";
    }
    if (!forget.newPassword) {
      newErrors.newPassword = "New password is required";
    }
    if (forget.newPassword.length < 6) {
      newErrors.newPassword = "Password must be at least 6 characters long";
    }

    // Set errors if any
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with API call
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/reset`,
        forget
      );
      toast.success(response?.data?.message);
      navigate("/login");
    } catch (error) {

      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="w-full absolute flex flex-col justify-center items-center left-0 top-0 text-white">
      <div className="bg-[#000] border-2 border-primary-b lg:w-[450px] w-[400px]  space-y-6 p-10 rounded-lg ">
        <p className="lg:text-[30px] md:text-[20px] text-[13px] font-bold">
          Reset Password
        </p>
        <div>
          <form className="space-y-4">
            <div className="flex flex-col">
              <label className="font-bold lg:text-[20px] text-[13px]">
                OTP
              </label>
              <OTPInput
                value={forget.otp}
                onChange={handleOtpChange}
                numInputs={6}
                isInputNum
                renderSeparator={<span style={{ width: "15px" }}></span>}
                renderInput={(props) => (
                  <input {...props} className="otp-input" />
                )}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid #00F3F4",
                  borderRadius: "8px",
                  margin: "10px 0",
                  width: "45px",
                  height: "45px",
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  fontFamily: "Gilroy",
                }}
                focusStyle={{
                  border: "1px solid ##9ADD3A",
                }}
              />
              {errors.otp && <span className="text-red-500">{errors.otp}</span>}
            </div>
            <div className="flex flex-col">
              <label className="font-bold text-[20px]">New Password</label>
              <input
                className="text-black rounded-full p-2 my-2"
                name="newPassword"
                value={forget.newPassword}
                onChange={handleChange}
              />
              {errors.newPassword && (
                <span className="text-red-500">{errors.newPassword}</span>
              )}
            </div>
            <button
              className="font-[montserrat] rounded-full my-3 border-2 border-primary-b"
              onClick={handleSubmit}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
