import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let auth = localStorage.getItem("adminToken");
  if (auth) {
    // Token exists in local storage
    return <Outlet />;
  } else {
    // Token does not exist, redirect to login page
    return <Navigate to="/admin/sign_in" />;
  }
};

export default PrivateRoutes;
