import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import SignUp from "./components/Auth/SignUp";
import Login from "./components/Auth/Login";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import Payment from "./components/cart/Payment";
import Dashboard from "./AdminPages/Dashboard/Dashboard";
import ListedItem from "./AdminPages/ListedItem/ListedItem";
import Drafts from "./AdminPages/Drafts/Drafts";
import Orders from "./AdminPages/Orders/Orders";
import { Product } from "./AdminPages/product/Product";
import Categories from "./AdminPages/Categories/Categories";
import Users from "./AdminPages/Users/Users";
import PrivateRoutes from "./utils/PrivateRoutes";
import SignIn from "./Component/Auth/Login/SignIn";
import Home from "./Pages/ProfilePage/Home";
import { toast, ToastContainer } from "react-toastify";
import Activity from "./components/Profile/Activity";
import Details from "./components/projects/Details";
import Notfound from "./Constant/Notfound";
import ProductNotFound from "./components/projects/ProductNotFound";
import { Customization } from "./AdminPages/Customization/Customization";
import { Search } from "./components/Search/Search";
import { useEffect, useState } from "react";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material";
import Samples from "./components/projects/Samples";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import FaqPage from "./Pages/ProfilePage/Faq/FaqPage";

const AppContent = () => {
  const baseURL = process.env.REACT_APP_URL;
  const location = useLocation();
  useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("firstName");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("products");
    localStorage.removeItem("users");
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const adminToken = localStorage.getItem("adminToken");
        const authToken = token || adminToken;

        if (authToken) {
          const response = await axios.get(`${baseURL}/users/me`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (response.status === 401) {
            toast.info("Invalid token, please login again.");
            handleLogout();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.info("Invalid token, please login again.");
          handleLogout();
        } else {
          toast.info(error?.response?.data?.message || "An error occurred.");
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchData();
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/terms-and-conditions" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="*" element={<Notfound />} />
      <Route path="/" element={<Home />} />
      <Route path="/profile/*" element={<ProfilePage />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/details/:id" element={<Details />} />
      <Route path="/search/:id" element={<Search />} />
      <Route path="/collection/:id" element={<Samples />} />
      <Route path="/product-not-found" element={<ProductNotFound />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route exact path="/admin/sign_in" element={<SignIn />} />
      <Route path="/activity/:productId" element={<Activity />} />
      <Route element={<PrivateRoutes />}>
        <Route exact path="/admin/dashboard" element={<Dashboard />} />
        <Route exact path="/admin/listing" element={<ListedItem />} />
        <Route exact path="/admin/drafts" element={<Drafts />} />
        <Route exact path="/admin/orders" element={<Orders />} />
        <Route exact path="/admin/product" element={<Product />} />
        <Route exact path="/admin/categories" element={<Categories />} />
        <Route exact path="/admin/customization" element={<Customization />} />
        <Route exact path="/admin/users" element={<Users />} />
      </Route>
    </Routes>
  );
};

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00F3F4",
      },
      secondary: {
        main: "#9ADD3A",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ThemeProvider>
  );
}
