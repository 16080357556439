import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import axios from "axios";
import Loader from "../../../Component/Loader/Loader";
import NoImage from "../../../assets/images/No_Image_Available.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./ImageModal";

export default function Modal({ centredModal, setCentredModal, productId }) {
  const toggleOpen = () => setCentredModal(!centredModal);
  const [userDetail, setUserDetail] = useState();
  const [loader, setloader] = useState(true);
  const [product, setproduct] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const baseURL = process.env.REACT_APP_URL;

  const getUserDetail = async () => {
    const token = localStorage.getItem("adminToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(`${baseURL}/users/${productId}`, config);
      setproduct(response.data.user.myOrders);
      setUserDetail(response.data.user);

      if (!response.data.user.image) {
        toast.error("No image available for this user.");
      }

      setloader(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
        <MDBModalDialog centered>
          {loader ? (
            <Loader />
          ) : (
            <MDBModalContent className="bg-black">
              <MDBModalHeader className="bg-black">
                <h3 className="mt-2 text-xl font-bold bg-black uppercase text-primary-b">
                  User Info
                </h3>
                <div className="bg-secondary-g rounded-full p-0.5 ">
                  <MDBBtn
                    className="btn-close grayscale brightness-200 m-0 z-50"
                    color="none"
                    onClick={toggleOpen}
                  ></MDBBtn>
                </div>
              </MDBModalHeader>
              <MDBModalBody className="bg-black ">
                <div className="container py-4">
                  <div className="flex justify-between  lg:flex-row flex-col gap-5">
                    <div className="col-6 d-flex align-items-center  justify-around">
                      <img
                        src={
                          userDetail?.image != null ? userDetail.image : NoImage
                        }
                        alt=""
                        className="object-cover rounded-lg h-auto w-28 mr-auto"
                      />
                    </div>
                    <div className="col-6 d-flex align-items-center ">
                      <div>
                        <p className="fw-bold mb-1 lg:text-xl text-sm text-secondary-g">
                          {userDetail?.firstName} {userDetail?.lastName}
                        </p>
                        <p className="m-0 text-sm text-gray-300">
                          {userDetail?.email}
                        </p>
                        <p className="m-0 text-sm text-gray-300">
                          {userDetail?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBModalBody>
              <div className="px-4 py-2 bg-black">
                <p className="fw-bold mb-1 text-xl text-secondary-g">
                  Order History
                </p>
              </div>
              <div className="h-36 overflow-auto px-4  bg-black">
                {product.length === 0 ? (
                  <p className="text-white">No orders yet</p>
                ) : (
                  product.map((item, index) => {
                    const productImage =
                      item.products?.productId?.images?.[0] || NoImage;
                    const productTitle =
                      item.products?.productId?.title || "Unknown Title";
                    const productPrice =
                      item.products?.productId?.price !== undefined
                        ? `$${item.products.productId.price}`
                        : "Unknown Price";

                    return (
                      <div
                        key={index}
                        className="w-full py-2 pl-2 pr-3 flex my-4 justify-between rounded-lg border-primary-b border-[1px] gap-4 md:gap-10 items-center"
                      >
                        <div
                          onClick={() => handleOpen(productImage || "No Image")}
                          style={{
                            backgroundImage: `url(${productImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top left",
                            backgroundSize: "cover",
                          }}
                          alt=""
                          className="w-20 h-20 object-contain rounded-lg cursor-pointer transition-all duration-700 bg-bottom-left"
                        />

                        <div className="flex justify-center items-center md:gap-4 gap-1.5">
                          <p className="md:text-lg text-[16px] gap-1 text-white flex text-left flex-col font-bold w-20 sm:w-[100px]">
                            Title
                            <span className="text-sm text-white font-medium">
                              {productTitle}
                            </span>
                          </p>

                          <p className="md:text-[20px] text-[16px] gap-1 text-white flex text-left flex-col font-bold">
                            Price
                            <span className="sm:text-[15px] text-sm text-white font-medium">
                              {productPrice}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}

                <ImageModal 
                showModal={openModal}
                setShowModal={setOpenModal}
                imageSrc = {selectedImage}

                />
              </div>

              <MDBModalFooter className="bg-black">
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  <span className="text-black">Close</span>
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          )}
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>
  );
}
