import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cut from "../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import TimePicker from "../Calender/TimePicker";
import Calendar from "../Calender/Calender";
import moment from "moment";
import { useEffect } from "react";

const GetInTouchPopup = ({
  isPopupOpen,
  setIsPopupOpen,
  productId,
  product,
}) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsPopupOpen(false);
    }
  };

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const [customerEmail, setCustomerEmail] = useState("");
  const token = localStorage.getItem("token");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [amount, setAmount] = useState(0);
  const [serviceError, setServiceError] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setCustomerEmail(storedEmail);
    }
  }, []);
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setCustomerEmail(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, customerEmail: "" }));
  };

  const handleServiceChange = (service) => {
    setSelectedServices((prevServices) =>
      prevServices.includes(service)
        ? prevServices.filter((s) => s !== service)
        : [...prevServices, service]
    );

    const newServices = selectedServices.includes(service)
      ? selectedServices.filter((s) => s !== service)
      : [...selectedServices, service];

    setAmount(newServices.length * 500);
    setServiceError("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formIsValid = true;
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email
    if (!customerEmail.trim()) {
      newErrors.customerEmail = "Email is required";
      formIsValid = false;
    } else if (!emailPattern.test(customerEmail.trim())) {
      newErrors.customerEmail = "Invalid email format";
      formIsValid = false;
    }

    // Validate services
    if (selectedServices.length === 0) {
      setServiceError("Please select at least one service.");
      formIsValid = false;
    }

    if (!selectedDate || !selectedTime) {
      toast.error("Please select both date and time.");
      formIsValid = false;
    }

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    try {
      const combinedDateTime = moment(
        `${selectedDate} ${selectedTime}`
      ).toISOString();

      toast.success("Consultation Booked Successfully");
      navigate("/payment", {
        state: {
          customerEmail: customerEmail,
          slotDate: combinedDateTime,
          productId: productId,
          services: selectedServices || [],
          amount: amount,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
        },
      });
    } catch (err) {
      toast.error("Error submitting customization request");
    }
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div
        onClick={handleOverlayClick}
        className="bg-black w-full h-full fixed top-0 flex flex-col justify-center items-center opacity-60"
      ></div>
      <form className="lg:w-full w-[95%] max-w-4xl lg:p-20 lg:px-5 px-3 py-5 bg-black border-[#9ADD3A] border-[1px] text-white absolute lg:top-20 top-5 rounded-3xl opacity-100">
        <span
          className="text-white absolute right-5 top-5"
          onClick={() => setIsPopupOpen(false)}
        >
          <img src={Cut} alt="" className="w-6" />
        </span>
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-center gap-3 lg:mt-6">
            <h2 className="lg:text-[30px] text-[20px] text-center leading-5 text-[#00F3F4] uppercase">
              For customization
            </h2>
            <p className="text-sm font-[montserrat] leading-7 text-center">
              Build A Unique Identity For Your Brand And Allow It To Thrive In
              The Marketplace. Our Customised Logo Designs Capture The True
              Essence Of What You Stand For. We Make Sure Every Detail Is
              On-Brand And One Of A Kind Because You Deserve The Best.
            </p>
          </div>
        </div>

        <div className="my-3">
          <label
            class="block text-white font-[Macoxs] lg:text-sm text-[10px] uppercase tracking-[2px]"
            for="customerEmail"
          >
            Email:
          </label>
          <input
            required
            type="email"
            value={customerEmail}
            onChange={handleEmailChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter email"
            className=" w-full font-[montserrat] text-black lg:text-[14px] mt-2 text-[12px] bg-white h-10 px-3 rounded-xl"
          />{" "}
          <span className="text-red-500 mt-1 block">
            {errors.customerEmail}
          </span>
        </div>
        <div className="flex flex-wrap  gap-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="digiMarket"
              className="accent-secondary-g border-secondary-g border-2 rounded"
              onChange={() => handleServiceChange("Digital marketing")}
              checked={selectedServices.includes("Digital marketing")}
            />
            <label
              htmlFor="digiMarket"
              className="text-[15px] text-wrap text-secondary-g font-[montserrat] m-0 p-0"
            >
              Digital marketing <span className="text-primary-b">($500)</span>
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="paymentGat"
              className="accent-secondary-g border-secondary-g border-2 rounded"
              onChange={() => handleServiceChange("Payment Gateway")}
              checked={selectedServices.includes("Payment Gateway")}
            />
            <label
              htmlFor="paymentGat"
              className="text-[15px] text-wrap text-secondary-g font-[montserrat] m-0 p-0"
            >
              Payment Gateway <span className="text-primary-b">($500)</span>
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="Ecom"
              className="accent-secondary-g border-secondary-g border-2 rounded"
              onChange={() => handleServiceChange("Ecommerce")}
              checked={selectedServices.includes("Ecommerce")}
            />
            <label
              htmlFor="Ecom"
              className="text-[15px] text-wrap text-secondary-g font-[montserrat] m-0 p-0"
            >
              Ecommerce <span className="text-primary-b">($500)</span>
            </label>
          </div>
        </div>
        {serviceError && (
          <div className="text-red-500 mt-1 mb-3 font-[montserrat]">
            {serviceError}
          </div>
        )}
        <div className="flex my-5 flex-col gap-5 md:flex-row">
          <Calendar onDateSelect={handleDateSelect} />
          <TimePicker
            onTimeSelect={handleTimeSelect}
            selectedDate={selectedDate}
          />
        </div>
        <button
          onClick={handleSubmit}
          className="bg-[#00F3F4] font-[montserrat] text-black rounded-lg w-full"
        >
          Book consultation
        </button>
      </form>
    </div>
  );
};

export default GetInTouchPopup;
