import React from "react";
import Logo from "../../src/assets/images/dipixels-logo-full.png";
import { OnBack } from "../Component/OnBack/OnBack";
import Footer from "../components/Common/footer/Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="py-10 lg:px-[5rem] px-4 max-w-7xl mx-auto">
        <div className="w-full absolute left-[40px]">
          <OnBack />
        </div>
        <div className="w-full flex flex-col justify-center items-center ">
          <div className="mt-28 text-white text-center">
            <h1 className="text-primary-b text-xl lg:text-3xl  font-[Monument]">
              Privacy Policy
            </h1>
            <p className="mt-4 text-[15px] leading-7">
              At Dipixels, we are committed to protecting your privacy. This
              Privacy Policy outlines how we collect, use, and safeguard your
              personal information.
            </p>
          </div>
        </div>
        <div className="w-full mt-10 flex flex-col text-white justify-start items-start">
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            1. Information We Collect:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Information We Collect:
            </span>{" "}
            When you create an account, place an order, or contact us, we may
            collect personal information such as your name, email address, phone
            number, and payment details.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">Usage Data:</span> We
            collect information about your interactions with our website,
            including your IP address, browser type, pages visited, and time
            spent on our site.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            2. How We Use Your Information:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              To Process Orders:
            </span>{" "}
            We use your personal information to process and fulfill your orders,
            including sending confirmation emails and updates on your order
            status.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Customer Support:{" "}
            </span>{" "}
            Your information helps us respond to your customer service requests
            and support needs more efficiently.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Improving Our Services:{" "}
            </span>{" "}
            We analyze usage data to improve our website, products, and services
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">Marketing: </span>{" "}
            With your consent, we may use your information to send promotional
            emails about new products, special offers, and other updates.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            3. Sharing Your Information:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Service Providers:
            </span>{" "}
            We may share your information with third-party service providers who
            assist us in operating our website, conducting our business, or
            servicing you, as long as those parties agree to keep your
            information confidential.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Legal Requirements:
            </span>{" "}
            We may disclose your information if required to do so by law or in
            response to valid requests by public authorities.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            4. Security of Your Information:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; We implement a variety of security measures to maintain the
            safety of your personal information when you place an order or
            enter, submit, or access your personal information.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            5. Your Rights:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">
              Access and Correction:
            </span>{" "}
            You have the right to access and correct your personal information.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">Deletion:</span> You
            can request the deletion of your personal information, subject to
            certain exceptions.
          </p>
          <p className="text-[14px] leading-7">
            &#9675;{" "}
            <span className="font-semibold text-gray-400">Opt-Out:</span> You
            can opt-out of receiving promotional communications from us by
            following the unsubscribe instructions provided in those
            communications.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            6. Cookies:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; We use cookies to enhance your experience, gather general
            visitor information, and track visits to our website. You can choose
            to disable cookies through your browser settings.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            7. Changes to Our Privacy Policy:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; We may update this Privacy Policy from time to time. Any
            changes will be posted on this page with an updated revision date.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            8. Contact Us:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; If you have any questions about this Privacy Policy, please
            contact us at
            <a href="mailto:info@dipixels.com." target="_blank">
              <span className="text-secondary-g hover:text-opacity-80 mx-1">
                INFO@DIPIXELS.COM.
              </span>
            </a>
          </p>
          <div className="mt-28 text-white text-center">
            <h1 className="text-primary-b text-xl lg:text-3xl  font-[Monument]">
              Refund and Revision Policy{" "}
            </h1>
            <p className="mt-4 text-[15px] leading-7">
              At Dipixels, we prioritize customer satisfaction and stand behind
              the quality of our designs. Instead of offering refunds, we
              provide revisions to ensure you receive a product that meets your
              expectations.
            </p>
          </div>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            1. Refund Policy:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; There is no refund on all digital themes, digital assets as
            they are provided with downloadable files in your email within a
            reasonable time of your purchase confirmation. 100% delivery with
            the assets requests are completed automatically.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; You can request for any mistake or change by reaching out to
            <a href="mailto:info@dipixels.com." target="_blank">
              <span className="text-secondary-g hover:text-opacity-80 mx-1">
                INFO@DIPIXELS.COM.
              </span>
            </a>{" "}
            and all responses are catered within 24 hours of the ticket being
            initiated. We do offer revisions to ensure you are happy with the
            final product. Revisions are only provided for customized designs
            and in some cases for the assets purchased. **(Dipixels reserves the
            right to make decisions on changes).
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            2. Eligibility for Revisions:
          </h4>
          <p className="text-[14px] leading-7 ml-4">
            &#9675; Only Customized designs are eligible for revisions as
            follows::
            <ul className="list-decimal">
              <li>
                Revisions can be requested within 7 days of receiving the
                design.
              </li>
              <li>
                The design must be in its original condition without any
                alterations.
              </li>
            </ul>
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            3. Conditions for Revisions: (Customized Designs Only)
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; Revisions are provided if the initial design does not meet
            the specifications outlined in your order.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; Requests for revisions must be specific about the changes
            required.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; Up to two rounds of revisions are allowed per order.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            4. Process for Requesting Revisions:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; Contact our customer support team at{" "}
            <a href="mailto:info@dipixels.com." target="_blank">
              <span className="text-secondary-g hover:text-opacity-80 mx-1">
                INFO@DIPIXELS.COM.
              </span>
            </a>{" "}
            with your order details and the specific revisions required.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; Our team will review your request and respond within 5
            business days with a plan for the revisions.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            5. Exceptions:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; We do not offer revisions for designs that have been altered
            or damaged after delivery.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; Final sale items or custom orders are not eligible for
            revisions unless there is a defect in quality.
          </p>
          <h4 className="text-base font-[Macoxs]  mb-2 mt-4  text-secondary-g tracking-[2px]">
            6. Asset Delivery:
          </h4>
          <p className="text-[14px] leading-7">
            &#9675; All assets are delivered electronically to the email
            provided. It is the customer's responsibility to provide correct
            information while filling out the forms and payment page.
          </p>
          <p className="text-[14px] leading-7">
            &#9675; As we sell digital designs, there are no shipping charges
            involved in the process.
          </p>
          <p className="text-[14px] leading-7 mt-4">
             For any questions or to initiate a revision request, please
            contact our customer service team at{" "}
            <a href="mailto:info@dipixels.com." target="_blank">
              <span className="text-secondary-g hover:text-opacity-80 mx-1">
                INFO@DIPIXELS.COM.
              </span>
            </a>{" "}
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
