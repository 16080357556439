import React, { useRef, useEffect, useState } from "react";
import { JwtDecodeOptions, jwtDecode } from "jwt-decode";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import GoogleButton from "./GoogleButton";
import Header from "../Common/header/Header";
import Gif from "../../assets/images/gif/gif2 (3).gif";
import axios from "axios";
import Verification from "./Verification";
import ForgetPassword from "./ForgetPassword";
import { ErrorIcon, EyeIcon, EyeOffIcon } from "../../svg";
const Login = () => {
  const location = useLocation();
  const LoginData = localStorage.getItem("userId");
  const [showPassword, setShowPassword] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const baseURL = process.env.REACT_APP_URL;
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const previous = location?.state?.previous ? true : false;

  const handleButtonClick = () => {
    setOpenModal(true);
    setEmail(loginData.email);
  };
  const closePopup = () => {
    setOpenModal(false);
  };
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    setIsPending(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/login`,
        loginData
      );
      const role = response.data.user.role;
      localStorage.setItem("role", role);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("firstName", response.data.user.firstName);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("userId", response.data.user._id);
      localStorage.setItem(
        "pixelUser_Details",
        JSON.stringify(response.data.user)
      );

      if (role === "customer") {
        if (previous) {
          navigate(-1);
        } else {
          navigate("/");
        }
        toast.success("You've successfully logged in!");
        setIsPending(false);
        setError(error?.response?.data?.message);
      } else {
        toast.error("Invalid role. Please log in as a customer.");
        setIsPending(false);
        setError(error?.response?.data?.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      setIsPending(false);
      if (error?.response?.data?.code) {
        handleButtonClick();
        setIsPending(false);
      } else {
        setError(error?.response?.data?.message);
        setIsPending(false);
      }
    }
  };

  //forget pass
  const [forgetPass, setForgetPass] = useState(false);
  const handleClick = () => {
    setForgetPass(true);
  };
  const handleClose = () => {
    setForgetPass(false);
  };

  return (
    <section className="login relative  bg-black flex justify-center items-center h-[100vh]">
      <div className="section w-[101vw] h-[100vh] absolute flex justify-center items-center gap-[3px] flex-wrap">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="absolute bg-black  rounded-2xl md:max-w-2xl w-[90%] py-12 md:py-16">
        <form
          className=" text-white flex justify-center items-center w-full top-0 bg-black  max-w-[60rem] rounded-2xl"
          onSubmit={handleSubmit}
        >
          <div className="w-full font-[Macoxs] tracking-wider  flex flex-col justify-center space-y-10 lg:px-20 md:px-10 px-[20px]">
            <div className="w-full">
              {error && (
                <div className="flex items-center gap-1 justify-center text-red-400 mt-2 font-[montserrat]  md:text-[20px] text-sm ">
                  <ErrorIcon />
                  {error}
                </div>
              )}
            </div>
            {/* <GoogleButton /> */}

            <div className="w-full  flex flex-col space-y-2 mt-4">
              <label className="lg:text-[15px] text-[13px]">Email</label>
              <input
                name="email"
                placeholder="Email"
                value={loginData.email}
                onChange={handleChange}
                className="font-[montserrat]  font-bold px-3 rounded-xl text-black py-2 w-full focus:outline-secondary-g"
              ></input>
            </div>

            <div className="w-full flex flex-col space-y-2">
              <div className="flex justify-between">
                <label className="lg:text-[15px] text-[13px]">Password</label>
                <p
                  className="cursor-pointer lg:text-[15px] text-[13px]"
                  onClick={handleClick}
                >
                  Forgot?
                </p>
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={loginData?.password}
                  onChange={handleChange}
                  className="py-2  font-[montserrat] font-bold px-3 rounded-xl text-black w-full focus:outline-secondary-g "
                />

                <div
                  className="absolute top-2 min-h-7 flex items-center right-3"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <EyeIcon color="#000" />
                  ) : (
                    <EyeOffIcon color="#000" />
                  )}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="rounded-full font-[montserrat] lg:text-[15px] text-[13px]"
              onSubmit={handleSubmit}
            >
              {!isPending ? "Login" : "Logging in ..."}
            </button>
            <p className="text-[12px]">
              Don't have an account?{" "}
              <Link to="/signup" className="text-primary-b ">
                SignUp
              </Link>
            </p>
          </div>
        </form>
        {openModal && <Verification email={email} onClose={closePopup} />}
        {forgetPass && <ForgetPassword onClose={handleClose} />}
      </div>
    </section>
  );
};

export default Login;
