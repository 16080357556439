import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Component/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { ArrowRightIcon, EyeIcon } from "../../svg";
import LikeIcon from "./Like";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CancelIcon, SearchIcon } from "../../svg/utils";
import { OnBack } from "../../Component/OnBack/OnBack";
import Animation from "./Animation";
import Footer from "../Common/footer/Footer";
import LogoLoader from "../../Component/LogoLoader/LogoLoader";
import NoImage from "../../assets/images/No_Image_Available.jpg";
import LazyLoad from "react-lazyload";
import Modal from "react-modal";
import ConfirmModel from "./confirmModel";
import Cut2 from "../../../src/assets/images/Icon ionic-ios-close-circle-outline.svg";
Modal.setAppElement("#root");
const Samples = () => {
  const LoginData = localStorage.getItem("userId");
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 8; // Number of products per page
  const [saved, setSaved] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const selectedTag = location.state.selectedTag;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [productToSave, setProductToSave] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProduct();
    // fetchSavedProducts();
  }, [selectedTag, currentPage]);

  const fetchProduct = async () => {
    setIsLoading(true);

    try {
      const filter = selectedTag ? { categories: selectedTag } : {};
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/products?page=${currentPage}&limit=${limit}`,
        { filter },
        config
      );
      setIsLoading(false);

      if (currentPage === 1) {
        setProduct(response.data?.data || []);
      } else {
        setProduct((prevProducts) => [...prevProducts, ...response.data?.data]);
      }
      setTotalPages(response.data?.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching category:", error);
      setIsLoading(false);
    }
  };

  // const fetchSavedProducts = async () => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_URL}/users/me`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const generalInfo = response?.data?.data;
  //     if (generalInfo?.savedProducts) {
  //       setSaved(generalInfo.savedProducts.map((item) => item._id));
  //     }
  //   } catch (error) {
  //     toast.info(error?.response?.data?.message);
  //     console.error("Error fetching user data:", error);
  //   }
  // };

  const handleButtonClick = async (product) => {
    window.scrollTo(0, 0);

    try {
      navigate(`/details/${product.title.toLowerCase().replace(/ /g, "_")}`, {
        state: { product },
      });
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_URL}/products/view/${product._id}`,
        null,
        config
      );
    } catch (error) {
      console.error("Error viewing product:", error);
    }
  };

  const [savedLocally, setSavedLocally] = useState([]);

  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    setSavedLocally(savedProducts.map((item) => item._id));
  }, []);

  const saveProductsToLocal = (product) => {
    let savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    const existingProduct = savedProducts.find((p) => p._id === product._id);
    if (existingProduct) {
      toast.info("Product already in cart!");
    } else {
      savedProducts.push(product);
      localStorage.setItem("products", JSON.stringify(savedProducts));
      setSavedLocally([...savedLocally, product._id]);
      toast.success("Item successfully added to your cart!");
      navigate("/payment");
    }
  };

  const handleSaveItem = async (productId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (saved.includes(productId)) {
        await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        setSaved(saved.filter((id) => id !== productId));
        toast.warning("Product unsaved!");
      } else {
        await axios.post(
          `${process.env.REACT_APP_URL}/products/save/${productId}`,
          null,
          config
        );
        setSaved([...saved, productId]);
        toast.success("Save Product!");
      }
    } catch (error) {
      console.error("Error saving/unsaving product:", error);
      toast.error("Please log in to access this feature.");
    }
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Filter categories based on the search value
    const filteredProduct = product.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setProduct(filteredProduct);
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setProductToSave(null);
  };
  // const handleModalConfirm = () => {
  //   let savedProducts = JSON.parse(localStorage.getItem("products")) || [];
  //   savedProducts = [productToSave];
  //   localStorage.setItem("products", JSON.stringify(savedProducts));
  //   setSavedLocally([productToSave._id]);
  //   toast.success("Item successfully replaced in your cart!!!");

  //   handleModalClose();
  // };
  return (
    <div className="p-4 lg:p-10">
      <div className="px-10 lg:w-[19rem]">
        <OnBack />
      </div>
      <span className="text-white absolute right-5 top-5">
        <Link to="/">
          {" "}
          <img src={Cut2} alt="" className="w-6" />
        </Link>
      </span>
      <div className="mt-10">
        <div className="text-center mb-6">
          <h2 className="lg:text-[45px] sm:text-[25px] mt-20 text-[#00F3F4]  font-[Macoxs] uppercase tracking-[5px]">
            <span className="text-[#9ADD3A]">Rapid </span>
            Innovation
          </h2>
          <p className="text-white text-xl max-w-md mx-auto">
            From HD videos to amazing AI-generated images, find the content you
            want in the format you need
          </p>
        </div>
        <form className="max-w-3xl mx-auto" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="relative w-full min-h-14">
              <input
                type="search"
                id="search-dropdown"
                className="placeholder:text-white px-4 rounded-t-lg rounded-bl-lg text-white block p-2.5 w-full z-20 h-full text-sm bg-black rounded-e-lg border-2 border-primary-b focus:ring-primary-b focus:border-primary-b"
                placeholder="Search Products ...."
                value={searchValue}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="absolute top-0 end-0 py-2.5 px-4 h-full text-sm font-medium text-white bg-primary-b rounded-e-lg border-primary-b hover:border-primary-b hover:bg-opacity-85 "
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      {product.length === 0 ? (
        <div className="text-white flex justify-center text-center w-full">
          <p className="my-5"> No products found for the selected category.</p>
          </div>
      ) : (
        <>
          <InfiniteScroll
            dataLength={product.length}
            next={() => setCurrentPage(currentPage + 1)}
            hasMore={currentPage < totalPages}
            // loader={<LogoLoader />}
            endMessage={
              <p style={{ textAlign: "center", color: "#fff" }}>
                No more products to load.
              </p>
            }
          >
            <div className="box w-full grid md:grid-cols-3 xl:grid-cols-4 gap-4 p-10">
              {product.map((array, i) => (
                <div
                  key={array._id}
                  className="pd_hover relative overflow-hidden rounded-3xl cursor-pointer group w-full h-72 lg:h-96 border-4 border-[#c7c7c7]"
                >
                  <LazyLoad className="object-cover rounded-2xl overflow-hidden w-full">
                    <img
                      className="object-cover rounded-2xl overflow-hidden w-full"
                      // src={array.images[0]}
                      src={
                        array.mockupImage == "" ? NoImage : array.mockupImage
                      }
                      alt="design"
                      onLoad={handleImageLoad}
                    ></img>
                    {!imageLoaded && <LogoLoader />}
                  </LazyLoad>
                  <div
                    onClick={() => handleButtonClick(array)}
                    className="hidden h-0 group-hover:h-full w-full group-hover:flex flex-col justify-end items-center absolute bottom-0 bg-gradient-to-b from-transparent to-[#000000] rounded-b-2xl"
                  >
                    <div className="flex justify-between py-4 items-center w-full px-[20px]">
                      <div className="w-full">
                        <div className="flex flex-col">
                          <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                            {array?.title}
                          </p>
                        </div>
                        <div>
                          <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                            Price: $ {array?.price}
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-center ml-3 items-center gap-1">
                        <EyeIcon size={true} color="#fff" />
                        <p className="text-white mb-0 font-[montserrat] font-bold drop-shadow-sm">
                          {array.views}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icons_aside flex items-center flex-col gap-2 absolute top-2/3 right-4 -translate-y-1/2 -mt-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => saveProductsToLocal(array)}
                    >
                      <button
                        className={`shadow-sm h-10 font-[montserrat] w-10 flex p-0 items-center justify-center font-bold rounded-full hover:border-transparent ${
                          savedLocally.includes(array._id)
                            ? "border-[#FCECFA] bg-[#FCECFA]"
                            : "border-white bg-white"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          id="Cart"
                          width="16"
                          height="16"
                        >
                          <path
                            d="M8.5,19A1.5,1.5,0,1,0,10,20.5,1.5,1.5,0,0,0,8.5,19ZM19,16H7a1,1,0,0,1,0-2h8.49121A3.0132,3.0132,0,0,0,18.376,11.82422L19.96143,6.2749A1.00009,1.00009,0,0,0,19,5H6.73907A3.00666,3.00666,0,0,0,3.92139,3H3A1,1,0,0,0,3,5h.92139a1.00459,1.00459,0,0,1,.96142.7251l.15552.54474.00024.00506L6.6792,12.01709A3.00006,3.00006,0,0,0,7,18H19a1,1,0,0,0,0-2ZM17.67432,7l-1.2212,4.27441A1.00458,1.00458,0,0,1,15.49121,12H8.75439l-.25494-.89221L7.32642,7ZM16.5,19A1.5,1.5,0,1,0,18,20.5,1.5,1.5,0,0,0,16.5,19Z"
                            fill={
                              savedLocally && savedLocally.includes(array._id)
                                ? "#EA64D9"
                                : "#000"
                            }
                            class="color000000 svgShape"
                          ></path>
                        </svg>{" "}
                      </button>
                    </div>
                    <LikeIcon
                      product={array.likedBy}
                      id={array._id}
                      userId={LoginData}
                      fetchProduct={fetchProduct}
                    />
                    <button
                      onClick={() => handleSaveItem(array._id)}
                      className={` h-10 font-[montserrat] w-10 flex p-0 items-center justify-center font-bold rounded-full hover:border-transparent   ${
                        saved.includes(array._id)
                          ? "border-[#FCECFA] bg-[#FCECFA]"
                          : " border-white bg-white"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill={saved.includes(array._id) ? "#EA64D9" : "#FFFFFF"}
                        role="img"
                        class="icon "
                      >
                        <path
                          d="M3.33337 5.2C3.33337 4.0799 3.33337 3.51984 3.55136 3.09202C3.74311 2.71569 4.04907 2.40973 4.42539 2.21799C4.85322 2 5.41327 2 6.53337 2H9.46671C10.5868 2 11.1469 2 11.5747 2.21799C11.951 2.40973 12.257 2.71569 12.4487 3.09202C12.6667 3.51984 12.6667 4.0799 12.6667 5.2V14L8.00004 11.3333L3.33337 14V5.2Z"
                          stroke={
                            saved.includes(array._id) ? "#EA64D9" : "#000"
                          }
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
              {/* 
              <ConfirmModel
                isOpen={showModal}
                onClose={handleModalClose}
                onConfirm={handleModalConfirm}
                productToSave={productToSave}
              /> */}
            </div>
            {isLoading && (
              <div className="flex justify-center items-center ">
                <LogoLoader />
              </div>
            )}
          </InfiniteScroll>
        </>
      )}
      <Animation />

      <Footer />
    </div>
  );
};

export default Samples;
