import React, { useEffect, useState } from "react";
import axios from "axios";
import { EyeIcon, EyeOffIcon } from "../../svg";
import Close from "../../assets/images/Icon ionic-ios-close-circle-outline.svg";
import { toast } from "react-toastify";
const baseURL = process.env.REACT_APP_URL;

const History = () => {
  // const userTabs = [
  //   {
  //     id: "Pending",
  //     tab: "Pending",
  //     status: "Pending",
  //   },
  //   {
  //     id: "Processing",
  //     tab: "Processing",
  //     status: "Processing",
  //   },
  //   {
  //     id: "Completed",
  //     tab: "Completed",
  //     status: "Completed",
  //   },
  //   {
  //     id: "Cancelled",
  //     tab: "Cancelled",
  //     status: "Cancelled",
  //   },
  // ];
  // const [selectedTab, setSelectedTab] = useState(userTabs[0].id);
  const [generalInfo, setGeneralInfo] = useState({});
  const [filteredOrders, setFilteredOrders] = useState([]);

  const token = localStorage.getItem("token");
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toggleImageModal = (imageURL) => {
    setSelectedImage(imageURL);
    setShowImageModal(!showImageModal);
  };

  useEffect(() => {
    // setSelectedTab(userTabs[0].id);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setGeneralInfo(response?.data?.data);
        localStorage.setItem("users", JSON.stringify(response?.data?.data));
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.info(error?.response?.data?.message);
      }
    };

    fetchUserData();
  }, [baseURL, token]);

  return (
    <div className="w-full  flex justify-center xl:items-start items-center flex-col lg:absolute lg:top-[30%] ">
      <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
        <span className="text-secondary-g mr-2">My</span> Orders
      </p>
      <div className="w-full">
        <div className="tab-content overflow-auto h-[70]">
          {generalInfo?.myOrders?.length === 0 && (
            <p className="uppercase lg:text-[30px] text-[24px] text-[#00F3F4] font-[Macoxs] flex items-center">
              <span className="text-secondary-g mr-2">No Orders</span> Found
            </p>
          )}
        </div>
        <div className="flex gap-4 flex-wrap w-full">
          {generalInfo?.myOrders?.map((order) => (
            <div
              key={order?.products?._id}
              className="relative overflow-hidden rounded-3xl cursor-pointer group w-80 h-72 lg:h-96 border-4 border-[#c7c7c7]"
            >
              <img
                className="object-cover rounded-2xl w-full h-full"
                src={order.products?.productId?.images}
                alt={"Product image"}
                //  onClick={() =>
                //                 toggleImageModal(order.products?.productId?.images)
                //               }
              />
              <div className="hidden h-0 group-hover:h-full w-full group-hover:flex flex-col justify-end items-center absolute bottom-0 bg-gradient-to-b from-transparent to-[#000000] rounded-b-2xl">
                <div className="flex justify-between py-4 items-center w-full px-[20px]">
                  <div className="w-full">
                    <div className="flex flex-col">
                      <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                        {order?.products?.productId?.title}
                      </p>
                    </div>
                    <div>
                      <p className="text-white drop-shadow-sm text-transform: capitalize text-lg mb-0 font-bold font-[montserrat] w-[70%] whitespace-nowrap text-ellipsis overflow-hidden">
                        Price: ${order?.products?.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showImageModal && (
        <div className="absolute  top-[-13rem] left-0 w-[40vw] h-[90vh] bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg w-full h-full">
            <img
              src={selectedImage}
              alt="LargeImage"
              className="w-full h-full"
            />
            <div
              onClick={toggleImageModal}
              className="absolute top-0 right-0 m-2"
            >
              <img src={Close} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default History;
