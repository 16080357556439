import React, { useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRipple,
  MDBCarousel,
  MDBCarouselItem,
  MDBRow,
} from "mdb-react-ui-kit";
import NoImage from "../../assets/images/No_Image_Available.jpg";

export const ProductList = ({
  products,
  setCentredModal,
  setSelectedProductId,
  deleteProduct,
}) => {
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);

  const toggleDescription = (index) => {
    setExpandedDescriptionIndex(
      expandedDescriptionIndex === index ? null : index
    );
  };

  const handleOptionClick = (option, productId) => {
    if (option === "Edit") {
      setCentredModal(true);
      setSelectedProductId(productId);
    } else {
      setCentredModal(false);
    }
  };

  return (
    <div>
      {products.length < 1 && <p className="text-white">No products found</p>}
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
        {products?.map((product, index) => (
          <div key={index}>
            <MDBCard className="product-card flex-row  md:gap-4">
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="bg-image hover-overlay w-2/5"
              >
                <div className="w-full object-cover h-48  cursor-pointer transition-all duration-700 bg-bottom-left">
                  <img
                    src={
                      product.mockupImage != null
                        ? product.mockupImage
                        : NoImage
                    }
                    alt="Mockup Image"
                    className="w-full h-full object-cover"
                  />
                </div>

                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </MDBRipple>
              <MDBCardBody className="p-2 md:p-4 flex flex-col justify-center w-3/5">
                <MDBRow className="items-center justify-between ">
                  <MDBCardTitle className="w-fit">{product.title}</MDBCardTitle>
                  <MDBCardTitle className="w-fit">
                    <span className="text-secondary-g">$</span>
                    {product.price}
                  </MDBCardTitle>
                </MDBRow>
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    scrollbarColor: "blue lightgray",
                    marginBottom: "5px",
                  }}
                >
                  <MDBCardText className="first-letter:capitalize min-h-6">
                    {expandedDescriptionIndex === index ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.description.slice(0, 20),
                        }}
                      />
                    )}
                    {product.description.length > 100 && (
                      <>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => toggleDescription(index)}
                        >
                          {expandedDescriptionIndex === index
                            ? " See less"
                            : " See more..."}
                        </span>
                      </>
                    )}
                  </MDBCardText>
                </div>
                <div className="flex flex-wrap gap-2">
                  <MDBBtn
                    className="sm:w-fit  w-fit px-3 font-[montserrat]"
                    onClick={() => handleOptionClick("Edit", product._id)}
                  >
                    Update
                  </MDBBtn>
                  <MDBBtn
                    className="sm:w-fit w-fit px-3 font-[montserrat]"
                    onClick={() => deleteProduct(product._id)}
                  >
                    Delete
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        ))}
      </div>
    </div>
  );
};
