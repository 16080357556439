import React, { useEffect, useState } from "react";
import StatsChart from "../StatsChart/StatsChart";
import axios from "axios";
import ApiUrl from "../../Constant/ApiUrl";

const DashboardStats = () => {
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(false);
  const baseURL = process.env.REACT_APP_URL;

  useEffect(() => {
    const getLineChartData = async () => {
      const token = localStorage.getItem("adminToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/orders/graph?year=2024`,
          config
        );

        const transformedData = response.data.result.map((item) => ({
          month: item.month,
          day: item.day,
          totalAmount: item.totalAmount,
        }));

        setChartData(transformedData);
        setError(false);
      } catch (err) {
        setError(true);
      }
    };

    getLineChartData();
  }, []);

  return (
    <div className="mb-6 w-full mt-8 px-3.5">
      {chartData ? (
        <StatsChart data={chartData} />
      ) : error ? (
        <p>No Chart Data Available</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DashboardStats;
