import { CloseOutlined } from "@mui/icons-material";
import React from "react";

const ImageModal = ({ showModal, setShowModal, imageSrc }) => {
  if (!showModal) return null;
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };
  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 "
    >
      <div className="relative bg-black border-[2px] border-primary-b rounded-lg w-11/12 max-w-2xl h-3/4 overflow-auto">
        <button
          className="absolute top-2 right-2 p-1 bg-gray-200 rounded-full text-black"
          onClick={() => setShowModal(false)}
        >
          <CloseOutlined />
        </button>
        <div className="p-4">
          <img src={imageSrc} alt="Full View" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
