import React, { useState } from "react";
import Accordion from "./Component/Accordion";
import Footer from "../../../components/Common/footer/Footer";
import { OnBack } from "../../../Component/OnBack/OnBack";

const faqDataGeneralQuestions = [
  {
    question: "What services does Pixel Pulse offer?",
    answer:
      "Pixel Pulse provides a range of design services, including graphic design, web design, branding, and more. We specialize in creating unique and customized design solutions for businesses of all sizes.",
  },
  {
    question: "How can I contact Pixel Pulse for support or inquiries?",
    answer:
      "You can contact us through our support email at INFO@DIPIXELS.COM or use the contact form on our website. We aim to respond to all inquiries within 24 hours.",
  },
  {
    question: "What is the process for getting a custom design?",
    answer:
      "To get a custom design, you can start by filling out our design request form on the website. While filling out the form, you can also book a consultation at a time that suits you to discuss your needs and preferences before we start the design process.",
  },
];

const faqDataOrdersAndPayments = [
  {
    question: "How do I place an order?",
    answer:
      "Orders can be placed directly through our website. Select the service you need and complete the payment process. Please provide the necessary details for customized order before making the payment. In case you forgot to do so you can contact us at info@dipixels.com",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept various payment methods including credit/debit cards, PayPal, and ACH bank transfers. (For ACH transactions please reach out to info@dipixels.com to complete your payment)",
  },
  {
    question: "Can I get a refund?",
    answer:
      "We do not offer refunds, but we provide revisions according to our revision policy to ensure you are satisfied with the final design. Note that revisions are only provided for customized designs.",
  },
];

const faqDataRevisionsAndPolicies = [
  {
    question: "How many revisions do you offer?",
    answer:
      "We offer a certain number of revisions based on the package you choose. Additional revisions can be requested for an extra fee. Revisions are only provided for customized designs.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "Our policy is not to provide refunds. Instead, we offer revisions to ensure you are happy with the final product. Revisions are only provided for customized designs.",
  },
  {
    question: "Are there any hidden fees?",
    answer:
      "No, all fees are clearly stated in our pricing plans. Any additional charges for extra services will be communicated upfront.",
  },
];

const faqDataTechnicalSupport = [
  {
    question: "How do I get technical support for my website?",
    answer:
      "If you need technical support for a website we designed, please contact our support team via email at Customer Support (info@dipixels.com) or through the Contact Us form on our website.",
  },
  {
    question: "Can you help with website updates and maintenance?",
    answer:
      "Yes, we offer website maintenance and update services. Please contact us to discuss your specific needs and we will provide a custom quote. Contact us at (info@dipixels.com) for more information.",
  },
];

const faqDataAdditionalServices = [
  {
    question: "Do you offer any marketing services?",
    answer:
      "Yes, we offer additional services such as SEO, social media management, and content creation to complement our design services. Contact us at (info@dipixels.com) for more information.",
  },
  {
    question: "Can I request a consultation before ordering a service?",
    answer:
      "Absolutely! We offer free initial consultations to discuss your project and provide recommendations based on your needs. Feel free to contact us at (info@dipixels.com) for more information.",
  },
];

const FaqPage = () => {
  const [activeIndexGeneral, setActiveIndexGeneral] = useState(null);
  const [activeIndexOrders, setActiveIndexOrders] = useState(null);
  const [activeIndexRevisions, setActiveIndexRevisions] = useState(null);
  const [activeIndexTechnical, setActiveIndexTechnical] = useState(null);
  const [activeIndexAdditional, setActiveIndexAdditional] = useState(null);

  const toggleFAQ = (index, setActiveIndex, activeIndex) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="py-10 lg:px-[5rem] px-[1rem]  mx-auto">
      <OnBack />
      <div className="my-6 text-text w-full max-w-6xl mx-auto px-4 lg:px-5 lg:my-10">
        <h2 className="lg:text-[45px] font-[Macoxs] text-center mb-4 sm:text-[25px] text-[#00F3F4] uppercase tracking-[5px]">
          FAQs
        </h2>

        <h4 className="lg:text-xl font-[Macoxs] text-center mb-4 sm:text-base text-secondary-g uppercase tracking-[5px]">
          General Questions
        </h4>
        {faqDataGeneralQuestions.map((item, index) => (
          <Accordion
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndexGeneral === index}
            onToggle={() =>
              toggleFAQ(index, setActiveIndexGeneral, activeIndexGeneral)
            }
          />
        ))}

        <h4 className="lg:text-xl font-[Macoxs] text-center mb-4 sm:text-base text-secondary-g uppercase tracking-[5px]">
          Orders and Payments
        </h4>
        {faqDataOrdersAndPayments.map((item, index) => (
          <Accordion
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndexOrders === index}
            onToggle={() =>
              toggleFAQ(index, setActiveIndexOrders, activeIndexOrders)
            }
          />
        ))}

        <h4 className="lg:text-xl font-[Macoxs] text-center mb-4 sm:text-base text-secondary-g uppercase tracking-[5px]">
          Revisions and Policies
        </h4>
        {faqDataRevisionsAndPolicies.map((item, index) => (
          <Accordion
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndexRevisions === index}
            onToggle={() =>
              toggleFAQ(index, setActiveIndexRevisions, activeIndexRevisions)
            }
          />
        ))}

        <h4 className="lg:text-xl font-[Macoxs] text-center mb-4 sm:text-base text-secondary-g uppercase tracking-[5px]">
          Technical Support
        </h4>
        {faqDataTechnicalSupport.map((item, index) => (
          <Accordion
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndexTechnical === index}
            onToggle={() =>
              toggleFAQ(index, setActiveIndexTechnical, activeIndexTechnical)
            }
          />
        ))}

        <h4 className="lg:text-xl font-[Macoxs] text-center mb-4 sm:text-base text-secondary-g uppercase tracking-[5px]">
          Additional Services
        </h4>
        {faqDataAdditionalServices.map((item, index) => (
          <Accordion
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndexAdditional === index}
            onToggle={() =>
              toggleFAQ(index, setActiveIndexAdditional, activeIndexAdditional)
            }
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default FaqPage;
