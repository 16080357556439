import React, { useRef } from "react";
import Close from "../../assets/images/Icon ionic-ios-close-circle-outline.svg";

const ImageModal = ({ isOpen, onClose, imageUrl, title }) => {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-75 z-50 px-4"
      onClick={handleBackdropClick}
    >
      <div
        ref={modalRef}
        className="relative px-3 pb-4 bg-black border-secondary-g border-2  rounded-lg h-5/6 max-h-[1000px] w-full max-w-2xl overflow-hidden flex flex-col"
      >
        <div className="flex justify-between items-center py-4">
          <div className="font-[Macoxs] text-sm lg:text-base uppercase text-[#9ADD3A] font-bold">
            <span>{title}</span>
          </div>
          <div className="" onClick={onClose}>
            <img src={Close} alt="Close" className="w-7 h-7" />
          </div>
        </div>
        <div className="h-full overflow-auto">
          <img src={imageUrl} alt="Product" className="w-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
